import config from '@config/config';

import ApiService from './api';

class ProductApi extends ApiService {
  constructor() {
    if (ProductApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return ProductApi.instance;
    }

    super(config.workbenchApiUrl);
    ProductApi.instance = this;
  }

  async getById(id) {
    const response = await this.http.get(`/products/${id}`);
    return response.data;
  }

  async getAll() {
    const response = await this.http.get(`/products`);
    return response.data;
  }

  async create(data) {
    const response = await this.http.post(`/products`, data);
    return response.data;
  }
}

const productApiInstance = new ProductApi();
export default productApiInstance;
