import { createContext, useState, useMemo, useEffect } from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';
import PropTypes from 'prop-types';

import { MEDIA_FONT_SIZE, MEDIA_SCREEN } from '@utils/const';
import useResize from '@hooks/useResize';

export const ResponsiveFontSizeContext = createContext({
  isContinueOnMobile: false,
  isMobile: false,
  htmlFontSize: MEDIA_FONT_SIZE.BASE,
  setHtmlFontSize: () => {},
  setIsContinueOnMobile: () => {},
});

const ResponsiveFontSizeProvider = ({ children }) => {
  const [isContinueOnMobile, setIsContinueOnMobile] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [htmlFontSize, setHtmlFontSize] = useState(16);

  const { windowWidth } = useResize();

  useEffect(() => {
    document.documentElement.style.fontSize = `${htmlFontSize}px`;
  }, [htmlFontSize]);

  useEffect(() => {
    if (windowWidth < MEDIA_SCREEN.SM) {
      setHtmlFontSize(MEDIA_FONT_SIZE.XS);
    } else if (windowWidth < MEDIA_SCREEN.MD) {
      setHtmlFontSize(MEDIA_FONT_SIZE.SM);
    } else if (windowWidth < MEDIA_SCREEN.LG) {
      setHtmlFontSize(MEDIA_FONT_SIZE.MD);
    } else {
      setHtmlFontSize(MEDIA_FONT_SIZE.BASE);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!windowWidth || isContinueOnMobile) {
      return;
    }

    setIsMobile(isMobileDevice && windowWidth < 900);
  }, [windowWidth, isContinueOnMobile]);

  const value = useMemo(
    () => ({
      isContinueOnMobile,
      isMobile,
      htmlFontSize,
      setHtmlFontSize,
      setIsContinueOnMobile,
    }),
    [isContinueOnMobile, isMobile, htmlFontSize]
  );

  return (
    <ResponsiveFontSizeContext.Provider value={value}>
      {children}
    </ResponsiveFontSizeContext.Provider>
  );
};

ResponsiveFontSizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResponsiveFontSizeProvider;
