import clsx from 'clsx';
import PropTypes from 'prop-types';

const Question = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className={clsx('w-6 h-6', className)}
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 3.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 8.154c-.708 0-1.282.574-1.282 1.282a.77.77 0 1 1-1.539 0 2.82 2.82 0 1 1 5.194 1.525 9.22 9.22 0 0 1-.576.776l-.103.128c-.159.196-.304.377-.439.561-.332.457-.486.8-.486 1.113a.77.77 0 0 1-1.538 0c0-.82.402-1.498.78-2.018.16-.22.335-.436.494-.633l.094-.115c.19-.236.351-.444.48-.645A1.282 1.282 0 0 0 12 8.154Z"
      clipRule="evenodd"
    />
    <path d="M13.026 16.615a1.026 1.026 0 1 1-2.052 0 1.026 1.026 0 0 1 2.052 0Z" fill={color} />
  </svg>
);

Question.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Question.defaultProps = {
  color: '#242424',
  className: '',
};

export default Question;
