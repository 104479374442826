import PropTypes from 'prop-types';
import clsx from 'clsx';

const ImagePlaceholder = ({ color, className, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    className={clsx('w-8 h-8', className)}
    {...props}
  >
    <path
      d="m29.36 22.427-4.173-9.76c-.76-1.787-1.893-2.8-3.187-2.867-1.28-.066-2.52.827-3.466 2.534L16 16.88c-.533.96-1.293 1.534-2.12 1.6-.84.08-1.68-.36-2.36-1.226l-.293-.374c-.947-1.186-2.12-1.76-3.32-1.64-1.2.12-2.227.947-2.907 2.294l-2.306 4.6a5.297 5.297 0 0 0 .226 5.173 5.29 5.29 0 0 0 4.52 2.52h17.014a5.317 5.317 0 0 0 4.44-2.387 5.22 5.22 0 0 0 .466-5.013ZM9.294 11.174a4.507 4.507 0 1 0 0-9.014 4.507 4.507 0 0 0 0 9.014Z"
      fill={color}
    />
  </svg>
);

ImagePlaceholder.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ImagePlaceholder.defaultProps = {
  color: '#E0E0E0',
  className: '',
};

export default ImagePlaceholder;
