const RedCheck = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.13438 17.6849C9.32338 17.8859 9.58738 17.9999 9.86338 17.9999H9.87038C10.1484 17.9979 10.4144 17.8799 10.6014 17.6739L19.7384 7.67387C20.1104 7.26687 20.0824 6.63387 19.6754 6.26187C19.2674 5.88987 18.6354 5.91687 18.2614 6.32587L9.85338 15.5279L5.72838 11.1369C5.35138 10.7349 4.71838 10.7149 4.31538 11.0929C3.91338 11.4709 3.89238 12.1039 4.27138 12.5059L9.13438 17.6849Z"
        fill="white"
      />
    </svg>
  );
};

export default RedCheck;
