import { createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import AuthService from '@services/api/auth';
import ProfileService from '@services/api/profile';
import LocalStorageService from '@services/localStorage';
import { ROOT } from '@router/consts';

export const UserContext = createContext({
  user: null,
  profile: null,
  isMainLoading: true,
  passwordRecentlyRecet: false,
  login: async () => {},
  socialLogin: async () => {},
  requestPasswordReset: async () => {},
  resetPassword: async () => {},
  logout: async () => {},
  register: async () => {},
  activateUser: async () => {},
  setProfile: () => {},
});

const UserProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const [isMainLoading, setIsMainLoading] = useState(true);
  const [passwordRecentlyRecet, setPasswordRecentlyRecet] = useState(false);
  const navigate = useNavigate();

  const setProfileFromUser = useCallback(async (userData) => {
    const [profileResponse] = await ProfileService.getByUserId(userData.id);
    setProfile(profileResponse);
  }, []);

  const init = useCallback(async () => {
    setIsMainLoading(true);

    const accessToken = LocalStorageService.getAccessToken();
    if (!accessToken) {
      setIsMainLoading(false);
      return;
    }

    try {
      let userResponse;

      try {
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      } catch (err) {
        // Try to refresh token if current one is invalid
        const result = await AuthService.refreshToken();

        LocalStorageService.setAccessToken(result.accessToken);
        LocalStorageService.setRefreshToken(result.refreshToken);

        // Set current user after token refresh
        userResponse = await AuthService.getCurrentUser();
        setUser(userResponse);
      }

      try {
        await setProfileFromUser(userResponse);
      } catch (err) {
        navigate(ROOT);
      }
    } catch (err) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
    } finally {
      setIsMainLoading(false);
    }
  }, [navigate, setProfileFromUser]);

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const login = useCallback(async (email, password) => {
    const result = await AuthService.emailLogin(email, password);
    if (!result?.accessToken) {
      return;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();

    try {
      const [userProfile] = await ProfileService.getByUserId(userResponse.id);
      setProfile(userProfile);
      setUser(userResponse);
    } catch (err) {
      // TODO: Redirect to create profile page?
    }
  }, []);

  const socialLogin = useCallback(async (token) => {
    const result = await AuthService.googleLogin(token);
    if (!result?.accessToken) {
      return;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();

    try {
      const [userProfile] = await ProfileService.getByUserId(userResponse.id);
      setProfile(userProfile);
      setUser(userResponse);
    } catch (err) {
      // TODO: Redirect to create profile page?
    }
  }, []);

  const register = useCallback(async (email, password) => {
    await AuthService.emailRegistration(email, password);
  }, []);

  const requestPasswordReset = useCallback(async (email) => {
    await AuthService.requestPasswordReset(email);
  }, []);

  const resetPassword = useCallback(async (password, email, code) => {
    const response = await AuthService.resetPassword(password, code, email);

    if (!response?.accessToken) {
      return;
    }

    LocalStorageService.setAccessToken(response.accessToken);
    LocalStorageService.setRefreshToken(response.refreshToken);

    const userResponse = await AuthService.getCurrentUser();

    try {
      const [userProfile] = await ProfileService.getByUserId(userResponse.id);
      setPasswordRecentlyRecet(true);
      setProfile(userProfile);
      setUser(userResponse);
    } catch (err) {
      // TODO: Redirect to create profile page?
    }
  }, []);

  const activateUser = useCallback(async (email, activationCode) => {
    const result = await AuthService.activateUser(email, activationCode);
    if (!result?.accessToken) {
      return;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();
    setUser(userResponse);
  }, []);

  const logout = useCallback(async () => {
    await AuthService.logout();

    LocalStorageService.removeAccessToken();
    LocalStorageService.removeRefreshToken();

    // expired token is not handled
    queryClient.clear();

    setUser(null);
    setProfile(null);

    navigate(ROOT);
  }, [navigate, queryClient]);

  const value = useMemo(
    () => ({
      user,
      isMainLoading,
      profile,
      passwordRecentlyRecet,
      setPasswordRecentlyRecet,
      login,
      logout,
      register,
      activateUser,
      setProfile,
      socialLogin,
      requestPasswordReset,
      resetPassword,
    }),
    [
      activateUser,
      isMainLoading,
      passwordRecentlyRecet,
      setPasswordRecentlyRecet,
      login,
      logout,
      profile,
      register,
      socialLogin,
      requestPasswordReset,
      resetPassword,
      user,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const value = useContext(UserContext);
  return value;
};

export default UserProvider;
