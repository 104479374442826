const SaveTime = () => {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.4858 23.7792C86.4858 23.7792 70.5938 5.59717 47.4278 8.15717C24.2638 10.7152 21.8398 36.8432 14.9718 43.1732C8.10381 49.5032 2.84981 55.8332 3.92781 69.4352C5.00581 83.0372 8.55981 90.1572 8.55981 90.1572H87.2938C87.2938 90.1572 95.9138 70.2432 95.2398 50.7132C94.5678 31.1872 86.4858 23.7792 86.4858 23.7792Z"
        fill="#585550"
        fillOpacity="0.4"
      />
      <path
        opacity="0.7"
        d="M86.4858 23.7792C86.4858 23.7792 70.5938 5.59717 47.4278 8.15717C24.2638 10.7152 21.8398 36.8432 14.9718 43.1732C8.10381 49.5032 2.84981 55.8332 3.92781 69.4352C5.00581 83.0372 8.55981 90.1572 8.55981 90.1572H87.2938C87.2938 90.1572 95.9138 70.2432 95.2398 50.7132C94.5678 31.1872 86.4858 23.7792 86.4858 23.7792Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M35.0767 6.04297H33.8467V8.29697H35.0767V6.04297Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M31.3064 6.72484L30.2412 7.33984L31.3682 9.29181L32.4334 8.67681L31.3064 6.72484Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M28.3826 9.20044L27.7676 10.2656L29.7195 11.3926L30.3345 10.3274L28.3826 9.20044Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M27.0869 12.8052V14.0352H29.3409V12.8052H27.0869Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M27.7678 16.5754L28.3828 17.6406L30.3348 16.5136L29.7198 15.4484L27.7678 16.5754Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M30.2434 19.5022L31.3086 20.1172L32.4356 18.1652L31.3704 17.5502L30.2434 19.5022Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M33.8481 20.7969H35.0781V18.5429H33.8481V20.7969Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M37.6184 20.115L38.6836 19.5L37.5566 17.548L36.4914 18.163L37.6184 20.115Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M40.5432 17.6394L41.1582 16.5742L39.2062 15.4472L38.5912 16.5124L40.5432 17.6394Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M41.8389 14.0347V12.8047H39.5849V14.0347H41.8389Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M41.1589 10.2644L40.5439 9.19922L38.592 10.3262L39.207 11.3914L41.1589 10.2644Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M38.6824 7.34156L37.6172 6.72656L36.4902 8.67853L37.5554 9.29353L38.6824 7.34156Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M31.8613 12.3711L34.4633 13.4191V17.2031"
          stroke="black"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M33.936 13.8281L30.334 16.5121"
          stroke="black"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M35.4295 12.7188L33.9355 13.8308"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M27.3237 46.5781H26.0938V48.8321H27.3237V46.5781Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M23.5535 47.2561L22.4883 47.8711L23.6153 49.8231L24.6805 49.2081L23.5535 47.2561Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M20.6267 49.7317L20.0117 50.7969L21.9637 51.9239L22.5787 50.8587L20.6267 49.7317Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M19.3311 53.3403V54.5703H21.5851V53.3403H19.3311Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M20.012 57.1067L20.627 58.1719L22.5789 57.0449L21.9639 55.9797L20.012 57.1067Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M22.4866 60.0373L23.5518 60.6523L24.6788 58.7004L23.6136 58.0854L22.4866 60.0373Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M26.0923 61.332H27.3223V59.078H26.0923V61.332Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M29.8645 60.6502L30.9297 60.0352L29.8027 58.0832L28.7375 58.6982L29.8645 60.6502Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M32.7893 58.1746L33.4043 57.1094L31.4523 55.9824L30.8373 57.0476L32.7893 58.1746Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M34.085 54.5698V53.3398H31.831V54.5698H34.085Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M33.406 50.7956L32.791 49.7305L30.8391 50.8575L31.4541 51.9226L33.406 50.7956Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M30.9275 47.8728L29.8623 47.2578L28.7353 49.2098L29.8005 49.8248L30.9275 47.8728Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M24.1074 52.9062L26.7074 53.9542V57.7383"
          stroke="black"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M26.1801 54.3633L22.5801 57.0473"
          stroke="black"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M27.6737 53.25L26.1797 54.364"
          stroke="black"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M67.9786 81.753C72.1564 80.7493 74.7294 76.5488 73.7257 72.3711C72.722 68.1933 68.5215 65.6203 64.3438 66.624C60.166 67.6277 57.593 71.8281 58.5967 76.0059C59.6004 80.1837 63.8009 82.7567 67.9786 81.753Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M72.0533 76.5696C73.3694 73.32 71.802 69.6187 68.5524 68.3026C65.3028 66.9865 61.6015 68.5539 60.2854 71.8035C58.9693 75.0532 60.5367 78.7544 63.7863 80.0705C67.0359 81.3867 70.7372 79.8192 72.0533 76.5696Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M66.9539 74.1864C66.9539 73.7504 66.6019 73.3984 66.1659 73.3984C65.7299 73.3984 65.3779 73.7504 65.3779 74.1864C65.3779 74.6224 65.7299 74.9744 66.1659 74.9744C66.6019 74.9744 66.9539 74.6224 66.9539 74.1864Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M65.5422 73.6154L62.3242 72.7734L65.3782 74.1854"
          stroke="#242424"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M65.6875 74.8203L66.0175 79.5383L66.6035 74.9663"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M66.165 68.6875V69.7935"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M63.417 69.4219L63.969 70.3799"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M61.4043 71.4375L62.3623 71.9915"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M60.667 74.1875H61.773"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M61.4043 76.9368L62.3623 76.3828"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M63.417 78.9463L63.969 77.9883"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M66.165 79.6841V78.5781"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M68.9163 78.9463L68.3623 77.9883"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M70.9268 76.9368L69.9688 76.3828"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M71.6636 74.1875H70.5576"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M70.9268 71.4375L69.9688 71.9915"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M68.9163 69.4219L68.3623 70.3799"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M69.9101 25.6385C70.6678 25.6385 71.2821 25.0243 71.2821 24.2665C71.2821 23.5088 70.6678 22.8945 69.9101 22.8945C69.1523 22.8945 68.5381 23.5088 68.5381 24.2665C68.5381 25.0243 69.1523 25.6385 69.9101 25.6385Z"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M68.8237 23.2726L63.2217 21.8086L68.5377 24.2666"
          stroke="#242424"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M69.0781 25.375L69.6521 33.587L70.6701 25.629"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M69.9102 14.6953V16.6213"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M65.125 15.9766L66.089 17.6446"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M61.6191 19.4805L63.2871 20.4445"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M60.3379 24.2656H62.2639"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M61.6191 29.0538L63.2871 28.0898"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M65.125 32.5586L66.089 30.8906"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M69.9102 33.8401V31.9141"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M74.6954 32.5586L73.7334 30.8906"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M78.2002 29.0538L76.5322 28.0898"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M79.4817 24.2656H77.5557"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M78.2002 19.4805L76.5322 20.4445"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M74.6954 15.9766L73.7334 17.6446"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M69.4004 60.2498C69.4004 60.2498 72.3304 59.3478 76.8484 58.7918C81.3664 58.2358 84.6664 59.4338 84.6664 59.4338C84.6664 59.4338 83.2744 60.9118 81.6764 61.1558C80.0784 61.3998 75.4484 61.9298 73.7484 61.5578C72.0464 61.1858 69.4004 60.2498 69.4004 60.2498Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M82.5295 62.2127L83.2995 48.3007C83.2995 48.3007 88.8355 42.3847 90.0635 41.6787C91.2915 40.9727 91.6115 38.6707 91.8315 37.5247C92.0515 36.3787 92.2995 34.8867 93.4735 33.4767C94.6475 32.0667 94.6775 31.7187 93.5215 31.6167C92.3655 31.5147 90.3075 32.3807 89.7435 33.4947C89.1775 34.6087 88.7495 35.5027 88.0995 36.2607C87.4495 37.0187 86.5795 37.6407 86.5795 37.6407L84.7895 30.2587C84.7895 30.2587 85.5335 24.4987 85.6675 22.9967C85.8015 21.4947 86.7375 17.4987 86.4735 16.5447C86.2095 15.5887 84.6115 15.2147 83.9095 16.5507C83.2075 17.8867 83.4635 18.9567 83.0775 20.6707C82.6915 22.3847 81.3575 26.9267 81.0835 27.3667C80.8115 27.8087 80.4835 28.7247 80.4835 28.7247C80.4835 28.7247 79.9995 26.4547 79.7655 25.1527C79.5315 23.8507 79.2535 16.4847 78.6735 15.1527C78.0935 13.8187 76.4435 14.0227 76.3315 15.2947C76.2195 16.5667 76.5495 19.3927 76.6475 20.9147C76.7455 22.4367 76.4575 25.6747 76.1035 27.0407C75.7495 28.4067 75.7495 28.4067 75.7495 28.4067C75.7495 28.4067 74.1495 21.5067 74.1795 21.1607C74.2095 20.8147 74.2495 19.0687 73.4275 17.8307C72.6055 16.5927 71.1775 16.9327 71.3055 18.1087C71.4335 19.2847 72.1335 21.9107 72.1775 22.7307C72.2215 23.5507 72.3635 31.1367 72.4475 31.4927C72.5315 31.8507 72.1235 32.5127 72.1235 32.5127C72.1235 32.5127 70.4355 29.2167 70.5075 28.4087C70.5795 27.5987 70.1835 24.1847 69.2875 22.4747C68.3915 20.7647 66.8155 21.4387 67.2715 22.8787C67.7255 24.3167 68.0275 27.4887 68.1355 28.8967C68.2435 30.3047 69.4415 35.1867 69.4415 35.1867C69.4415 35.1867 70.6495 45.1967 71.5355 47.0227C72.4215 48.8487 72.7815 50.0467 72.7815 50.0467L72.2275 66.7747L82.5295 62.2127Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.8873 34.7344L76.8633 35.4964"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.0439 30.2969C76.0439 30.2969 76.0559 31.4629 76.3519 32.0729C76.6479 32.6809 76.9319 33.4069 76.9319 33.4069L76.8999 34.3449"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M72.4826 31.7539C72.4826 31.7539 72.3826 32.4819 72.3646 33.5159C72.3566 33.9839 72.5086 34.4439 72.7986 34.8099L72.9866 35.8959"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M84.2642 32.1914L83.1542 34.1914L83.1162 35.9354"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.4357 30.7109C80.4357 30.7109 80.2197 32.3309 80.5237 32.9289C80.8277 33.5269 80.7517 34.3749 80.7517 34.3749"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M86.5752 37.6367L86.7832 39.3527"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M69.3984 60.2496C69.3984 60.2496 70.4824 60.7236 76.0844 60.6436C81.6864 60.5636 84.6644 59.4336 84.6644 59.4336L84.3764 67.8056C84.3764 67.8056 78.7404 68.5916 75.9204 68.4496C73.1004 68.3076 69.0684 67.1876 69.0684 67.1876L69.3984 60.2496Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.5815 90.1557L86.6555 90.0117L85.6455 65.9317C85.6455 65.9317 82.2935 66.7597 78.5475 66.7637C72.2215 66.7717 68.4855 65.5117 68.4855 65.5117L67.3535 90.1537H83.5815V90.1557Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.7851 70.5106C71.6751 71.0106 71.1811 71.3286 70.6791 71.2186C70.1771 71.1086 69.8611 70.6146 69.9711 70.1126C70.0811 69.6126 70.5751 69.2946 71.0771 69.4046C71.5771 69.5146 71.8951 70.0106 71.7851 70.5106Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.8691 73.3817C71.7591 73.8817 71.2651 74.1997 70.7631 74.0897C70.2611 73.9797 69.9451 73.4857 70.0551 72.9837C70.1651 72.4837 70.6591 72.1657 71.1611 72.2757C71.6631 72.3857 71.9791 72.8797 71.8691 73.3817Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M71.9559 76.2528C71.8459 76.7528 71.3519 77.0708 70.8499 76.9608C70.3499 76.8508 70.0319 76.3568 70.1419 75.8548C70.2519 75.3548 70.7459 75.0368 71.2479 75.1468C71.7479 75.2568 72.0639 75.7508 71.9559 76.2528Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.3313 50.7602C80.5353 50.8882 83.0333 51.6282 83.0333 51.5622V52.2302H81.2673L79.9033 50.4922C79.9033 50.4942 80.3293 50.7582 80.3313 50.7602Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.3313 57.2669C80.5353 57.1389 83.0333 56.3989 83.0333 56.4649V55.7969H81.2673L79.9033 57.5349C79.9033 57.5329 80.3293 57.2689 80.3313 57.2669Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.5955 52.25H82.6055V55.79H83.5955V52.25Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M82.6807 53.0742H83.4847"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.4847 55.0039H82.6807"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M83.1611 53.0977V54.9577"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.4686 50.7602C75.2646 50.8882 72.7666 51.6282 72.7666 51.5622V52.2302H74.5326L75.8966 50.4922C75.8946 50.4942 75.4686 50.7582 75.4686 50.7602Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.4686 57.2669C75.2646 57.1389 72.7666 56.3989 72.7666 56.4649V55.7969H74.5326L75.8966 57.5349C75.8946 57.5329 75.4686 57.2689 75.4686 57.2669Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M72.2053 55.7852H73.1953V52.2452H72.2053V55.7852Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M73.1204 53.0703H72.3164"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M72.3164 55H73.1204"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M72.6367 53.0977V54.9577"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.9041 58.5523C80.407 58.5523 82.4361 56.5232 82.4361 54.0203C82.4361 51.5173 80.407 49.4883 77.9041 49.4883C75.4011 49.4883 73.3721 51.5173 73.3721 54.0203C73.3721 56.5232 75.4011 58.5523 77.9041 58.5523Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M81.11 55.363C81.8517 53.5946 81.0194 51.5598 79.2511 50.8181C77.4828 50.0764 75.448 50.9087 74.7062 52.677C73.9645 54.4454 74.7968 56.4802 76.5652 57.2219C78.3335 57.9636 80.3683 57.1313 81.11 55.363Z"
        fill="#242424"
      />
      <path
        d="M78.1645 50.8984H77.6445V51.8524H78.1645V50.8984Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.5665 51.1853L76.1162 51.4453L76.5932 52.2715L77.0435 52.0115L76.5665 51.1853Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.3284 52.2333L75.0684 52.6836L75.8945 53.1606L76.1545 52.7103L75.3284 52.2333Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M74.7832 53.7612V54.2812H75.7372V53.7612H74.7832Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.0691 55.3583L75.3291 55.8086L76.1553 55.3316L75.8953 54.8813L75.0691 55.3583Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.121 56.5955L76.5713 56.8555L77.0483 56.0293L76.598 55.7693L76.121 56.5955Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.646 57.1445H78.166V56.1905H77.646V57.1445Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.243 56.8577L79.6934 56.5977L79.2164 55.7715L78.766 56.0315L79.243 56.8577Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.4822 55.8058L80.7422 55.3555L79.916 54.8785L79.656 55.3288L80.4822 55.8058Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M81.0273 54.2778V53.7578H80.0733V54.2778H81.0273Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.7405 52.6808L80.4805 52.2305L79.6543 52.7075L79.9143 53.1578L80.7405 52.6808Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.6886 51.4436L79.2383 51.1836L78.7613 52.0098L79.2116 52.2698L79.6886 51.4436Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.6524 55.6209C80.5351 54.6558 80.4683 53.158 79.5032 52.2753C78.5382 51.3927 77.0404 51.4595 76.1577 52.4245C75.2751 53.3896 75.3419 54.8874 76.3069 55.77C77.272 56.6527 78.7698 56.5859 79.6524 55.6209Z"
        fill="#242424"
      />
      <path
        d="M77.4861 53.5765C77.4861 53.2005 77.1801 52.8945 76.8041 52.8945C76.4281 52.8945 76.1221 53.2005 76.1221 53.5765C76.1221 53.9525 76.4281 54.2585 76.8041 54.2585C77.1801 54.2585 77.4861 53.9525 77.4861 53.5765Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.6785 53.5765C79.6785 53.2005 79.3725 52.8945 78.9965 52.8945C78.6205 52.8945 78.3145 53.2005 78.3145 53.5765C78.3145 53.9525 78.6205 54.2585 78.9965 54.2585C79.3725 54.2585 79.6785 53.9525 79.6785 53.5765Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M78.5906 55.1195C78.5906 54.7435 78.2846 54.4375 77.9086 54.4375C77.5326 54.4375 77.2266 54.7435 77.2266 55.1195C77.2266 55.4955 77.5326 55.8015 77.9086 55.8015C78.2846 55.8015 78.5906 55.4955 78.5906 55.1195Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M76.8037 53.5781L77.9037 54.0221V55.6241"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.6832 54.1953L76.1572 55.3313"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M78.3146 53.7227L77.6826 54.1947"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M78.3909 48.7227H77.4189V49.4367H78.3909V48.7227Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M77.7197 48.793V49.323"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M78.043 48.7578V49.3398"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.5955 49.2514L75.2764 49.418L75.4586 49.7673L75.7778 49.6008L75.5955 49.2514Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M75.804 49.4409L75.3164 49.6953L75.5357 50.1156L76.0233 49.8611L75.804 49.4409Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M80.005 49.5913L80.3242 49.7578L80.5065 49.4085L80.1873 49.242L80.005 49.5913Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M79.7663 49.8628L80.2539 50.1172L80.4732 49.697L79.9856 49.4426L79.7663 49.8628Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M74.4926 51.3828C74.1666 51.8028 73.9166 52.2848 73.7646 52.8088C73.9566 52.9088 74.1926 53.0348 74.4546 53.1768C74.5846 52.6408 74.8366 52.1528 75.1786 51.7428C74.9086 51.5968 74.6826 51.4788 74.4926 51.3828ZM77.9046 57.5728C77.6546 57.5728 77.4126 57.5468 77.1786 57.4988V58.2748C77.4146 58.3168 77.6566 58.3368 77.9046 58.3368C78.2046 58.3368 78.4966 58.3068 78.7786 58.2488V57.4648C78.4987 57.5348 78.2066 57.5728 77.9046 57.5728ZM81.5606 51.7268L80.8546 52.0428C81.1526 52.4828 81.3526 52.9908 81.4266 53.5408L82.1446 53.2188C82.0446 52.6788 81.8426 52.1748 81.5606 51.7268Z"
        fill="#242424"
      />
      <path
        d="M37.0986 68.8714C37.0986 68.8714 51.0786 62.8534 55.1266 64.2654C55.1266 64.2654 51.9486 68.4094 50.2366 69.9494C48.5246 71.4894 42.1446 70.7894 41.6246 70.7534C41.1046 70.7174 37.2606 69.9294 37.0986 68.8714Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M55.694 33.5154C55.694 33.5154 54.768 28.9094 53.606 26.8234C52.442 24.7374 53.146 21.0014 52.664 19.4374C52.182 17.8734 51.07 17.1774 49.92 17.9594C48.77 18.7414 48.63 20.8274 48.622 21.1754C48.614 21.5234 48.614 21.5234 48.614 21.5234C48.614 21.5234 45.806 22.5654 45.528 23.2614C45.25 23.9574 45.25 23.9574 45.25 23.9574C45.25 23.9574 42.81 24.2174 42.262 25.2614C41.714 26.3034 41.402 28.3034 41.402 28.3034C41.402 28.3034 39.39 28.8254 39.092 30.3014C38.794 31.7794 38.17 35.7754 38.396 37.1674C38.622 38.5574 39.782 40.7294 39.782 40.7294C39.782 40.7294 41.806 46.5514 43.732 49.4194C43.732 49.4194 41.516 74.9674 41.39 79.9214L55.044 76.0974C55.044 76.0974 53.684 51.0694 53.646 49.1594C53.608 47.2474 58.95 38.8174 59.322 37.8634C59.694 36.9074 59.896 35.7774 59.666 34.5614C59.436 33.3454 59.128 31.7814 58.746 29.7814C58.362 27.7834 58.892 27.4354 58.302 26.7394C57.712 26.0434 57.218 24.9154 56.556 23.6114C55.894 22.3074 54.636 23.9594 54.762 25.7834C54.89 27.6074 55.734 28.5634 55.89 29.2594C56.044 29.9534 55.694 33.5154 55.694 33.5154Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.613 21.5234C48.613 21.5234 48.675 25.8674 48.299 26.9974C47.923 28.1274 47.717 29.3434 47.877 29.9514"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M45.2488 23.957C45.2488 23.957 44.3948 30.213 44.0208 31.257C44.0208 31.257 43.5508 32.647 44.1508 32.995"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M41.4001 28.3008C41.4001 28.3008 41.1141 32.7328 40.8301 33.6008"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M56.1484 35.9023L56.3064 36.7303"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M55.6934 33.5156L55.9754 34.9996"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M58.0081 90.1576C57.8701 89.0596 55.6621 71.3816 55.4661 69.0016C55.2621 66.5436 55.1281 64.2656 55.1281 64.2656C55.1281 64.2656 45.8461 67.5536 42.2781 68.4416C38.7101 69.3296 37.0981 68.8696 37.0981 68.8696L37.0801 90.1556H58.0081V90.1576Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M38.614 69.4102C38.536 75.7982 39.174 82.1982 39.166 88.5982"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M41.8026 72.5522C41.7546 73.0542 41.3106 73.4342 40.8086 73.4002C40.3066 73.3662 39.9386 72.9302 39.9866 72.4282C40.0346 71.9262 40.4786 71.5462 40.9806 71.5802C41.4826 71.6142 41.8506 72.0502 41.8026 72.5522Z"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M42.0224 76.2319C41.9744 76.7339 41.5304 77.1139 41.0284 77.0799C40.5264 77.0459 40.1584 76.6099 40.2064 76.1079C40.2544 75.6059 40.6984 75.2259 41.2004 75.2599C41.7024 75.2939 42.0704 75.7299 42.0224 76.2319Z"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M42.2421 79.9116C42.1941 80.4136 41.7501 80.7936 41.2481 80.7596C40.7461 80.7256 40.3781 80.2896 40.4261 79.7876C40.4741 79.2856 40.9181 78.9056 41.4201 78.9396C41.9201 78.9736 42.2901 79.4096 42.2421 79.9116Z"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M52.0623 56.9415L52.8783 56.9115L52.8423 54.1875L52.0283 54.2195L52.0623 56.9415Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M52.0283 54.2576L53.7303 54.1916L53.0463 53.8256C52.9223 53.7596 52.7603 53.7676 52.6423 53.8476L52.0283 54.2576Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M52.0615 56.941L53.7635 56.875L53.0895 57.293C52.9675 57.369 52.8055 57.373 52.6855 57.303L52.0615 56.941Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M54.4007 54.1681L52.8447 54.2181L52.8787 56.9101L54.4347 56.8601C54.5387 56.8561 54.6227 56.7681 54.6227 56.6641L54.5927 54.3521C54.5907 54.2461 54.5047 54.1641 54.4007 54.1681Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M44.5697 57.2279L43.7537 57.2579L43.7197 54.5359L44.5357 54.5039L44.5697 57.2279Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M44.535 54.5408L42.833 54.6068L43.507 54.1888C43.629 54.1128 43.791 54.1088 43.913 54.1788L44.535 54.5408Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M44.5682 57.2266L42.8662 57.2926L43.5502 57.6586C43.6742 57.7246 43.8362 57.7166 43.9542 57.6366L44.5682 57.2266Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M42.8335 54.6004L43.7195 54.5664L43.7535 57.2584L42.8675 57.2924C42.7635 57.2964 42.6775 57.2144 42.6755 57.1104L42.6455 54.7984C42.6435 54.6924 42.7275 54.6044 42.8335 54.6004Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M51.55 58.1812L45.028 58.4312C44.632 58.4472 44.306 58.1372 44.302 57.7432L44.254 54.0532C44.248 53.6572 44.566 53.3252 44.962 53.3092L51.484 53.0592C51.88 53.0432 52.206 53.3532 52.21 53.7472L52.258 57.4372C52.262 57.8332 51.946 58.1652 51.55 58.1812Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M51.1391 57.5245L45.4191 57.7425C45.2571 57.7485 45.1231 57.6225 45.1211 57.4585L45.0811 54.2625C45.0791 54.1005 45.2091 53.9625 45.3731 53.9565L51.0931 53.7385C51.2551 53.7325 51.3891 53.8585 51.3911 54.0225L51.4311 57.2185C51.4331 57.3825 51.3031 57.5185 51.1391 57.5245Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M51.1738 55.8318L50.3878 56.0378L50.3858 55.9358L50.9658 55.7898L50.3838 55.6937L50.3818 55.5938L51.1718 55.7378L51.1738 55.8318Z"
        fill="white"
      />
      <path
        d="M51.1699 55.4752L50.3819 55.5052L50.3799 55.3972L51.1679 55.3672L51.1699 55.4752Z"
        fill="white"
      />
      <path
        d="M45.5625 54.5195L46.0105 54.7115"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M47.1582 54.1484L47.3382 54.3944"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.2598 54.0938L48.2658 54.5097"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M49.4153 54.0039L49.1533 54.3999"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M51.0001 54.2852L50.5781 54.5252"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M51.0898 57.0029L50.5518 56.7109"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M49.4225 57.2868L49.1865 56.9688"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.338 57.3858L48.332 56.9258"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M47.2178 57.3859L47.4618 57.0039"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M45.6328 57.1785L46.0728 56.8945"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.9506 52.5119C48.9466 52.1859 48.6786 51.9319 48.3526 51.9459C48.0266 51.9579 47.7666 52.2319 47.7706 52.5579C47.7746 52.8839 48.0426 53.1379 48.3686 53.1239C48.6946 53.1119 48.9546 52.8379 48.9506 52.5119Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M49.095 52.293L47.623 52.351L47.633 53.175L49.107 53.119L49.095 52.293Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.0088 52.3242L48.0188 53.1282"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.6465 52.3008L48.6565 53.1048"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.1234 55.4537L47.4414 54.3438"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.2559 55.7422L47.4219 56.7542"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M47.6133 55.7896L48.2253 55.7656"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.5811 55.7285L50.7931 55.6445"
        stroke="#242424"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M48.5817 55.7303C48.5797 55.5503 48.4317 55.4103 48.2517 55.4183C48.0717 55.4243 47.9277 55.5763 47.9297 55.7563C47.9317 55.9363 48.0797 56.0763 48.2597 56.0683C48.4397 56.0623 48.5837 55.9103 48.5817 55.7303Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M46.2071 56.2779L45.7931 56.1299L45.4191 56.3059V56.2039L45.6951 56.0739L45.4151 55.9759L45.4131 55.8739L45.7991 56.0239L46.2011 55.8359L46.2031 55.9379L45.9071 56.0779L46.2051 56.1779L46.2071 56.2779Z"
        fill="white"
      />
      <path
        d="M46.2021 55.7564L45.4141 55.7864L45.4121 55.6784L46.2001 55.6484L46.2021 55.7564Z"
        fill="white"
      />
      <path
        d="M46.1972 55.5142L45.4092 55.5443L45.4072 55.4362L46.1952 55.4062L46.1972 55.5142Z"
        fill="white"
      />
      <path
        d="M11.0713 68.4589C11.0713 68.4589 15.8933 65.6949 28.0973 64.4609L22.3473 68.2149L14.8653 69.4609L11.0713 68.4589Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M11.9141 62.8954C11.9141 62.8954 15.7801 61.6494 20.3521 60.8994C24.9241 60.1514 27.3441 60.5134 27.3441 60.5134L16.1961 64.3074L11.9141 62.8954Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M25.6176 63.7083L24.9256 47.9643C24.9256 47.9643 27.9476 43.1123 28.1536 40.9543C28.3596 38.7983 27.0136 35.1303 25.0196 33.9443C23.0236 32.7583 21.1956 32.6503 20.0576 31.5723C18.9196 30.4943 18.3216 29.4163 18.3216 29.4163C18.3216 29.4163 19.2396 30.6023 20.0276 29.8483C20.8156 29.0943 20.6156 28.7703 21.7076 26.0743C22.7996 23.3783 23.2456 20.6823 22.0836 20.1423C20.9216 19.6023 16.6376 18.9563 16.1036 18.8483C15.5696 18.7403 13.9316 19.1723 13.5696 20.0343C13.2076 20.8963 10.0296 31.5743 9.42755 32.9763C8.82555 34.3783 8.90355 35.0243 9.49155 36.3203C10.0796 37.6163 13.6336 47.3203 14.3336 48.5063C15.0356 49.6923 14.6096 66.3003 14.6096 66.3003L25.6176 63.7083Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.4219 35.4543C20.4219 35.4543 21.9939 31.6803 21.9439 30.3863C21.8939 29.0923 20.8919 24.9943 21.2099 22.7303C21.5279 20.4663 22.5879 19.0863 24.2519 19.8183C25.4959 20.3643 25.7679 24.4563 26.2539 25.6423C26.7399 26.8283 26.5579 28.4463 26.7999 30.2803C27.0399 32.1143 28.9599 37.3983 28.4739 38.5843C27.9899 39.7703 26.5979 41.9283 26.5979 41.9283"
        fill="white"
      />
      <path
        d="M20.4219 35.4543C20.4219 35.4543 21.9939 31.6803 21.9439 30.3863C21.8939 29.0923 20.8919 24.9943 21.2099 22.7303C21.5279 20.4663 22.5879 19.0863 24.2519 19.8183C25.4959 20.3643 25.7679 24.4563 26.2539 25.6423C26.7399 26.8283 26.5579 28.4463 26.7999 30.2803C27.0399 32.1143 28.9599 37.3983 28.4739 38.5843C27.9899 39.7703 26.5979 41.9283 26.5979 41.9283"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.3213 29.4143C18.3213 29.4143 18.6253 27.4723 18.6973 25.8563C18.7693 24.2383 18.7693 24.2383 18.7693 24.2383C18.7693 24.2383 19.0773 24.5623 20.7053 24.3463"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.6035 38.6992C13.7095 39.1932 13.7655 39.5532 13.7655 39.5532"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.3807 19.6016C20.3807 19.6016 14.9887 19.6016 14.2987 20.5716C13.6087 21.5416 13.7707 22.7276 13.8187 24.0216C13.8687 25.3156 12.8307 31.5696 12.4547 32.7576C12.0767 33.9436 11.9067 35.3456 12.6267 36.1016C12.9927 36.4836 13.2787 37.3636 13.4747 38.1396"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.5668 20.0338C13.5668 20.0338 12.2128 18.9558 11.5908 20.7878C10.9688 22.6218 9.4248 32.9738 9.4248 32.9738C9.4248 32.9738 12.2168 26.0738 13.5668 20.0338Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.7256 46.2773C19.0016 46.3533 19.2936 46.4133 19.6016 46.4533"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.6992 44.5117C15.6992 44.5117 16.5412 45.4597 18.0432 46.0497"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M22.4995 20.6562C22.4995 20.6562 21.8195 21.3002 22.0295 22.3902C22.2395 23.4822 22.3155 24.1042 22.3155 24.1042C22.3155 24.1042 23.0895 24.2083 24.0835 24.0523C25.0775 23.8963 25.2995 23.5842 25.2995 23.5842C25.2995 23.5842 24.8335 21.2462 24.6495 20.7262"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M23.082 20.2995C23.082 20.2995 23.93 20.0495 24.29 20.3895"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M27.3441 60.5156L22.5021 61.8496C20.5761 62.3796 18.5961 62.6796 16.6001 62.7456L11.9141 62.8976L12.0061 69.9716L27.4161 66.9736L27.3441 60.5156Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M29.9991 90.1588L28.0971 64.4648L24.4831 66.0628C21.2611 67.4868 17.7911 68.2708 14.2691 68.3708L11.0711 68.4628L10.5791 90.1588H29.9991Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.3994 53.6804C18.3994 53.6804 21.7854 53.8044 25.3434 52.8164L25.6814 57.6264C25.6814 57.6264 21.1214 58.9204 18.5654 58.1804L18.3994 53.6804Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.178 55.6988C19.54 57.8548 18.714 59.8388 17.332 60.1288C15.95 60.4208 14.536 58.9088 14.174 56.7548C13.812 54.5988 14.638 52.6148 16.02 52.3248C17.402 52.0348 18.814 53.5428 19.178 55.6988Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.408 56.2359C19.412 56.4579 19.296 56.6459 19.15 56.6559C19.004 56.6639 18.882 56.4919 18.88 56.2679C18.876 56.0459 18.992 55.8579 19.138 55.8479C19.284 55.8399 19.404 56.0119 19.408 56.2359Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.6717 56.2203C19.6757 56.4423 19.5597 56.6303 19.4137 56.6403C19.2677 56.6483 19.1457 56.4763 19.1437 56.2523C19.1397 56.0303 19.2557 55.8423 19.4017 55.8323C19.5477 55.8243 19.6697 55.9983 19.6717 56.2203Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.4114 55.9136C18.7734 58.0696 17.9474 60.0536 16.5654 60.3436C15.1834 60.6356 13.7694 59.1236 13.4074 56.9696C13.0454 54.8136 13.8714 52.8296 15.2534 52.5396C16.6354 52.2496 18.0494 53.7596 18.4114 55.9136Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M25.6527 52.7595C25.4767 52.7915 25.3487 52.9495 25.3547 53.1255L25.5167 57.8355C25.5247 58.0535 25.7167 58.2075 25.9327 58.1735C26.1267 58.1415 26.2687 57.9675 26.2607 57.7735L26.0447 53.0675C26.0367 52.8655 25.8547 52.7235 25.6527 52.7595Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M16.3143 60.2136C17.5386 60.0802 18.3501 58.3111 18.1269 56.2621C17.9037 54.2132 16.7302 52.6603 15.5059 52.7936C14.2816 52.927 13.47 54.6962 13.6932 56.7451C13.9165 58.7941 15.0899 60.347 16.3143 60.2136Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.498 53.3555L15.57 53.9055"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.6162 53.9375L14.8422 54.3855"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.082 55.207L14.402 55.433"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.0381 56.8255L14.3661 56.7695"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.4961 58.3572L14.7441 58.0352"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.334 59.3907L15.434 58.8867"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M16.3259 59.6516L16.2539 59.1016"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.2065 59.0671L16.9805 58.6211"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.7429 57.7963L17.4229 57.5703"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.786 56.1797L17.458 56.2357"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.3291 54.6484L17.0811 54.9724"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M16.4896 53.6133L16.3896 54.1173"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.0039 56.6579L15.9659 56.7979L16.7139 55.0859"
        stroke="#242424"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaveTime;
