import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useUser } from '@contexts/User';
import AnalyticsService from '@services/analytics';

const UserLayout = () => {
  const { boot, shutdown } = useIntercom();
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      return undefined;
    }

    AnalyticsService.setUserId(user.id);
    AnalyticsService.setUserProperties({ email: user.email });
    boot({
      userId: user.id,
      email: user.email,
      verticalPadding: 75,
      horizontalPadding: 35,
    });

    return () => {
      AnalyticsService.setUserId(null);
      AnalyticsService.setUserProperties({ email: null });
      shutdown();
    };
  }, [user, boot, shutdown]);

  return (
    <div className="h-full max-h-full flex relative">
      <Outlet />
    </div>
  );
};

export default UserLayout;
