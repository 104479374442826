import { ROOT_ROUTES } from '@router/root';
import RootLayout from '@layouts/Root';
import UserLayout from '@layouts/User';
import OnboardingLayout from '@layouts/Onboarding';
import { RedirectRoot } from '@pages/RedirectPage';
import { NOT_FOUND, AUTH } from '@router/consts';
import { USER_ROUTES } from '@router/user';
import { AUTH_ROUTES } from '@router/auth';
import { ONBOARDING_ROUTES } from '@router/onboarding';
import { MainLoader } from '@components/index';

export const useRouterPaths = (user, profile, isMainLoading) => {
  if (isMainLoading) {
    return [
      {
        path: '*',
        element: (
          // <div className="w-full h-full flex items-center justify-center bg-white-brand-1">
          //   <Loader remSize={4} />
          // </div>
          <MainLoader />
        ),
      },
    ];
  }

  const layout = <RootLayout />;

  if (user) {
    if (!profile) {
      const onboardingRoutes = [
        ...ONBOARDING_ROUTES,
        ...ROOT_ROUTES,
        {
          path: NOT_FOUND,
          element: <RedirectRoot />,
        },
      ];

      return [{ element: <OnboardingLayout />, children: onboardingRoutes }];
    }

    const userRoutes = [
      ...USER_ROUTES,
      ...ROOT_ROUTES,
      {
        path: NOT_FOUND,
        element: <RedirectRoot />,
      },
    ];

    return [{ element: <UserLayout />, children: userRoutes }];
  }

  const authRoutes = [
    ...AUTH_ROUTES,
    ...ROOT_ROUTES,
    {
      path: NOT_FOUND,
      element: <RedirectRoot path={AUTH.SIGN_UP} />,
    },
  ];

  return [{ element: layout, children: authRoutes }];
};
