import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const Auth = {
  SignIn: loadable(() => import('./SignIn'), loadableOptions),
  SignUp: loadable(() => import('./SignUp'), loadableOptions),
  CheckEmail: loadable(() => import('./CheckEmail'), loadableOptions),
  EmailVerification: loadable(() => import('./EmailVerification'), loadableOptions),
  RestoreRequest: loadable(() => import('./RestoreRequest'), loadableOptions),
  RestoreNotification: loadable(() => import('./RestoreNotification'), loadableOptions),
  ResetPassword: loadable(() => import('./ResetPassword'), loadableOptions),
};

export default Auth;
