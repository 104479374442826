export const ROOT = '/';
export const NOT_FOUND = '*';

export const USER = {
  BASE_SELECTION: `${ROOT}base-selection`,
  TEMPLATE_BUILDER: `${ROOT}template-builder`,
  PLAN_DELIVERY: `${ROOT}plan-delivery`,
  CONGRATS_PAGE: `${ROOT}congrats`,
};

export const AUTH = {
  SIGN_IN: `${ROOT}sign-in`,
  SIGN_UP: `${ROOT}sign-up`,
  CHECK_EMAIL: `${ROOT}check-email`,
  EMAIL_VERIFICATION: `${ROOT}activate`,
  RESTORE_REQUEST: `${ROOT}restore-request`,
  RESTORE_NOTIFICATION: `${ROOT}restore-notifcation`,
  RESET_PASSWORD: `${ROOT}reset`,
};
