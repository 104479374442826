import { ALGORITHM_TYPES, DEVELOPMENT_STACK } from '@utils/const';

class CalculationsService {
  static getAlgorithmMultiplier(algoType, config) {
    switch (algoType) {
      case ALGORITHM_TYPES.SIMPLE:
        return config.singleAlgorithmMultiplier;
      case ALGORITHM_TYPES.COMPLEX:
        return config.complexAlgorithmMultiplier;
      default:
        return 1;
    }
  }

  static getDevStackMultiplier(algoType, config) {
    switch (algoType) {
      case DEVELOPMENT_STACK.FRONT:
        return config.frontendMultiplier;
      case DEVELOPMENT_STACK.BACK:
        return config.backendMultiplier;
      case DEVELOPMENT_STACK.FS:
        return config.fullstackMultiplier;
      default:
        return 1;
    }
  }

  static getModifiedHours(x, multipliers) {
    const numberOfPlatforms = 3;

    if (multipliers.length < numberOfPlatforms) {
      return x;
    }

    return multipliers.reduce((prev, curr) => prev + (curr * x - x), x);
  }

  static getDesignUICustomizationHours(feature, options) {
    if (options.hasOwnDesign || options.isWithoutDesign) {
      return 0;
    }

    const multipliers = options.designMultipliers;

    if (!multipliers || multipliers.length === 0) {
      return feature.designUICustomizationHours;
    }

    if (multipliers.length === 1) {
      return feature.designUICustomizationHours;
    }

    if (multipliers.length === 2) {
      const secondMobileCoef = 1.1;
      const multiplier = multipliers.find((item) => !item.isMobile)?.value ?? secondMobileCoef;
      return feature.designUICustomizationHours * multiplier;
    }

    const multipliersValues = multipliers.map((i) => i.value);
    const modifiedHours = this.getModifiedHours(
      feature.designUICustomizationHours,
      multipliersValues
    );
    const genericDesignPlatformsCustomizationCoef = 1.3;

    return options.isCustomDesign
      ? modifiedHours
      : feature.designUICustomizationHours *
          multipliers.length *
          genericDesignPlatformsCustomizationCoef;
  }

  static getCustomFeatureDesignHours(options, config, feature) {
    if (options.hasOwnDesign || options.isWithoutDesign) {
      return 0;
    }

    const multipliers = options.designMultipliers;
    const customFeatureUIHours = config.complexityLevelMultiplier * feature.complexity;

    if (!multipliers || multipliers.length === 0) {
      return customFeatureUIHours;
    }

    if (multipliers.length === 1) {
      return customFeatureUIHours;
    }

    if (multipliers.length === 2) {
      const secondMobileCoef = 1.1;
      const multiplier = multipliers.find((item) => !item.isMobile)?.value ?? secondMobileCoef;

      return customFeatureUIHours * multiplier;
    }

    const multipliersValues = multipliers.map((i) => i.value);
    const modifiedHours = this.getModifiedHours(customFeatureUIHours, multipliersValues);

    return modifiedHours;
  }

  static getDesignUXCustomizationHours(feature, options) {
    if (options.hasOwnDesign || options.isWithoutDesign) {
      return 0;
    }

    const multipliers = options.designMultipliers;
    if (!multipliers || multipliers.length === 0) {
      return feature.designUXCustomizationHours;
    }

    if (multipliers.length === 1) {
      return feature.designUXCustomizationHours;
    }

    if (multipliers.length === 2) {
      const secondMobileCoef = 1.1;
      const multiplier = multipliers.find((item) => !item.isMobile)?.value ?? secondMobileCoef;
      return feature.designUXCustomizationHours * multiplier;
    }

    const multipliersValues = multipliers.map((i) => i.value);
    const modifiedHours = this.getModifiedHours(
      feature.designUXCustomizationHours,
      multipliersValues
    );

    return options.isCustomDesign ? modifiedHours : 0;
  }

  static getDevCustomizationHours(feature, options) {
    const multipliers = options.devMultipliers;
    if (!multipliers || multipliers.length === 0) {
      return 0;
    }

    if (multipliers.length === 1) {
      return feature.devCustomizationHours;
    }

    if (multipliers.length === 2) {
      const secondMobileCoef = 1.3;
      const multiplier = multipliers.find((item) => !item.isMobile)?.value ?? secondMobileCoef;
      return feature.devCustomizationHours * multiplier;
    }

    const multipliersValues = multipliers.map((i) => i.value);
    const modifiedHours = this.getModifiedHours(feature.devCustomizationHours, multipliersValues);

    return options.isDevIncluded ? modifiedHours : 0;
  }

  static getCustomFeatureDevHours(config, options, feature) {
    const multipliers = options.devMultipliers;

    const featureHours = config.complexityLevelMultiplier * feature.complexity;
    const algoMultiplier = this.getAlgorithmMultiplier(feature.algorithmType, config);
    const stackMultiplier = this.getDevStackMultiplier(feature.developmentStack, config);
    const sourceHours = featureHours * algoMultiplier * stackMultiplier;

    if (!multipliers || multipliers.length === 0) {
      return 0;
    }

    if (multipliers.length === 1) {
      return sourceHours;
    }

    if (multipliers.length === 2) {
      const secondMobileCoef = 1.3;
      const multiplier = multipliers.find((item) => !item.isMobile)?.value ?? secondMobileCoef;

      return sourceHours * multiplier;
    }

    const multipliersValues = multipliers.map((i) => i.value);
    const modifiedHours = this.getModifiedHours(sourceHours, multipliersValues);

    return options.isDevIncluded ? modifiedHours : 0;
  }

  static getDesignFixedCost(feature, options) {
    if (options.hasOwnDesign || options.isWithoutDesign) {
      return 0;
    }

    return feature.designFixedCost;
  }

  static getCustomFeatureFixedCost() {
    return 0;
  }

  static getTemplateFeatureFixedCost(feature, options) {
    if (!feature || !options) {
      return 0;
    }

    const devFixedCost = options.isDevIncluded ? feature.devFixedCost ?? 0 : 0;

    return devFixedCost + this.getDesignFixedCost(feature, options) ?? 0;
  }

  static getCustomFeatureCustomizationCost(feature, config, options) {
    if (!feature || !config || !options) {
      return 0;
    }

    const devc = this.getCustomFeatureDevCost(feature, config, options);
    const desc = this.getCustomFeatureDesignCost(feature, config, options);

    return devc + desc;
  }

  static getTemplateFeatureCustomizationCost(feature, config, options) {
    if (!feature || !config || !options) {
      return 0;
    }

    const designHours =
      this.getDesignUICustomizationHours(feature, options) +
      (options.isCustomDesign ? this.getDesignUXCustomizationHours(feature, options) : 0);
    const designCustomizationCost = designHours * config.designRate;

    const devCustomizationCost = options.isDevIncluded
      ? this.getDevCustomizationHours(feature, options) * config.devRate
      : 0;

    return designCustomizationCost + devCustomizationCost;
  }

  static getTemplateFeatureDesignCost(feature, config, options) {
    if (!feature || !config || !options) {
      return 0;
    }

    if (options.isCustomDesign) {
      const designHours =
        this.getDesignUICustomizationHours(feature, options) +
        this.getDesignUXCustomizationHours(feature, options);
      const result = this.getDesignFixedCost(feature, options) + designHours * config.designRate;

      return result;
    }

    const result =
      this.getDesignUICustomizationHours(feature, options) * config.designRate +
      this.getDesignFixedCost(feature, options);

    return result;
  }

  static getTemplateFeatureDevCost(feature, config, options) {
    if (!feature || !config) {
      return 0;
    }

    return feature.devFixedCost + this.getDevCustomizationHours(feature, options) * config.devRate;
  }

  static getCustomFeatureDesignCost(feature, config, options) {
    if (!feature || !config) {
      return 0;
    }

    const designHours = this.getCustomFeatureDesignHours(options, config, feature);

    return designHours * config.designRate;
  }

  static getCustomFeatureDevCost(feature, config, options) {
    if (!feature || !config) {
      return 0;
    }

    return this.getCustomFeatureDevHours(config, options, feature) * config.devRate;
  }

  static getTemplateCost(features, config, options) {
    const featuresCosts = features.map((feature) => {
      const devCost = options.isDevIncluded
        ? this.getTemplateFeatureDevCost(feature, config, options)
        : 0;
      const designCost = this.getTemplateFeatureDesignCost(feature, config, options);

      return devCost + designCost;
    });

    return featuresCosts.reduce((prev, curr) => prev + curr, 0);
  }

  static getFeaturesTotalPrice(features, config, options) {
    const featuresCosts = features.map((feature) => {
      if (feature.featureCategoryId === 'custom') {
        const devCost = options.isDevIncluded
          ? this.getCustomFeatureDevCost(feature, config, options)
          : 0;
        const designCost = this.getCustomFeatureDesignCost(feature, config, options);

        return devCost + designCost;
      }

      const devCost = options.isDevIncluded
        ? this.getTemplateFeatureDevCost(feature, config, options)
        : 0;

      const designCost = this.getTemplateFeatureDesignCost(feature, config, options);

      return devCost + designCost;
    });

    return featuresCosts.reduce((prev, curr) => prev + curr, 0);
  }

  // Calculate timeline for all features and options. Returns weeks count.
  static getTimelineWeeks(features, config, options) {
    const hoursInWeek = 40;

    // Dev timeline calculation
    const featuresDevCustomizationHours = features.map((feature) =>
      feature.featureCategoryId === 'custom'
        ? this.getCustomFeatureDevHours(config, options, feature)
        : this.getDevCustomizationHours(feature, options)
    );

    const devHours = options.isDevIncluded
      ? featuresDevCustomizationHours.reduce((prev, curr) => prev + curr, 0)
      : 0;

    // Design timeline calculation
    const featuresDesignCustomizationHours = features.map((feature) => {
      if (feature.featureCategoryId === 'custom') {
        return this.getCustomFeatureDesignHours(options, config, feature);
      }

      return (
        this.getDesignUICustomizationHours(feature, options) +
        this.getDesignUXCustomizationHours(feature, options)
      );
    });
    const designHours = featuresDesignCustomizationHours.reduce((prev, curr) => prev + curr, 0);

    const devTimelineWeeks = options.isWithoutDev
      ? 0
      : devHours / (config.numberOfDevs * hoursInWeek);

    const designTimelineWeeks =
      options.isWithoutDesign || options.hasOwnDesign
        ? 0
        : designHours / (config.numberOfDesigners * hoursInWeek);

    // Prototype timeline
    const defaultLowTimelineHours = 40;
    const defaultHighTimelineHours = 80;
    const lowPrototypeTimeline = options.lowPrototype
      ? config.lowPrototype.hours ?? defaultLowTimelineHours
      : 0;
    const prototypeTimelineHours = options.highPrototype
      ? (config.highPrototype.hours ?? defaultHighTimelineHours) / config.numberOfDesigners
      : lowPrototypeTimeline / config.numberOfDesigners;
    const prototypeTimelineWeeks = prototypeTimelineHours / hoursInWeek;

    // Roadmap timeline
    const roadmapHours = options.roadmapIncluded ? config.roadmapHours : 0;
    const roadmapTimelineWeeks = roadmapHours / (config.numberOfDesigners * hoursInWeek);

    // Total timeline sum
    const totalTimelineWeeks =
      devTimelineWeeks + designTimelineWeeks + prototypeTimelineWeeks + roadmapTimelineWeeks;

    return Math.ceil(totalTimelineWeeks);
  }

  static getPrototypePrice(options, config) {
    if (!options) {
      return 0;
    }

    if (options.highPrototype) {
      return options.isDevIncluded
        ? config?.highPrototype?.withDev ?? 0
        : config?.highPrototype?.withoutDev ?? 0;
    }

    if (options.lowPrototype) {
      return options.isDevIncluded
        ? config?.lowPrototype?.withDev ?? 0
        : config?.lowPrototype?.withoutDev ?? 0;
    }

    return 0;
  }
}

export default CalculationsService;
