import loadable from '@loadable/component';
import { loadableOptions } from '@utils/helpers';

const User = {
  BaseSelection: loadable(() => import('./BaseSelection'), loadableOptions),
  PlanDelivery: loadable(() => import('./PlanDelivery'), loadableOptions),
  TemplateBuilder: loadable(() => import('./TemplateBuilder'), loadableOptions),
  CongratsPage: loadable(() => import('./CongratsPage'), loadableOptions),
};

export default User;
