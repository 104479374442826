import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';

import ResponsiveFontSizeProvider from '@contexts/ResponsiveFontSize';
import UserProvider from '@contexts/User';
import PricingProvider from '@contexts/Pricing';
import { ToastContainer } from '@components/index';
import config from '@config/config';
import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ResponsiveFontSizeProvider>
          <IntercomProvider appId={config.intercomId}>
            <GoogleOAuthProvider clientId={config.googleClientId}>
              <UserProvider>
                <PricingProvider>
                  <ToastContainer />
                  <App />
                </PricingProvider>
              </UserProvider>
            </GoogleOAuthProvider>
          </IntercomProvider>
        </ResponsiveFontSizeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
