import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ArrowRight } from '@assets/icons';
import { PAGE_TYPE } from '@utils/const';

const Stepper = ({ pageType, className, onHelpPressed }) => {
  const stylizeTextFor = (type) => {
    return pageType === type ? 'font-bold text-black-brand-1' : 'text-gray-3';
  };

  return (
    <div
      className={clsx(
        className,
        'flex items-center justify-between bg-white-default border-b-[0.1rem] border-gray-5 gap-4 px-[3.9rem] py-6'
      )}
    >
      <div className="flex flex-1 flex-row items-center gap-x-2">
        {/* <p
          className={`text-sm leading-[1.375rem] line-clamp-2 text-start xl:text-base ${stylizeTextFor(
            PAGE_TYPE.BASE_SELECTION
          )}`}
        >
          1. Choose a base
        </p> */}
        {/* <ArrowRight color="var(--colors-gray-3)" /> */}
        <p
          className={`text-sm leading-[1.375rem] line-clamp-2 text-start xl:text-base ${stylizeTextFor(
            PAGE_TYPE.FEATURE_ADJUSTMENT
          )}`}
        >
          1. Define features
        </p>
        <ArrowRight color="var(--colors-gray-3)" />
        <p
          className={`text-sm leading-[1.375rem] line-clamp-2 text-start xl:text-base ${stylizeTextFor(
            PAGE_TYPE.PLAN_DELIVERY
          )}`}
        >
          2. Choose deliverables
        </p>
      </div>
      <div
        className="flex flex-row items-center p-2 border-gray-5 border-[0.12rem] rounded-lg gap-x-4 hover:scale-105 duration-200 ease-in-out cursor-pointer"
        onClick={onHelpPressed}
      >
        <img alt="Margo" src="https://i.postimg.cc/Jnsntjmh/expert.png" className="w-7 h-7" />
        <p className="text-xs line-clamp-1 xl:text-sm">Talk to our experts</p>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  pageType: PropTypes.number.isRequired,
  onHelpPressed: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Stepper.defaultProps = {
  className: '',
};

export default React.memo(Stepper);
