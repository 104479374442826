import clsx from 'clsx';

const CostFooter = ({ price, timeline, isActive, isPopupVisible, setIsPopupVisible, children }) => {
  return (
    <div className="flex pl-6 pr-9 items-center bg-white-default justify-between border-t-[0.1rem] border-gray-5">
      <div
        onClick={() => {
          // if (isActive) {
          //   setIsPopupVisible((prev) => !prev);
          // }
        }}
        className={clsx(
          'flex self-stretch items-center my-2 px-3 rounded-md',
          isActive && [
            'cursor-pointer',
            isPopupVisible ? 'bg-gray-6' : 'cursor-pointer hover:bg-gray-6',
          ]
        )}
      >
        <div className="hidden mr-16">
          <span className="mr-2.5 text-base leading-[1.188rem] text-black-brand-1">Price:</span>
          <span className="text-black-brand-1 text-base leading-[1.188rem] font-bold">
            {price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </span>
        </div>
        <div>
          <span className="mr-2.5 text-base leading-[1.188rem] text-black-brand-1">Timeline:</span>
          <span className="text-black-brand-1 text-base leading-[1.188rem] font-bold">
            {timeline} weeks
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CostFooter;
