import PropTypes from 'prop-types';
import { BUTTON } from '@utils/const';
import clsx from 'clsx';

import { Loader } from '@components/Loaders';

// eslint-disable-next-line react/prop-types
const Button = ({ className, disabled, loading, type, variant, onClick, children }) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={clsx(
        'flex flex-row items-center text-center',
        disabled ? 'opacity-60' : !loading && 'hover:opacity-80',
        className
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {loading && (
        <Loader remSize={1.5} stroke="var(--colors-white-default)" className="absolute" />
      )}
      <div className={clsx('flex justify-center items-center', loading ? 'opacity-0' : '')}>
        {children}
      </div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(BUTTON.TYPES)),
  variant: PropTypes.oneOf(Object.values(BUTTON.VARIANTS)),
};

Button.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  type: BUTTON.TYPES.BUTTON,
  variant: BUTTON.VARIANTS.DEFAULT,
};

export default Button;
