import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { MainLoader } from '@components/Loaders';
import { ToastByType } from '@components/ToastContainer';

export const generateUUID = () => uuid();

export const loadableOptions = {
  fallback: <MainLoader />,
};

export const setFieldForm = (method, errorMethod, nameField, val) => {
  method(nameField, val, false);
  errorMethod(nameField, undefined);
};

export const showToast = (type, message) => {
  const Component = ToastByType[type];
  toast(<Component message={message} />);
};

export const openNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const sortCategories = (categories) => {
  const orderSchema = {
    essentials: 1,
    communication: 2,
    'educational content': 3,
    community: 4,
    'health apps integration': 6,
    payment: 7,
    administration: 8,
    'security & privacy': 9,
    additional: 10,
    custom: 11,
  };

  const freeOrderCode = 5;

  return categories.sort((a, b) => {
    const orderCodeA = orderSchema[a.name?.toLowerCase()] ?? freeOrderCode;
    const orderCodeB = orderSchema[b.name?.toLowerCase()] ?? freeOrderCode;
    const result = orderCodeA - orderCodeB;

    // eslint-disable-next-line no-nested-ternary
    return result === 0 ? (a.name < b.name ? -1 : a.name === b.name ? 0 : 1) : result;
  });
};
