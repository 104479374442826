import { useRoutes } from 'react-router-dom';

import { useRouterPaths } from '@hooks/useRouterPaths';

import './styles/main.scss';
import { useUser } from '@contexts/User';

const App = () => {
  // pass roles, etc. form context (?) to function
  const { user, profile, isMainLoading } = useUser();
  const routers = useRouterPaths(user, profile, isMainLoading);
  const routes = useRoutes(routers);

  return routers && routes;
};

export default App;
