import clsx from 'clsx';
import PropTypes from 'prop-types';

const Eye = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={clsx('w-4 h-4', className)}
    {...props}
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      clipPath="url(#clip0_2590_5941)"
    >
      <path d="M.664 8.001s2.667-5.333 7.333-5.333c4.667 0 7.334 5.333 7.334 5.333s-2.667 5.334-7.334 5.334C3.331 13.335.664 8 .664 8z" />
      <path d="M8 10a2 2 0 100-4 2 2 0 000 4z" />
    </g>
    <defs>
      <clipPath id="clip0_2590_5941">
        <path fill={color} d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);

Eye.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Eye.defaultProps = {
  color: '#000',
  className: '',
};

export default Eye;
