import config from '@config/config';

import ApiService from './api';

class ProfileApi extends ApiService {
  constructor() {
    if (ProfileApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return ProfileApi.instance;
    }

    super(config.accountApiUrl);
    ProfileApi.instance = this;
  }

  async create(data) {
    const response = await this.http.post(`/persons`, data);
    return response.data;
  }

  async getByUserId(userId) {
    const response = await this.http.get(`/persons?userId=${userId}`);
    return response.data;
  }
}

const profileApiInstance = new ProfileApi();
export default profileApiInstance;
