import { Slide, ToastContainer as BaseToastContainer } from 'react-toastify';
import clsx from 'clsx';

import { TOAST_TYPES } from '@utils/const';
import 'react-toastify/dist/ReactToastify.css';

const BaseToast = ({ message, className }) => {
  return (
    <div className={clsx('flex justify-center items-center py-4', className)}>
      <span className="font-bold text-sm">{message}</span>
    </div>
  );
};

const Info = ({ message }) => (
  <BaseToast message={message} className="bg-light-blue text-black-brand-1" />
);
const Error = ({ message }) => (
  <BaseToast message={message} className="bg-red-500 text-white-default" />
);
const Success = ({ message }) => (
  <BaseToast message={message} className="bg-green text-white-default" />
);

export const ToastByType = {
  [TOAST_TYPES.INFO]: Info,
  [TOAST_TYPES.ERROR]: Error,
  [TOAST_TYPES.SUCCESS]: Success,
};

const ToastContainer = () => (
  <BaseToastContainer
    style={{
      top: 0,
      padding: 0,
      width: '100%',
    }}
    toastStyle={{
      padding: 0,
      margin: 0,
      minHeight: 0,
      borderRadius: 0,
    }}
    bodyStyle={{
      padding: 0,
      margin: 0,
    }}
    position="top-center"
    autoClose={3000}
    closeButton={false}
    hideProgressBar
    draggable={false}
    pauseOnFocusLoss={false}
    pauseOnHover
    transition={Slide}
  />
);

export default ToastContainer;
