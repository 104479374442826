import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Tooltip = ({ children, message, messageClassName }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute bottom-0 flex-col border-gray-3 items-center hidden mb-6 group-hover:flex">
        <span
          className={clsx(
            'relative z-30 py-3 px-5 w-64 text-xs leading-[0.875rem] text-gray-3 whitespace-no-wrap bg-white-default shadow-lg border-gray-6 border-[0.15rem] rounded-md',
            messageClassName
          )}
        >
          {message}
        </span>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired,
  messageClassName: PropTypes.string,
};

Tooltip.defaultProps = {
  messageClassName: '-ml-32',
};

export default React.memo(Tooltip);
