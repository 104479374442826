/* eslint-disable react/prop-types */
import { useCallback, useRef, useState } from 'react';
import { useOutside } from '@hooks/useOutside';
import { Element, scroller } from 'react-scroll/modules';
import { Arrow } from '@assets/icons';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import useDeferredRenderEffect from '@hooks/useDifferredRenderEffects';
import styles from './Dropdown.module.scss';

const Dropdown = ({
  onChange,
  classSelect,
  label,
  options,
  scrollID = 'default',
  error,
  value,
  className,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutside(wrapperRef, setIsOpen);

  const scroll = (fieldName) => {
    scroller.scrollTo(`value-${fieldName}`, {
      duration: 300,
      smooth: true,
      containerId: `dropdown-${scrollID}`,
      offset: -5,
    });
  };

  const viewLabel = useCallback(() => {
    if (value || value === 0) {
      return (
        <span className="text-dawn-gray">{options.find((v) => v.value === value)?.label}</span>
      );
    }
    if (placeholder) {
      return <span className="text-gray-light">{placeholder}</span>;
    }
    return <span className="text-dawn-gray"> - </span>;
  }, [value, placeholder, options]);

  useDeferredRenderEffect(() => {
    if (value && isOpen) {
      scroll(value);
    }
  }, [value, isOpen]);

  const onClick = (option) => {
    if (!option?.value) {
      return;
    }

    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={clsx('relative w-full', className)}>
      {!!label && (
        <p className="block text-sm leading-[1.063rem] text-black-brand-1 mb-2">{label}</p>
      )}
      <div
        className={clsx(
          'flex items-center justify-between w-full py-4 px-5 h-12 rounded bg-gray-6 outline-none text-sm cursor-pointer',
          classSelect,
          isOpen ? 'pointer-events-none' : '',
          error ? 'border border-red-400' : ''
        )}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {viewLabel()}
        <Arrow className="transform rotate-90 stroke-current text-dawn-gray" />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute z-20 flex flex-1 flex-row w-full rounded-sm top-full shadow overflow-y-hidden max-h-80"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, translateY: '0.625rem' }}
            exit={{ opacity: 0, translateY: '1.25rem' }}
          >
            <div
              className={clsx('flex flex-col flex-1 bg-white p-1', styles.hideWithScrollbar)}
              ref={wrapperRef}
              id={`dropdown-${scrollID}`}
            >
              {options.map((item) => (
                <Element
                  id={item.value}
                  key={item.value}
                  name={`value-${item.value}`}
                  className={clsx(
                    'px-2 text-dawn-gray relative rounded-md py-2 cursor-pointer flex flex-row justify-between items-center',
                    value === item.value ? 'bg-gray-6 font-medium' : 'hover:bg-gray-50'
                  )}
                  onClick={() => onClick(item)}
                >
                  {item.label}
                  {value === item.value ? <Arrow className="mb-1" /> : <></>}
                </Element>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} mode="wait">
        <motion.p
          key={error || 'empty'}
          animate={{ opacity: 1, y: 2 }}
          initial={{ opacity: 0, y: -4 }}
          exit={{ opacity: 0, y: -4 }}
          transition={{ duration: 0.2 }}
          className="absolute text-red-400 text-xs font-light"
        >
          {error}
        </motion.p>
      </AnimatePresence>
    </div>
  );
};
export default Dropdown;
