import config from '@config/config';

import ApiService from './api';

class PricingConfigApi extends ApiService {
  constructor() {
    if (PricingConfigApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return PricingConfigApi.instance;
    }

    super(config.workbenchApiUrl);
    PricingConfigApi.instance = this;

    this.getActiveConfig = this.getActiveConfig.bind(this);
  }

  async getActiveConfig() {
    const response = await this.http.get(`/configs/versions/active`);
    return response.data;
  }
}

const pricingApiInstance = new PricingConfigApi();
export default pricingApiInstance;
