export const INPUT = {
  TYPES: {
    TEXT: 'text',
    EMAIL: 'email',
    PASSWORD: 'password',
    PICKER: 'picker',
    AREA: 'area',
  },
};

export const BUTTON = {
  TYPES: {
    BUTTON: 'button',
    SUBMIT: 'submit',
  },
  VARIANTS: {
    DEFAULT: 'default',
  },
};

export const PAGE_TYPE = {
  BASE_SELECTION: 1,
  FEATURE_ADJUSTMENT: 2,
  PLAN_DELIVERY: 3,
};

export const MEDIA_FONT_SIZE = {
  XS: 11,
  SM: 12,
  MD: 14,
  BASE: 16,
};

export const MEDIA_SCREEN = {
  SM: 1280,
  MD: 1602,
  LG: 1920,
};

export const TOAST_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const ALGORITHM_TYPES = {
  NONE: 'None',
  SIMPLE: 'Simple',
  COMPLEX: 'Complex',
};

export const DEVELOPMENT_STACK = {
  FRONT: 'Frontend only',
  BACK: 'Backend only',
  FS: 'Frontend & Backend',
};
