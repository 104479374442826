const Svg = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_353_7254)">
        <path
          d="M23.9873 12.6223C23.9873 11.6069 23.9057 10.866 23.729 10.0977H12.239V14.6804H18.9833C18.8474 15.8192 18.1132 17.5343 16.4814 18.6868L16.4585 18.8402L20.0915 21.6801L20.3431 21.7055C22.6547 19.5513 23.9873 16.3817 23.9873 12.6223Z"
          fill="#4285F4"
        />
        <path
          d="M12.2391 24.6994C15.5433 24.6994 18.3171 23.6017 20.3432 21.7083L16.4815 18.6896C15.4481 19.4168 14.0611 19.9245 12.2391 19.9245C9.00291 19.9245 6.25622 17.7704 5.27711 14.793L5.13359 14.8053L1.35604 17.7552L1.30664 17.8938C3.31906 21.9277 7.45273 24.6994 12.2391 24.6994Z"
          fill="#34A853"
        />
        <path
          d="M5.27702 14.7917C5.01867 14.0234 4.86916 13.2001 4.86916 12.3494C4.86916 11.4987 5.01867 10.6755 5.26343 9.90711L5.25659 9.74347L1.4317 6.74609L1.30655 6.80616C0.47714 8.48012 0.0012207 10.3599 0.0012207 12.3494C0.0012207 14.3389 0.47714 16.2186 1.30655 17.8926L5.27702 14.7917Z"
          fill="#FBBC05"
        />
        <path
          d="M12.2391 4.77481C14.5371 4.77481 16.0871 5.77643 16.971 6.61346L20.4248 3.21067C18.3036 1.22116 15.5433 0 12.2391 0C7.45273 0 3.31906 2.77158 1.30664 6.80547L5.26351 9.90642C6.25622 6.92901 9.00291 4.77481 12.2391 4.77481Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_353_7254">
          <rect width="24" height="24.7826" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;
