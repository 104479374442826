import React from 'react';
import PropTypes from 'prop-types';

import { Logo, Question, Logout } from '@assets/icons';
import { useUser } from '@contexts/User';
import { openNewTab } from '@utils/helpers';

import AnalyticsService from '@services/analytics';

const LeftBar = ({ children }) => {
  const { logout } = useUser();

  return (
    <div className="flex flex-col justify-between w-[22rem] bg-white-default border-r-[0.1rem] border-gray-5 py-10">
      <div className="flex flex-row items-end mb-12 px-8">
        <Logo className="mr-2 w-7 h-7" />
        <p className="text-sm font-semibold">Workbench</p>
      </div>
      {children}
      <div className="flex flex-col mt-10 px-8">
        <div
          className="flex flex-row items-center mb-6 cursor-pointer hover:opacity-80"
          onClick={() => {
            openNewTab('https://www.notion.so/Workbench-FAQ-9688a6d7b1a0476993f16efc1b5931a0');
            AnalyticsService.logEvent({
              name: 'click FAQ',
            });
          }}
        >
          <div className="w-6 h-6 flex items-center justify-center mr-2">
            <Question />
          </div>
          <p>FAQ</p>
        </div>
        <div
          className="flex flex-row items-center cursor-pointer hover:opacity-80"
          onClick={() => {
            AnalyticsService.logEvent({
              name: 'click logout',
            });

            logout();
          }}
        >
          <div className="w-6 h-6 flex items-center justify-center mr-2">
            <Logout />
          </div>
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
};

LeftBar.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default React.memo(LeftBar);
