import { Outlet } from 'react-router-dom';

import { Logo } from '@assets/icons';

const RootLayout = () => {
  return (
    <div className="h-full max-h-full flex relative bg-white-brand-1">
      <div className="absolute top-10 left-8">
        <div className="flex flex-row items-end">
          <Logo className="mr-2 w-7 h-7" />
          <p className="text-sm font-bold">Workbench</p>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default RootLayout;
