import User from '@pages/User';
import { USER } from './consts';

export const USER_ROUTES = [
  // {
  //   element: <User.BaseSelection />,
  // },
  {
    index: true,
    element: <User.TemplateBuilder />,
  },
  {
    path: USER.PLAN_DELIVERY,
    element: <User.PlanDelivery />,
  },
  {
    path: USER.CONGRATS_PAGE,
    element: <User.CongratsPage />,
  },
];
