import amplitude from 'amplitude-js';

import config from '@config/config';

class AnalyticsService {
  constructor() {
    if (AnalyticsService.instance) {
      // eslint-disable-next-line no-constructor-return
      return AnalyticsService.instance;
    }

    if (config.amplitudeAnalyticsId) {
      this.ampInstance = amplitude.getInstance();
      this.ampInstance.init(config.amplitudeAnalyticsId);
      AnalyticsService.instance = this;
    }

    this.logEvent = this.logEvent.bind(this);
  }

  setUserId(userId) {
    this.ampInstance?.setUserId(userId);
  }

  setUserProperties(properties) {
    this.ampInstance?.setUserProperties(properties);
  }

  logEvent({ name, category = 'generic', properties = {} }) {
    this.ampInstance?.logEvent(`${category} - ${name}`, properties);
  }
}

const analyticsServiceInstance = new AnalyticsService();
export default analyticsServiceInstance;
