const Arrow = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5303 10.079C18.8232 10.3719 18.8232 10.8468 18.5303 11.1397L12.5303 17.1397C12.2374 17.4326 11.7626 17.4326 11.4697 17.1397L5.46967 11.1397C5.17678 10.8468 5.17678 10.3719 5.46967 10.079C5.76256 9.78615 6.23744 9.78615 6.53033 10.079L12 15.5487L17.4697 10.079C17.7626 9.78615 18.2374 9.78615 18.5303 10.079Z"
        fill="#242424"
      />
    </svg>
  );
};

export default Arrow;
