const Trashcan = (props) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.75 3.5h10.5M4.666 3.501V2.335a1.167 1.167 0 0 1 1.167-1.167h2.333a1.167 1.167 0 0 1 1.167 1.167V3.5m1.75 0v8.167a1.167 1.167 0 0 1-1.167 1.167H4.083a1.167 1.167 0 0 1-1.167-1.167V3.501h8.167Z"
      stroke="#242424"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Trashcan;
