const Check = (props) => (
  <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.089 7.79c.126.134.302.21.486.21h.005a.665.665 0 0 0 .487-.217l6.091-6.667a.667.667 0 1 0-.984-.899L4.568 6.352l-2.75-2.927a.667.667 0 0 0-.971.912L4.089 7.79Z"
      fill="#fff"
    />
  </svg>
);

export default Check;
