const BuildOnTop = () => {
  return (
    <svg
      width={100}
      height={102}
      viewBox="0 0 100 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.81 68.3555C86.1078 91.2011 60.2652 103.998 37.8528 100.546C34.2059 99.9827 24.2075 98.3108 15.3574 90.4176C-1.68204 75.2197 -5.32897 45.1307 8.41742 23.9244C18.4194 8.48452 36.3543 -0.264491 53.3938 0.176029C57.066 0.26991 71.1337 0.851253 83.4935 11.0446C85.3098 12.5431 89.3142 16.0997 92.925 21.7434C101.403 34.9988 102.133 53.4645 95.81 68.3555Z"
        fill="#F2F2F2"
      />
      <path
        d="M67.6468 100.839H41.2012V49.7283C41.2012 48.9231 41.8547 48.2695 42.6599 48.2695H66.1844C66.9896 48.2695 67.6432 48.9231 67.6432 49.7283V100.839H67.6468Z"
        fill="url(#paint0_linear_661_7275)"
      />
      <path
        d="M60.93 100.839H34.4844V49.7283C34.4844 48.9231 35.1379 48.2695 35.9431 48.2695H59.4676C60.2728 48.2695 60.9264 48.9231 60.9264 49.7283V100.839H60.93Z"
        fill="url(#paint1_linear_661_7275)"
      />
      <path
        opacity="0.76"
        d="M36.2584 58.0877C36.1573 58.0877 36.0742 58.0047 36.0742 57.9036V54.1736C36.0742 52.1841 37.533 50.5664 39.3276 50.5664H44.4044C44.5055 50.5664 44.5885 50.6495 44.5885 50.7506C44.5885 50.8517 44.5055 50.9347 44.4044 50.9347H39.3276C37.7352 50.9347 36.4389 52.3899 36.4389 54.1736V57.9036C36.4389 58.0083 36.3595 58.0877 36.2584 58.0877Z"
        fill="white"
      />
      <path
        opacity="0.76"
        d="M36.2584 62.0447C36.1573 62.0447 36.0742 61.9617 36.0742 61.8606V60.3404C36.0742 60.2393 36.1573 60.1562 36.2584 60.1562C36.3595 60.1562 36.4425 60.2393 36.4425 60.3404V61.8606C36.4389 61.9617 36.3595 62.0447 36.2584 62.0447Z"
        fill="white"
      />
      <path
        d="M37.116 100.841H14.3281V65.1697C14.3281 64.3645 14.9817 63.7109 15.7869 63.7109H35.6536C36.4588 63.7109 37.1124 64.3645 37.1124 65.1697V100.841H37.116Z"
        fill="url(#paint2_linear_661_7275)"
      />
      <path
        d="M30.3972 100.841H7.60938V65.1697C7.60938 64.3645 8.26293 63.7109 9.06815 63.7109H28.9349C29.7401 63.7109 30.3936 64.3645 30.3936 65.1697V100.841H30.3972Z"
        fill="url(#paint3_linear_661_7275)"
      />
      <g opacity="0.73">
        <path
          d="M9.49079 74.2833C9.38969 74.2833 9.30664 74.2003 9.30664 74.0992V69.6976C9.30664 67.3686 11.0146 65.4766 13.1124 65.4766H19.1028C19.2039 65.4766 19.2869 65.5596 19.2869 65.6607C19.2869 65.7618 19.2039 65.8449 19.1028 65.8449H13.1161C11.2204 65.8449 9.67494 67.5744 9.67494 69.7012V74.1028C9.67494 74.2003 9.59189 74.2833 9.49079 74.2833Z"
          fill="white"
        />
        <path
          d="M9.49079 78.948C9.38969 78.948 9.30664 78.865 9.30664 78.7639V76.9693C9.30664 76.8682 9.38969 76.7852 9.49079 76.7852C9.59189 76.7852 9.67494 76.8682 9.67494 76.9693V78.7639C9.67494 78.8686 9.59189 78.948 9.49079 78.948Z"
          fill="white"
        />
      </g>
      <path
        d="M93.2879 100.841H70.5V65.1697C70.5 64.3645 71.1536 63.7109 71.9588 63.7109H91.8255C92.6307 63.7109 93.2843 64.3645 93.2843 65.1697V100.841H93.2879Z"
        fill="url(#paint4_linear_661_7275)"
      />
      <path
        d="M86.5691 100.841H63.7812V65.1697C63.7812 64.3645 64.4348 63.7109 65.24 63.7109H85.1067C85.912 63.7109 86.5655 64.3645 86.5655 65.1697V100.841H86.5691Z"
        fill="url(#paint5_linear_661_7275)"
      />
      <path
        opacity="0.76"
        d="M65.6646 74.2833C65.5635 74.2833 65.4805 74.2003 65.4805 74.0992V69.6976C65.4805 67.3686 67.1884 65.4766 69.2863 65.4766H75.2766C75.3777 65.4766 75.4608 65.5596 75.4608 65.6607C75.4608 65.7618 75.3777 65.8449 75.2766 65.8449H69.2863C67.3906 65.8449 65.8452 67.5744 65.8452 69.7012V74.1028C65.8452 74.2003 65.7657 74.2833 65.6646 74.2833Z"
        fill="white"
      />
      <path
        opacity="0.76"
        d="M65.6646 78.948C65.5635 78.948 65.4805 78.865 65.4805 78.7639V76.9693C65.4805 76.8682 65.5635 76.7852 65.6646 76.7852C65.7657 76.7852 65.8488 76.8682 65.8488 76.9693V78.7639C65.8452 78.8686 65.7657 78.948 65.6646 78.948Z"
        fill="white"
      />
      <path
        d="M62.832 93.6001L81.5361 57.7663H77.705C76.9973 57.7663 76.6109 56.943 77.0587 56.3978L96.994 32.1006C97.857 31.0463 99.5649 31.7143 99.4854 33.0755L98.0844 56.8347C98.005 58.196 96.799 59.207 95.4449 59.0554L92.7729 58.7484L74.2133 98.9405C73.7295 99.9876 72.6823 100.656 71.5305 100.656H65.0996L62.832 93.6001Z"
        fill="#828282"
      />
      <path
        d="M47.3672 68.4686L60.572 97.8029C61.3555 99.5397 63.0815 100.659 64.988 100.659H71.7944C72.6971 100.659 73.5312 100.168 73.9681 99.3808L74.21 98.9439C73.477 98.9439 72.809 98.5323 72.4768 97.8823L54.8813 63.4062L47.3672 68.4686Z"
        fill="#828282"
      />
      <path
        d="M56.6618 66.8925C55.3366 65.8453 53.4012 66.1559 52.4696 67.5605L33.0543 96.7287C31.498 99.0648 28.8802 100.469 26.0709 100.469H23.3375L21.1133 94.0639C21.1133 94.0639 39.1638 66.3906 43.8145 62.202C43.8145 62.202 48.4472 58.0279 53.0149 61.1838C54.2209 62.0179 55.138 63.2094 55.6977 64.5635L56.6618 66.8925Z"
        fill="#828282"
      />
      <path
        d="M0 76.8347C0 76.8347 3.24974 72.7725 9.547 72.1406C9.547 72.1406 12.5368 73.1589 18.4982 83.8361C24.4561 94.5133 28.4135 99.3842 33.0534 96.7231L32.6707 97.3622C31.4033 99.4637 29.1285 100.749 26.6731 100.749H24.1058C21.2786 100.749 18.8268 96.3945 17.1731 94.1016C12.5368 87.6563 2.84894 77.8349 0 76.8347Z"
        fill="#828282"
      />
      <path
        d="M97.2321 40.5912C97.2249 40.5912 97.2177 40.5912 97.2104 40.5912C97.1093 40.5804 97.0371 40.4901 97.048 40.389L97.1779 39.23C97.1888 39.1289 97.279 39.0566 97.3801 39.0675C97.4812 39.0783 97.5535 39.1686 97.5426 39.2697L97.4126 40.4288C97.4018 40.5226 97.3224 40.5912 97.2321 40.5912Z"
        fill="#F2F2F2"
      />
      <path
        d="M80.5887 55.2302C80.549 55.2302 80.5093 55.2194 80.4768 55.1905C80.3973 55.1291 80.3829 55.0135 80.4443 54.9341L96.906 33.5436C97.101 33.2909 97.4151 33.2078 97.7112 33.327C98.0073 33.4461 98.1734 33.7278 98.1373 34.0419L97.8484 36.6056C97.8376 36.7067 97.7473 36.7789 97.6462 36.7681C97.5451 36.7572 97.4729 36.667 97.4837 36.5659L97.7726 34.0022C97.7943 33.8216 97.6823 33.7133 97.574 33.6664C97.4657 33.6194 97.3068 33.6267 97.1985 33.7675L80.7367 55.158C80.697 55.2085 80.6429 55.2302 80.5887 55.2302Z"
        fill="#F2F2F2"
      />
      <path
        d="M43.2458 77.6563C43.2097 77.6563 43.1736 77.6454 43.1447 77.6238C43.0617 77.566 43.04 77.454 43.0942 77.371L51.6085 64.7512C51.9118 64.2998 52.4209 64.0543 52.9625 64.094C53.5078 64.1337 53.9699 64.4551 54.2046 64.9462C54.248 65.0364 54.2083 65.1484 54.118 65.1917C54.0277 65.235 53.9158 65.1953 53.8725 65.105C53.6955 64.7331 53.3453 64.4912 52.9336 64.4587C52.522 64.4262 52.1393 64.614 51.9082 64.9534L43.3939 77.5732C43.3614 77.631 43.3036 77.6563 43.2458 77.6563Z"
        fill="#F2F2F2"
      />
      <path
        d="M41.0349 80.9361C40.9988 80.9361 40.9627 80.9252 40.9338 80.9036C40.8507 80.8458 40.8291 80.7339 40.8832 80.6508L41.4285 79.842C41.4862 79.7589 41.5982 79.7373 41.6812 79.7914C41.7643 79.8492 41.7859 79.9611 41.7318 80.0442L41.1865 80.853C41.154 80.9072 41.0927 80.9361 41.0349 80.9361Z"
        fill="#F2F2F2"
      />
      <path
        d="M62.7006 2.30264C66.752 1.10024 70.6192 1.42521 72.5473 1.93434C74.8294 2.53734 75.584 2.9887 77.6061 3.21979C79.4007 3.42199 84.1706 1.97044 83.5603 3.06091C82.7659 4.48357 82.9826 10.0623 84.1706 12.3877C84.3439 12.7271 78.6929 13.5648 75.2699 12.9365C70.5506 12.0735 69.0304 10.6292 65.9323 11.2864C64.5024 11.5897 63.4156 12.1963 62.7223 12.6621C63.0401 9.22458 62.3829 5.74014 62.7006 2.30264Z"
        fill="#E5796E"
      />
      <path
        d="M62.7506 48.5665C62.6495 48.5665 62.5664 48.4834 62.5664 48.3823V1.19978C62.5664 1.09867 62.6495 1.01562 62.7506 1.01562C62.8517 1.01562 62.9347 1.09867 62.9347 1.19978V48.3859C62.9347 48.487 62.8517 48.5665 62.7506 48.5665Z"
        fill="#26264F"
      />
      <path
        d="M42.459 48.5738C42.459 48.5485 42.459 48.5233 42.4662 48.498C42.8381 47.0284 44.3511 46.2773 44.3511 46.2773L45.0804 46.3351C45.4993 47.6278 45.7701 48.2597 45.7015 48.6135C45.6979 48.6244 45.6979 48.6316 45.6943 48.6424C45.0913 48.6966 43.8564 48.7327 42.459 48.5738Z"
        fill="#242424"
      />
      <path
        d="M45.7021 48.6389C45.6696 48.7581 45.5829 48.8483 45.471 48.8736C44.6152 49.0542 43.2576 48.9097 42.6871 48.8339C42.5607 48.8158 42.4704 48.7003 42.4668 48.5703C43.8642 48.7292 45.1027 48.6931 45.7021 48.6389Z"
        fill="#4F4F4F"
      />
      <path
        d="M43.752 45.1445L44.0697 46.6791C44.0914 46.7911 44.178 46.8741 44.2827 46.8849C44.4091 46.8958 44.5933 46.903 44.7774 46.8777C44.9435 46.8524 45.0555 46.6864 45.0266 46.5058L44.8063 45.1445H43.752Z"
        fill="url(#paint6_linear_661_7275)"
      />
      <path
        d="M51.8729 48.6283C51.0966 48.6066 50.6921 48.5163 50.4863 48.4297C50.4863 48.5055 50.4863 48.585 50.4899 48.668C50.4935 48.7547 50.5585 48.8233 50.638 48.8233L54.9096 48.7944C55.0468 48.7944 55.1262 48.6355 55.0648 48.5091C54.5016 48.5561 53.0753 48.6608 51.8729 48.6283Z"
        fill="#4F4F4F"
      />
      <path
        d="M55.071 48.5122C55.0674 48.5014 55.0602 48.4906 55.0529 48.4797C54.6774 47.9526 53.7169 46.9018 51.8177 46.4469L50.9077 46.418C50.9077 46.418 50.5033 47.1221 50.4961 48.4292C50.7055 48.5159 51.1063 48.6061 51.8826 48.6278C53.085 48.6603 54.5113 48.5556 55.071 48.5122Z"
        fill="#242424"
      />
      <path
        d="M50.7383 45.0406C50.7491 45.1092 50.8719 46.1419 50.9224 46.5391C50.9333 46.6329 51.0019 46.7088 51.0885 46.7196C51.2438 46.7377 51.4929 46.7557 51.7132 46.7088C51.8324 46.6835 51.919 46.5716 51.919 46.438V44.8203L50.7383 45.0406Z"
        fill="url(#paint7_linear_661_7275)"
      />
      <path
        d="M44.3593 24.9883C44.3593 24.9883 42.9005 37.9403 43.713 46.0357C43.713 46.0357 44.6843 46.0357 45.1717 45.8733C45.1717 45.8733 46.6847 33.2101 47.1757 29.5849C47.1938 29.4476 47.3888 29.4404 47.4177 29.5776C48.0207 32.6252 50.3966 44.5842 50.6782 45.7108C50.6782 45.7108 51.4871 46.0357 52.137 45.7108C52.137 45.7108 50.8407 28.3861 49.8694 25.3096L44.3593 24.9883Z"
        fill="#242424"
      />
      <path
        d="M41.4445 9.93007C41.4445 9.93007 46.3444 9.26929 50.8146 9.94452C51.9195 10.1106 52.6922 11.1289 52.5406 12.2374L52.1289 15.2741C52.1289 15.2741 50.8326 22.2357 49.8613 25.3122C49.8613 25.3122 46.1386 25.9585 44.3548 24.9872C44.3548 24.9872 42.8961 16.2454 42.0872 14.9491C42.0945 14.9491 41.2856 11.5477 41.4445 9.93007Z"
        fill="#E5796E"
      />
      <path
        d="M45.7193 5.5L45.1055 9.64522C45.1055 9.64522 46.817 10.4324 48.0158 9.61272L47.7594 6.68796L45.7193 5.5Z"
        fill="url(#paint8_linear_661_7275)"
      />
      <path
        d="M48.3581 2.80629C48.3761 2.8424 48.748 4.93306 48.9755 6.22573C49.0694 6.76014 48.6758 7.25843 48.1342 7.28731L47.6395 7.3162C46.7296 7.37036 45.9244 6.72041 45.7872 5.81771L45.4297 3.47429C45.4261 3.47429 47.8887 1.95414 48.3581 2.80629Z"
        fill="url(#paint9_linear_661_7275)"
      />
      <path
        d="M44.8917 3.47959C44.7004 3.24127 44.5343 2.86213 44.9423 2.45772C45.3539 2.0497 45.8161 2.1508 46.1122 2.28802C46.2422 2.3494 46.4047 2.32051 46.4986 2.21219C46.5383 2.16164 46.5997 2.12914 46.6935 2.13997C46.8669 2.15802 47.0185 2.04609 47.0402 1.87277C47.0763 1.58029 47.2316 1.23366 47.7515 1.13255C48.7048 0.948401 49.1814 1.90166 49.1633 2.57327C49.1453 3.24488 48.1018 3.3857 47.5096 3.27377C47.0727 3.19072 46.6972 3.57347 46.5274 3.78289C46.4661 3.85872 46.4444 3.95982 46.4661 4.0537L46.6177 4.70004C46.513 4.90225 46.4119 5.10806 46.2639 5.27777C46.1591 5.40054 46.0436 5.46914 45.9208 5.49803C45.7439 5.16584 45.2456 5.11167 45.0145 5.10445C44.9279 5.10084 44.8448 5.06112 44.787 4.99613C44.6137 4.79753 44.3393 4.34979 44.8629 3.91288C44.982 3.80817 44.9965 3.61319 44.8917 3.47959Z"
        fill="#242424"
      />
      <path
        d="M46.0841 6.19257C46.0841 6.19257 46.0625 5.02267 45.5895 4.91434C45.1164 4.80602 44.6037 5.14543 45.0117 5.84593C45.5136 6.71975 46.0841 6.19257 46.0841 6.19257Z"
        fill="url(#paint10_linear_661_7275)"
      />
      <path
        d="M44.2141 15.5192C44.2141 15.5192 30.3125 11.7351 37.2163 2.00391L37.6821 2.66469C37.6821 2.66469 35.6023 9.25443 42.4917 9.88271C42.4917 9.88271 44.2791 14.4829 44.2141 15.5192Z"
        fill="#E5796E"
      />
      <path
        d="M37.1697 2.05363C37.1697 2.05363 37.0145 0.176008 37.9533 0.0171317C38.8921 -0.138133 39.2063 0.80068 39.3615 1.27009C39.5168 1.73949 37.9533 2.6783 37.6392 2.6783C37.325 2.6783 37.1697 2.05363 37.1697 2.05363Z"
        fill="url(#paint11_linear_661_7275)"
      />
      <path
        d="M51.707 16.5579C51.707 16.5579 53.5052 23.6712 62.5178 15.8358L61.8282 15.4133C61.8282 15.4133 53.8916 21.0426 52.4942 11.4414C52.4942 11.4378 51.707 15.695 51.707 16.5579Z"
        fill="#E5796E"
      />
      <path
        d="M62.47 15.8824C62.47 15.8824 64.3548 15.9149 64.4487 14.9653C64.5426 14.0192 63.5857 13.7665 63.1055 13.6437C62.6252 13.5173 61.7947 15.1422 61.8128 15.4563C61.8345 15.7705 62.47 15.8824 62.47 15.8824Z"
        fill="url(#paint12_linear_661_7275)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_661_7275"
          x1="27.225"
          y1="91.627"
          x2="60.513"
          y2="71.1276"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_661_7275"
          x1="81.0174"
          y1="52.6379"
          x2="137.703"
          y2="14.7638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_661_7275"
          x1="14.3278"
          y1="82.2768"
          x2="37.1151"
          y2="82.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_661_7275"
          x1="37.4443"
          y1="64.0115"
          x2="1.03233"
          y2="100.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_661_7275"
          x1="24.1693"
          y1="121.627"
          x2="98.2654"
          y2="71.5557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_661_7275"
          x1="84.8164"
          y1="52.6458"
          x2="63.1136"
          y2="120.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_661_7275"
          x1="44.6445"
          y1="43.6682"
          x2="43.9952"
          y2="49.3343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_661_7275"
          x1="51.4832"
          y1="44.3902"
          x2="50.8758"
          y2="49.6906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_661_7275"
          x1="45.1066"
          y1="7.74355"
          x2="48.018"
          y2="7.74355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_661_7275"
          x1="289.254"
          y1="-217.043"
          x2="293.121"
          y2="-220.592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_661_7275"
          x1="288.743"
          y1="-217.609"
          x2="292.61"
          y2="-221.159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_661_7275"
          x1="38.9282"
          y1="-0.902901"
          x2="37.2841"
          y2="4.49914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_661_7275"
          x1="65.3013"
          y1="13.932"
          x2="60.021"
          y2="15.9327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BuildOnTop;
