const config = {
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  accountApiUrl: process.env.REACT_APP_ACCOUNT_API_URL,
  workbenchApiUrl: process.env.REACT_APP_WORKBENCH_API_URL,
  amplitudeAnalyticsId: process.env.REACT_APP_AMPLITUDE_ANALYTICS_ID,
  intercomId: process.env.REACT_APP_INTERCOM_ID,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};

export default config;
