import clsx from 'clsx';
import PropTypes from 'prop-types';

const Info = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 18"
    className={clsx('w-4 h-4', className)}
    {...props}
  >
    <path fill={color} d="M7.84 4.046v1.568h1.288V4.046H7.84zM7.924 14h1.12V6.944h-1.12V14z" />
    <circle cx="8.5" cy="9.5" r="8" stroke={color} />
  </svg>
);

Info.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Info.defaultProps = {
  color: '#BDBDBD',
  className: '',
};

export default Info;
