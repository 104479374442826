import PropTypes from 'prop-types';
import clsx from 'clsx';

const Cross = ({ color, className, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('w-6 h-6', className)}
    {...props}
  >
    <path
      d="M18 6 6 18M6 6l12 12"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Cross.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Cross.defaultProps = {
  color: '#000',
  className: '',
};

export default Cross;
