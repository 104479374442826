import clsx from 'clsx';
import PropTypes from 'prop-types';

const Edit = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 13"
    className={clsx('w-[0.813rem] h-[0.813rem]', className)}
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.21 1.625a1.532 1.532 0 012.168 2.166l-7.313 7.313-2.98.812.813-2.979 7.313-7.312z"
    />
  </svg>
);

Edit.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Edit.defaultProps = {
  color: '#E5796E',
  className: '',
};

export default Edit;
