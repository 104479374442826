import { useState } from 'react';
import clsx from 'clsx';
import { motion, useIsPresent } from 'framer-motion';

import { usePricing } from '@contexts/Pricing';

const CostPopup = ({ id, features, onClick }) => {
  const isPresent = useIsPresent();
  const [isOpened, setIsOpened] = useState();
  const { calculateFeatureFixedCost, calculateFeatureCustomizationCost } = usePricing();

  return (
    // <motion.div
    //   key={`${id}-1`}
    //   animate={{ opacity: 1 }}
    //   initial={{ opacity: 0 }}
    //   exit={{ opacity: 0 }}
    //   transition={{ duration: 0.4 }}
    //   className="absolute h-full w-full bg-black bg-opacity-40 flex items-end"
    // >
    <motion.div
      key={`${id}-2`}
      onClick={onClick}
      animate={{
        height: 'auto',
      }}
      initial={{ height: 0 }}
      exit={{ height: 0 }}
      transition={{ duration: 0.4 }}
      onAnimationComplete={() => setIsOpened(true)}
      className={clsx(
        'absolute left-0 bottom-0 bg-white-default border-b-2 border-l border-gray-5 max-h-full cursor-pointer',
        isPresent && isOpened ? 'overflow-y-auto' : 'overflow-y-hidden'
      )}
    >
      <table className="mb-3 mt-6">
        <thead>
          <tr>
            <th className="text-left pl-8 pr-6 pb-2 font-bold text-base leading-[1.188rem] text-black-brand-1">
              Feature
            </th>
            <th className="text-left px-6 mb-5 pb-2 font-bold text-base leading-[1.188rem] text-black-brand-1">
              Fixed cost
            </th>
            <th className="text-left pr-8 pl-6 pb-2 font-bold text-base leading-[1.188rem] text-black-brand-1">
              Customization cost
            </th>
          </tr>
        </thead>
        <tbody>
          {features.map((feature, index) => (
            <tr
              key={`${feature.id}-${feature.featureCategoryId}`}
              className={clsx(
                'border-b',
                index !== features.length - 1 ? 'border-gray-5' : 'border-white-default'
              )}
            >
              <td className="text-left pl-8 pr-6 py-3 text-sm leading-[1.063rem] font-medium text-black-brand-1">
                {feature.name}
              </td>
              <td className="text-left px-6 py-3 text-sm leading-[1.063rem] font-medium text-black-brand-1">
                {calculateFeatureFixedCost(feature).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </td>
              <td className="text-left pr-8 pl-6 py-3 text-sm leading-[1.063rem] font-medium text-black-brand-1">
                {calculateFeatureCustomizationCost(feature).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
    // </motion.div>
  );
};

export default CostPopup;
