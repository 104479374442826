const ChooseBase = () => {
  return (
    <svg
      width={150}
      height={102}
      viewBox="0 0 150 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M58.623 73.3477L55.6152 94.1005H90.5136L85.967 73.3477H58.623Z" fill="#BDBDBD" />
      <path
        d="M0 6.88229V68.6674C0 73.752 4.05157 77.9058 9.13083 78.035C31.6969 78.6161 110.625 80.0258 132.104 79.7137C135.795 79.6599 138.786 76.7059 138.894 73.0149C139.308 58.6595 139.981 24.1485 137.49 8.1252C136.968 4.75159 134.116 2.2281 130.705 2.12049C111.797 1.51786 28.1296 -0.402999 6.66114 0.0758719C2.95931 0.1512 0 3.18046 0 6.88229Z"
        fill="#828282"
      />
      <path
        d="M3.87891 6.57806V65.2747C3.87891 68.2448 6.2625 70.6607 9.22719 70.7037L129.015 72.1834C131.958 72.2264 134.368 69.8589 134.379 66.9212L134.578 8.74643C134.584 6.64262 132.91 4.92084 130.807 4.86704L8.16722 2.39198C5.81591 2.33279 3.87891 4.22675 3.87891 6.57806Z"
        fill="#E0E0E0"
      />
      <path
        d="M135.279 74.5978L3.87458 72.3595C3.78311 72.3595 3.70778 72.2841 3.71316 72.1927C3.71316 72.1012 3.79387 72.0312 3.87996 72.0312L135.284 74.2696C135.376 74.2696 135.451 74.3449 135.446 74.4364C135.446 74.5225 135.37 74.5978 135.279 74.5978Z"
        fill="#BDBDBD"
      />
      <path
        d="M70.8733 77.3325C70.0286 77.3325 69.3398 76.6438 69.3398 75.7991C69.3398 74.9543 70.0286 74.2656 70.8733 74.2656C71.7181 74.2656 72.4068 74.9543 72.4068 75.7991C72.4068 76.6438 71.7181 77.3325 70.8733 77.3325ZM70.8733 74.5992C70.2115 74.5992 69.6734 75.1373 69.6734 75.7991C69.6734 76.4609 70.2115 76.999 70.8733 76.999C71.5351 76.999 72.0732 76.4609 72.0732 75.7991C72.0732 75.1373 71.5351 74.5992 70.8733 74.5992Z"
        fill="#BDBDBD"
      />
      <path
        d="M98.0298 99.625H46.9574C45.5692 99.625 44.5254 98.635 44.7944 97.575L45.4508 94.9816C45.6499 94.2014 46.5054 93.631 47.5224 93.5988L96.76 92.0599C97.8253 92.0276 98.7669 92.5926 98.9983 93.3997L100.182 97.5266C100.483 98.6081 99.4395 99.625 98.0298 99.625Z"
        fill="#BDBDBD"
      />
      <path
        d="M74.4461 94.298C74.3546 94.298 74.2793 94.2227 74.2793 94.1366C74.2793 94.0451 74.3492 93.9698 74.4407 93.9698L76.5499 93.9375C76.636 93.9375 76.7167 94.0074 76.7167 94.0989C76.7167 94.1904 76.6467 94.2657 76.5553 94.2657L74.4461 94.298C74.4515 94.298 74.4461 94.298 74.4461 94.298Z"
        fill="#E0E0E0"
      />
      <path
        d="M46.6837 98.1949C46.6729 98.1949 46.6675 98.1949 46.6568 98.1949C46.5653 98.1787 46.5061 98.0926 46.5169 98.0065L46.8182 96.1233C46.9796 95.1118 47.8405 94.3639 48.8682 94.3478L71.246 94.0195C71.3375 94.0195 71.4128 94.0949 71.4128 94.1809C71.4128 94.2724 71.3428 94.3478 71.2514 94.3478L48.8736 94.676C48.0073 94.6867 47.2863 95.3162 47.1464 96.1718L46.8451 98.055C46.8343 98.1357 46.7644 98.1949 46.6837 98.1949Z"
        fill="#E0E0E0"
      />
      <path
        d="M48.6478 32.7891H13.8839C13.2544 32.7891 12.6518 33.1011 12.2213 33.6661C11.7909 34.2311 11.5756 34.9897 11.6187 35.7645L12.2375 45.9715C12.3289 47.4619 13.362 48.6026 14.5834 48.5488L48.7231 47.0368C49.9445 46.983 50.9076 45.7509 50.9076 44.2551V35.5547C50.9022 34.0105 49.9014 32.7891 48.6478 32.7891Z"
        fill="#BDBDBD"
      />
      <path
        d="M18.0815 45.247L16.688 44.6444C16.5803 44.5959 16.4566 44.6067 16.3544 44.6713L15.0684 45.4783C14.8155 45.6344 14.4927 45.4353 14.5196 45.1394L14.6595 43.6274C14.6702 43.509 14.6218 43.3961 14.5303 43.3207L13.3628 42.3469C13.1368 42.1585 13.2229 41.7873 13.5134 41.7227L14.9985 41.3891C15.1114 41.3622 15.2083 41.2815 15.2513 41.1739L15.8163 39.7642C15.9293 39.4898 16.3059 39.4575 16.4566 39.7157L17.2314 41.0232C17.2906 41.1255 17.3982 41.19 17.5166 41.2008L19.0339 41.303C19.3298 41.3245 19.4751 41.6743 19.2814 41.9003L18.2752 43.0409C18.1945 43.127 18.1676 43.2508 18.1945 43.3638L18.5658 44.838C18.6465 45.1178 18.3559 45.3653 18.0815 45.247Z"
        fill="#E5796E"
      />
      <path
        d="M25.3706 45.247L23.977 44.6444C23.8694 44.5959 23.7457 44.6067 23.6434 44.6713L22.3575 45.4783C22.1046 45.6344 21.7817 45.4353 21.8086 45.1394L21.9485 43.6274C21.9593 43.509 21.9109 43.3961 21.8194 43.3207L20.6518 42.3469C20.4258 42.1585 20.5119 41.7873 20.8025 41.7227L22.2875 41.3891C22.4005 41.3622 22.4974 41.2815 22.5404 41.1739L23.1054 39.7642C23.2184 39.4898 23.595 39.4575 23.7457 39.7157L24.5205 41.0232C24.5796 41.1255 24.6873 41.19 24.8056 41.2008L26.3229 41.303C26.6189 41.3245 26.7642 41.6743 26.5705 41.9003L25.5643 43.0409C25.4836 43.127 25.4567 43.2508 25.4836 43.3638L25.8548 44.838C25.9355 45.1178 25.645 45.3653 25.3706 45.247Z"
        fill="#E5796E"
      />
      <path
        d="M33.1289 45.2484L31.7353 44.6458C31.6277 44.5974 31.5039 44.6082 31.4017 44.6727L30.1158 45.4798C29.8629 45.6358 29.54 45.4368 29.5669 45.1408L29.7068 43.6289C29.7176 43.5105 29.6692 43.3975 29.5777 43.3222L28.4155 42.3429C28.1895 42.1546 28.2756 41.7834 28.5662 41.7188L30.0512 41.3852C30.1642 41.3583 30.261 41.2776 30.3041 41.17L30.869 39.7603C30.982 39.4859 31.3587 39.4536 31.5093 39.7118L32.2841 41.0193C32.3433 41.1215 32.4509 41.1861 32.5693 41.1969L34.0866 41.2991C34.3826 41.3206 34.5278 41.6704 34.3341 41.8963L33.328 43.037C33.2473 43.1231 33.2203 43.2469 33.2473 43.3599L33.6185 44.8341C33.6938 45.1193 33.4033 45.3668 33.1289 45.2484Z"
        fill="#E5796E"
      />
      <path
        d="M40.6421 45.247L39.2485 44.6444C39.1409 44.5959 39.0171 44.6067 38.9149 44.6713L37.6289 45.4783C37.3761 45.6344 37.0532 45.4353 37.0801 45.1394L37.22 43.6274C37.2308 43.509 37.1824 43.3961 37.0909 43.3207L35.9233 42.3469C35.6973 42.1585 35.7834 41.7873 36.074 41.7227L37.559 41.3891C37.672 41.3622 37.7688 41.2815 37.8119 41.1739L38.3768 39.7642C38.4898 39.4898 38.8665 39.4575 39.0171 39.7157L39.7919 41.0232C39.8511 41.1255 39.9587 41.19 40.0771 41.2008L41.5944 41.303C41.8904 41.3245 42.0356 41.6743 41.8419 41.9003L40.8358 43.0409C40.7551 43.127 40.7282 43.2508 40.7551 43.3638L41.1263 44.838C41.2016 45.1178 40.9111 45.3653 40.6421 45.247Z"
        fill="#E5796E"
      />
      <path
        d="M48.3413 45.247L46.9477 44.6444C46.8401 44.5959 46.7164 44.6067 46.6141 44.6713L45.3282 45.4783C45.0753 45.6344 44.7524 45.4353 44.7794 45.1394L44.9192 43.6274C44.93 43.509 44.8816 43.3961 44.7901 43.3207L43.6225 42.3469C43.3965 42.1585 43.4826 41.7873 43.7732 41.7227L45.2582 41.3891C45.3712 41.3622 45.4681 41.2815 45.5111 41.1739L46.0761 39.7642C46.1891 39.4898 46.5657 39.4575 46.7164 39.7157L47.4912 41.0232C47.5503 41.1255 47.658 41.19 47.7763 41.2008L49.2937 41.303C49.5896 41.3245 49.7349 41.6743 49.5412 41.9003L48.535 43.0409C48.4543 43.127 48.4274 43.2508 48.4543 43.3638L48.8255 44.838C48.9063 45.1178 48.6157 45.3653 48.3413 45.247Z"
        fill="#E5796E"
      />
      <path
        opacity="0.7"
        d="M48.0022 36.3928C48.0022 37.0439 47.5287 37.5012 46.9423 37.5012H18.4037C17.8172 37.5012 17.3438 37.0439 17.3438 36.3928V36.3336C17.3438 35.6826 17.8172 35.1445 18.4037 35.1445H46.9369C47.5234 35.1445 47.9969 35.6826 47.9969 36.3336V36.3928H48.0022Z"
        fill="white"
      />
      <g opacity="0.7">
        <path
          d="M127.052 18.9228H74.7257C74.3706 18.9228 74.0801 18.6323 74.0801 18.2771V7.94645C74.0801 7.59133 74.3706 7.30078 74.7257 7.30078H127.052C127.407 7.30078 127.697 7.59133 127.697 7.94645V18.2771C127.697 18.6323 127.407 18.9228 127.052 18.9228Z"
          fill="white"
        />
        <path d="M115.752 18.5179L118.254 22.4995L120.562 18.3242L115.752 18.5179Z" fill="white" />
      </g>
      <path
        d="M84.6539 16.662C86.4735 14.8423 86.4735 11.8921 84.6539 10.0724C82.8342 8.25274 79.884 8.25274 78.0643 10.0724C76.2447 11.8921 76.2447 14.8423 78.0643 16.662C79.884 18.4816 82.8342 18.4816 84.6539 16.662Z"
        fill="white"
      />
      <path
        d="M82.78 11.5986C82.78 12.3949 82.1397 13.2396 81.3487 13.2181C80.5578 13.1966 79.9121 12.3088 79.9121 11.5071C79.9121 10.7054 80.5578 10.1566 81.3487 10.1889C82.1397 10.2104 82.78 10.7969 82.78 11.5986Z"
        fill="#E5796E"
      />
      <path
        d="M79.5639 16.2533L83.0612 16.3394C83.4271 16.3502 83.7499 16.1188 83.8629 15.7637L84.0512 15.1772C84.1858 14.7468 83.9652 14.2733 83.5455 14.1011C82.1304 13.5362 80.6615 13.5577 79.1442 14.0258C78.7191 14.1549 78.4824 14.6123 78.6007 15.0481L78.746 15.5969C78.8429 15.9789 79.1818 16.248 79.5639 16.2533Z"
        fill="#E5796E"
      />
      <g opacity="0.7">
        <path
          d="M127.962 51.1259H75.6359C75.2808 51.1259 74.9902 50.8354 74.9902 50.4803V40.1496C74.9902 39.7945 75.2808 39.5039 75.6359 39.5039H127.962C128.317 39.5039 128.608 39.7945 128.608 40.1496V50.4803C128.608 50.8354 128.317 51.1259 127.962 51.1259Z"
          fill="white"
        />
        <path d="M116.662 50.7264L119.164 54.7027L121.467 50.5273L116.662 50.7264Z" fill="white" />
      </g>
      <path
        d="M85.5601 48.8651C87.3798 47.0454 87.3798 44.0952 85.5601 42.2755C83.7405 40.4559 80.7902 40.4559 78.9706 42.2755C77.1509 44.0952 77.1509 47.0454 78.9706 48.8651C80.7902 50.6848 83.7405 50.6848 85.5601 48.8651Z"
        fill="white"
      />
      <path
        d="M83.6901 43.8017C83.6901 44.598 83.0498 45.4428 82.2589 45.4212C81.4679 45.3997 80.8223 44.5119 80.8223 43.7102C80.8223 42.9085 81.4679 42.3597 82.2589 42.392C83.0552 42.4135 83.6901 43 83.6901 43.8017Z"
        fill="#E5796E"
      />
      <path
        d="M80.476 48.4604L83.9733 48.5465C84.3392 48.5572 84.662 48.3259 84.775 47.9707L84.9634 47.3843C85.0979 46.9538 84.8773 46.4803 84.4576 46.3081C83.0425 45.7432 81.5736 45.7647 80.0563 46.2328C79.6312 46.3619 79.3945 46.8193 79.5128 47.2551L79.6581 47.8039C79.755 48.1806 80.0886 48.4496 80.476 48.4604Z"
        fill="#E5796E"
      />
      <g opacity="0.7">
        <path
          d="M75.6359 34.9423H127.962C128.317 34.9423 128.608 34.6518 128.608 34.2967V23.966C128.608 23.6109 128.317 23.3203 127.962 23.3203H75.6359C75.2808 23.3203 74.9902 23.6109 74.9902 23.966V34.2967C74.9902 34.6518 75.2808 34.9423 75.6359 34.9423Z"
          fill="white"
        />
        <path d="M86.9352 34.5374L84.4279 38.5137L82.125 34.3438L86.9352 34.5374Z" fill="white" />
      </g>
      <g opacity="0.7">
        <path
          d="M76.2375 66.8525H128.564C128.919 66.8525 129.209 66.5619 129.209 66.2068V55.8761C129.209 55.521 128.919 55.2305 128.564 55.2305H76.2375C75.8823 55.2305 75.5918 55.521 75.5918 55.8761V66.2068C75.5918 66.5619 75.8823 66.8525 76.2375 66.8525Z"
          fill="white"
        />
        <path d="M87.5368 66.4476L85.0348 70.4238L82.7266 66.2539L87.5368 66.4476Z" fill="white" />
      </g>
      <path
        d="M125.224 64.5917C127.044 62.772 127.044 59.8217 125.224 58.0021C123.405 56.1824 120.454 56.1824 118.635 58.0021C116.815 59.8217 116.815 62.772 118.635 64.5917C120.454 66.4113 123.405 66.4113 125.224 64.5917Z"
        fill="white"
      />
      <path
        d="M123.892 100.367C125.399 100.496 128.019 100.712 130.08 100.798C131.323 100.851 132.033 100.733 132.447 100.566C132.447 100.615 132.447 100.658 132.442 100.695C132.431 100.911 132.253 101.088 132.038 101.099C128.137 101.314 125.038 101.174 123.935 101.11C123.709 101.094 123.569 100.846 123.688 100.652C123.747 100.556 123.817 100.459 123.892 100.367Z"
        fill="#4F4F4F"
      />
      <path
        d="M130.078 100.796C128.012 100.705 125.397 100.495 123.891 100.366C125.376 98.5471 130.078 97.3203 130.078 97.3203H131.407C132.322 98.6386 132.456 99.8922 132.446 100.565C132.037 100.732 131.321 100.85 130.078 100.796Z"
        fill="#242424"
      />
      <path
        d="M131.637 95.1445L131.513 97.6034C131.497 97.8725 131.298 98.0931 131.034 98.1307C130.77 98.1684 130.415 98.1738 130.033 98.05C129.802 97.9747 129.63 97.7756 129.587 97.5389L129.156 95.1445H131.637Z"
        fill="url(#paint0_linear_661_6410)"
      />
      <path
        d="M148.899 100.777C149.555 100.685 149.851 100.346 149.975 99.9805C149.975 99.9858 149.975 99.9858 149.975 99.9912C150.072 100.373 149.846 100.771 149.405 100.96C148.867 101.186 148.275 101.229 147.818 101.207C147.328 101.186 146.876 100.981 146.628 100.658L146.623 100.653C147.113 100.901 147.807 100.927 148.899 100.777Z"
        fill="#4F4F4F"
      />
      <path
        d="M145.816 95.133H146.914C148.582 95.1007 149.631 98.6196 149.975 99.9863C149.851 100.352 149.555 100.691 148.899 100.783C147.807 100.933 147.118 100.906 146.623 100.664C145.719 99.4752 145.273 97.7372 145.057 96.7257C144.815 95.5635 145.816 95.133 145.816 95.133Z"
        fill="#242424"
      />
      <path
        d="M146.769 92.8398L147.377 95.2611C147.452 95.557 147.307 95.8637 147.032 96.0036C146.693 96.1758 146.215 96.3641 145.773 96.3103C145.564 96.2834 145.391 96.1381 145.327 95.9444L144.434 93.3994L146.769 92.8398Z"
        fill="url(#paint1_linear_661_6410)"
      />
      <path
        d="M147.495 93.5421C146.737 94.2146 144.272 94.3007 144.272 94.3007C135.4 73.0044 131.768 69.9806 131.768 69.9806C133.048 78.0191 131.972 95.8127 131.972 95.8127C130.509 96.4583 128.62 95.8127 128.62 95.8127C128.502 82.6518 126.479 71.175 124.09 62.1303C130.089 61.9958 133.274 59.9458 134.318 59.1172C139.322 67.1827 147.495 93.5421 147.495 93.5421Z"
        fill="#242424"
      />
      <path
        d="M132.804 34.6295C133.94 40.2899 133.531 50.4269 133.267 55.0488C133.192 56.3455 134.101 58.148 134.833 59.3802C134.946 59.5739 134.876 59.8214 134.677 59.9236C133.45 60.5531 129.291 62.5116 124.475 62.7538C124.152 62.7699 123.867 62.5493 123.781 62.2426C120.208 48.8934 116.055 40.3652 116.055 40.3652C120.639 33.0423 132.804 34.6295 132.804 34.6295Z"
        fill="#E5796E"
      />
      <path
        d="M131.169 69.4744C131.217 69.4744 131.271 69.4583 131.314 69.4314C131.448 69.3507 131.491 69.1731 131.411 69.0386C131.362 68.9579 130.216 67.107 127.892 65.9825C127.752 65.9125 127.58 65.9717 127.51 66.117C127.44 66.2569 127.499 66.429 127.644 66.499C129.807 67.5428 130.916 69.3184 130.926 69.3346C130.98 69.426 131.077 69.4744 131.169 69.4744Z"
        fill="#4F4F4F"
      />
      <path
        d="M139.92 50.8773C139.554 53.5137 138.478 56.3923 136.326 59.4485L135.508 58.7813C135.508 58.7813 136.455 54.945 135.917 50.5921C137.24 50.3284 138.811 50.5975 139.92 50.8773Z"
        fill="#E5796E"
      />
      <path
        d="M131.531 41.6129L132.801 34.6289C132.801 34.6289 141.27 41.1017 139.92 50.8782C138.811 50.5984 137.24 50.3294 135.911 50.5877C135.524 47.4562 134.367 44.0503 131.531 41.6129Z"
        fill="#E5796E"
      />
      <path
        d="M116.054 40.3594L119.326 47.4025C118.798 51.6532 116.512 53.4933 114.053 54.1982C113.897 52.2612 113.407 50.6954 112.955 49.614C116.097 46.3587 116.054 40.3594 116.054 40.3594Z"
        fill="#E5796E"
      />
      <path
        d="M112.948 49.6133C113.4 50.6948 113.895 52.2605 114.046 54.1975C107.476 56.7479 105.738 51.8032 105.738 51.8032L106.954 51.5879C109.693 51.8785 111.608 50.9961 112.948 49.6133Z"
        fill="#E5796E"
      />
      <path
        d="M119.066 31.6658L120.643 37.4661C120.82 38.1171 121.525 38.4669 122.155 38.2194C122.833 37.9503 123.688 37.5791 124.431 37.1809C125.222 36.7559 125.469 35.7389 124.969 34.9964L121.687 30.1055L119.066 31.6658Z"
        fill="url(#paint2_linear_661_6410)"
      />
      <path
        d="M102.725 47.9258L143.177 62.647C144.871 63.2658 145.684 65.1974 144.936 66.8385L144.812 67.1075C144.15 68.5603 142.434 69.2006 140.981 68.528L101.703 50.3578L102.725 47.9258Z"
        fill="#4F4F4F"
      />
      <path
        opacity="0.39"
        d="M84.1353 58.6745C95.1629 58.6745 104.103 49.8963 104.103 39.0677C104.103 28.2392 95.1629 19.4609 84.1353 19.4609C73.1076 19.4609 64.168 28.2392 64.168 39.0677C64.168 49.8963 73.1076 58.6745 84.1353 58.6745Z"
        fill="white"
      />
      <path
        d="M61.918 39.0682C61.918 51.1153 71.8666 60.8864 84.1343 60.8864C96.4074 60.8864 106.351 51.1207 106.351 39.0682C106.351 27.0211 96.402 17.25 84.1343 17.25C71.8613 17.25 61.918 27.0157 61.918 39.0682ZM65.1786 39.0682C65.1786 28.7913 73.6638 20.4568 84.1343 20.4568C94.6049 20.4568 103.09 28.7913 103.09 39.0682C103.09 49.3505 94.6049 57.6796 84.1343 57.6796C73.6638 57.6796 65.1786 49.3451 65.1786 39.0682Z"
        fill="#828282"
      />
      <path
        d="M104.647 41.7688C104.566 41.7581 104.507 41.6827 104.518 41.602C104.582 41.0909 104.631 40.569 104.652 40.0632C104.658 39.9825 104.711 39.9179 104.808 39.9233C104.889 39.9287 104.954 39.9986 104.948 40.0793C104.921 40.5959 104.878 41.1231 104.808 41.6451C104.798 41.7204 104.733 41.7742 104.658 41.7742C104.658 41.7688 104.652 41.7688 104.647 41.7688ZM104.367 35.5489C104.12 34.1661 103.721 32.8048 103.189 31.5081C100.03 23.8515 92.5568 18.9014 84.1416 18.9014C80.2191 18.9014 76.4043 19.9937 73.1114 22.0544C73.0414 22.0975 72.95 22.0759 72.9069 22.006C72.8639 21.936 72.8854 21.8446 72.9553 21.8015C76.2967 19.7085 80.1653 18.6055 84.1469 18.6055C92.6859 18.6055 100.267 23.6255 103.474 31.3951C104.017 32.7133 104.421 34.0961 104.674 35.4951C104.69 35.5758 104.636 35.6511 104.555 35.6673C104.545 35.6673 104.539 35.6673 104.529 35.6673C104.442 35.6673 104.378 35.6188 104.367 35.5489Z"
        fill="#BDBDBD"
      />
      <path
        d="M115.611 27.2633C115.595 27.3655 116.93 33.8868 116.93 33.8868L120.637 33.0474L120.293 26.6768C120.293 26.6768 115.854 25.5953 115.611 27.2633Z"
        fill="url(#paint3_linear_661_6410)"
      />
      <path
        d="M119.46 28.0947L119.901 31.436C119.906 31.4791 119.906 31.5221 119.901 31.5652C119.863 31.8288 119.67 32.7166 118.771 32.4637C117.953 32.2324 117.205 32.0333 116.689 32.1893C116.457 32.2592 116.318 32.496 116.366 32.7327C116.49 33.3246 116.786 34.6536 117.221 35.7728C117.308 35.9988 117.56 36.1171 117.792 36.0526C118.497 35.8481 119.966 35.3746 121.058 34.7128C121.209 34.6213 121.295 34.4599 121.289 34.2877C121.252 33.443 121.155 30.8818 121.235 30.1662C121.311 29.4936 122.139 29.0901 122.5 28.9448C122.618 28.8964 122.71 28.8049 122.758 28.6866C122.925 28.2669 123.156 27.3037 121.94 26.911C121.687 26.8303 121.553 26.5559 121.644 26.303C121.822 25.8026 121.903 25.0385 120.961 24.6135C120.003 24.183 119.288 24.6942 118.906 25.1085C118.75 25.2753 118.502 25.3022 118.308 25.1784C118.201 25.1085 118.055 25.087 117.883 25.1946C117.652 25.3398 117.345 25.243 117.227 24.9955C116.996 24.5274 116.533 24.0216 115.597 24.183C113.934 24.4682 113.746 26.3083 114.187 27.3898C114.623 28.4713 116.409 28.057 117.302 27.5136C117.98 27.0993 118.868 27.5351 119.25 27.7665C119.358 27.8257 119.438 27.9494 119.46 28.0947Z"
        fill="#242424"
      />
      <path
        d="M121.387 31.3787C121.387 31.3787 120.704 29.4632 121.403 28.9951C122.102 28.527 123.146 28.7691 122.915 30.1573C122.63 31.8845 121.387 31.3787 121.387 31.3787Z"
        fill="url(#paint4_linear_661_6410)"
      />
      <path
        d="M135.842 58.6715L132.017 58.3594L130.359 59.8875C130.359 59.8875 130.752 60.4309 131.974 60.1188C133.195 59.8067 134.874 59.7314 134.874 59.7314C134.874 59.7314 136.563 60.4955 136.585 59.0696L135.842 58.6715Z"
        fill="url(#paint5_linear_661_6410)"
      />
      <path
        d="M106.221 52.7129C105.893 52.9711 104.758 52.4331 104.064 52.0564C103.725 51.8735 103.563 51.4807 103.676 51.1148C103.843 50.5875 104.123 49.9096 104.462 49.8235C105.038 49.6836 107.588 51.6475 106.221 52.7129Z"
        fill="url(#paint6_linear_661_6410)"
      />
      <path
        d="M142.38 67.9622C142.396 67.9622 142.412 67.9622 142.428 67.9515C144.01 67.4027 144.376 66.4718 144.408 65.7831C144.451 64.7769 143.822 63.8461 142.837 63.4641L137.979 61.5701C137.903 61.5378 137.817 61.5755 137.785 61.6562C137.753 61.7315 137.79 61.8176 137.871 61.8499L142.73 63.7439C143.591 64.0829 144.145 64.8953 144.107 65.7724C144.069 66.6386 143.472 67.2735 142.332 67.6717C142.256 67.6986 142.213 67.7847 142.24 67.86C142.256 67.9246 142.315 67.9622 142.38 67.9622Z"
        fill="#828282"
      />
      <path
        d="M136.427 61.2833C136.487 61.2833 136.546 61.2456 136.567 61.1864C136.6 61.1111 136.562 61.025 136.481 60.9927L135.852 60.7452C135.776 60.7129 135.69 60.756 135.658 60.8313C135.626 60.9066 135.663 60.9927 135.744 61.025L136.374 61.2725C136.39 61.2833 136.406 61.2833 136.427 61.2833Z"
        fill="#828282"
      />
      <path
        opacity="0.56"
        d="M53.1698 57.6766H8.58644C8.27437 57.6766 8.02148 57.4237 8.02148 57.1117V54.4321C8.02148 54.1201 8.27437 53.8672 8.58644 53.8672H53.1698C53.4819 53.8672 53.7348 54.1201 53.7348 54.4321V57.1117C53.7348 57.4237 53.4819 57.6766 53.1698 57.6766Z"
        fill="white"
      />
      <path
        opacity="0.56"
        d="M52.8925 63.4813H8.3091C7.99703 63.4813 7.74414 63.2284 7.74414 62.9164V60.2368C7.74414 59.9248 7.99703 59.6719 8.3091 59.6719H52.8925C53.2046 59.6719 53.4575 59.9248 53.4575 60.2368V62.9164C53.4575 63.2284 53.2046 63.4813 52.8925 63.4813Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_661_6410"
          x1="131.637"
          y1="96.6539"
          x2="129.157"
          y2="96.6539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_661_6410"
          x1="147.398"
          y1="94.5795"
          x2="144.435"
          y2="94.5795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_661_6410"
          x1="122.123"
          y1="30.0666"
          x2="122.213"
          y2="45.5816"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_661_6410"
          x1="117.087"
          y1="27.5195"
          x2="123.203"
          y2="38.6095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_661_6410"
          x1="127.017"
          y1="37.0348"
          x2="121.852"
          y2="29.9401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_661_6410"
          x1="129.656"
          y1="56.0961"
          x2="140.206"
          y2="65.438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_661_6410"
          x1="106.62"
          y1="51.2971"
          x2="103.644"
          y2="51.2971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChooseBase;
