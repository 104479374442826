const MakeYourOwn = () => {
  return (
    <svg
      width={74}
      height={120}
      viewBox="0 0 74 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0569 111.244C31.0831 106.407 24.3096 102.45 15.9278 102.404C7.546 102.359 0.729956 106.243 0.703732 111.079C0.677509 115.915 7.45104 119.873 15.8328 119.918C24.2146 119.964 31.0307 116.08 31.0569 111.244Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.3114 18.1319L40.3662 0.275298C39.7741 -0.0674993 39.2755 -0.0674986 38.8392 0.150645L35.7852 1.92696L39.3689 4.73166L40.2415 4.51352V77.8721C40.2415 79.5861 41.3011 81.5806 42.5788 82.3285L69.7221 102.18V103.395L72.8696 101.556C73.337 101.245 73.6175 100.621 73.6175 99.7177V22.6195C73.6487 20.8743 72.5891 18.8798 71.3114 18.1319Z"
        fill="#999999"
      />
      <path
        d="M66.5746 24.7074V21.2483L63.5206 23.5232L50.2138 17.9449L40.2415 10.6839V4.51352L39.3689 4.73166L35.7852 1.92696L38.8392 0.150645C39.2443 -0.0674986 39.7741 -0.0674993 40.3662 0.275298L71.3114 18.1631C72.1528 18.6617 72.9008 19.6901 73.3059 20.812L66.5746 24.7074Z"
        fill="#BDBDBD"
      />
      <path
        d="M68.2259 103.271L37.2806 85.4146C36.0029 84.6666 34.9434 82.6722 34.9434 80.9582V3.82881C34.9434 2.11483 36.0029 1.30458 37.2806 2.0525L68.2259 19.9091C69.5036 20.657 70.5631 22.6515 70.5631 24.3655V101.464C70.5631 103.209 69.5347 104.019 68.2259 103.271Z"
        fill="#CCCCCC"
      />
      <path
        d="M35.6289 81.3967V4.17377C35.6289 2.92723 36.3768 2.33513 37.3429 2.89607L68.1946 20.7215C69.1296 21.2513 69.9086 22.716 69.9086 23.9625V101.154C69.9086 102.401 69.1607 102.993 68.1946 102.432L37.3429 84.6065C36.3768 84.1079 35.6289 82.6432 35.6289 81.3967Z"
        fill="#333333"
      />
      <path
        d="M68.9743 24.7713V99.5634C68.9743 100.685 68.1952 101.122 67.2291 100.561L38.2783 83.8571C37.3123 83.2961 36.5332 81.9561 36.5332 80.8342V6.04209C36.5332 4.92021 37.3123 4.48392 38.2783 5.04486L43.5761 8.09888C43.7943 8.22353 43.9813 8.504 44.0436 8.78447L44.2929 9.90635C44.4799 10.9659 45.3213 12.1813 46.2873 12.7422L59.189 20.1903C60.1862 20.7512 61.0276 20.5331 61.1834 19.6917L61.4328 18.8502C61.4951 18.6321 61.6821 18.6009 61.9002 18.7256L67.198 21.7796C68.1952 22.3094 68.9743 23.6494 68.9743 24.7713Z"
        fill="#E0E0E0"
      />
      <path
        d="M62.4927 43.3492C62.9914 43.6297 63.3965 44.3464 63.3965 44.9385V48.1172C63.3965 48.7093 62.9914 48.9274 62.4616 48.647L43.9194 37.8956C43.4207 37.6151 43.0156 36.8984 43.0156 36.3063V33.1276C43.0156 32.5355 43.4207 32.3173 43.9505 32.5978L62.4927 43.3492Z"
        fill="#EAA49D"
      />
      <path
        d="M62.4927 54.4078C62.9914 54.6883 63.3965 55.405 63.3965 55.9971V59.1758C63.3965 59.7679 62.9914 59.986 62.4616 59.7056L43.9194 48.9542C43.4207 48.6737 43.0156 47.957 43.0156 47.3649V44.1862C43.0156 43.5941 43.4207 43.3759 43.9505 43.6564L62.4927 54.4078Z"
        fill="#EAA49D"
      />
      <path d="M36.5327 52.1928V57.5529L34.9434 56.618V51.2891L36.5327 52.1928Z" fill="#B3B3B3" />
      <path
        d="M63.396 69.1131V71.6374C63.396 72.4164 62.8351 72.7281 62.1495 72.3541L36.5332 57.5515V52.1914L62.1807 66.994C62.8663 67.3991 63.396 68.334 63.396 69.1131Z"
        fill="#EAA49D"
      />
      <path
        d="M48.4999 73.2623C48.9985 73.5427 49.3725 74.2283 49.3725 74.7893L49.3413 83.4527C49.3413 84.0136 48.9362 84.2318 48.4376 83.9513L40.896 79.5884C40.3974 79.308 40.0234 78.6224 40.0234 78.0614L40.0546 69.398C40.0546 68.8371 40.4597 68.6189 40.9272 68.8994L48.4999 73.2623Z"
        fill="#EAA49D"
      />
      <path
        d="M62.5234 81.3326C63.022 81.613 63.396 82.2986 63.396 82.8596L63.3648 91.523C63.3648 92.0839 62.9597 92.3021 62.4922 92.0216L54.9507 87.6587C54.4521 87.3783 54.0781 86.6927 54.0781 86.1317L54.1093 77.4683C54.1093 76.9074 54.5144 76.6892 55.013 76.9697L62.5234 81.3326Z"
        fill="#EAA49D"
      />
      <path
        d="M61.993 26.0153C62.7721 26.4827 63.4265 27.5735 63.4265 28.4772L63.3953 35.8941C63.3953 36.7978 62.7409 37.1718 61.9618 36.7043L40.5214 24.3325C39.7423 23.865 39.0879 22.7743 39.0879 21.8706L39.1191 14.4537C39.1191 13.5499 39.7735 13.176 40.5526 13.6434L61.993 26.0153Z"
        fill="#EAA49D"
      />
      <path d="M36.5327 29.0091V43.5624L34.9434 42.6275V28.0742L36.5327 29.0091Z" fill="#B3B3B3" />
      <path
        d="M38.9016 31.7813L38.8705 43.4675C38.8705 44.2466 38.3095 44.5583 37.6239 44.1531L36.5332 43.5299V28.9766L37.6862 29.631C38.3718 30.0673 38.9016 31.0022 38.9016 31.7813Z"
        fill="#EAA49D"
      />
      <path
        d="M47.4714 11.6148C47.4714 12.2069 47.0662 12.4562 46.5365 12.1446C46.0067 11.833 45.6016 11.1162 45.6016 10.5241C45.6016 9.932 46.0067 9.68269 46.5365 9.99432C47.0662 10.306 47.4714 11.0227 47.4714 11.6148Z"
        fill="#666666"
      />
      <path
        d="M47.0654 11.3635C47.0654 11.6752 46.8473 11.831 46.5356 11.644C46.2552 11.4882 46.0059 11.083 46.0059 10.7714C46.0059 10.4598 46.224 10.304 46.5356 10.4909C46.8161 10.6468 47.0654 11.0519 47.0654 11.3635Z"
        fill="#333333"
      />
      <path
        d="M57.2549 15.9506L48.0617 10.6528C47.9059 10.5593 47.7812 10.3412 47.7812 10.1231V9.74909C47.7812 9.56211 47.9059 9.46862 48.0617 9.53095L57.2549 14.8287C57.4107 14.9222 57.5354 15.1404 57.5354 15.3585V15.7325C57.5354 15.9506 57.4107 16.0441 57.2549 15.9506Z"
        fill="black"
      />
      <path
        d="M72.7745 34.4974C72.5876 34.4039 72.3382 34.4039 72.0578 34.5597C71.4968 34.8714 71.0605 35.6504 71.0605 36.3049L71.0917 45.4669C71.0917 45.7785 71.2164 45.9967 71.3722 46.1213L71.9331 46.433C71.7461 46.3395 71.6526 46.0902 71.6526 45.7785L71.6215 36.6165C71.6215 35.9932 72.0578 35.2142 72.6187 34.8714C72.8992 34.7155 73.1485 34.6844 73.3355 34.809L72.7745 34.4974Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.6183 34.9037C73.1793 34.5921 73.6155 34.8414 73.6155 35.4646L73.6467 44.6267C73.6467 45.2499 73.2104 46.029 72.6495 46.3718C72.0886 46.6835 71.6523 46.4341 71.6523 45.8109L71.6211 36.6488C71.6211 35.9944 72.0574 35.2153 72.6183 34.9037Z"
        fill="#BFBFBF"
      />
      <path
        d="M72.7731 35.9294C73.2406 35.6801 73.5834 35.8671 73.5834 36.3969L73.6145 43.9073C73.6145 44.4371 73.2406 45.0603 72.8043 45.3408C72.3369 45.5901 71.9941 45.4031 71.9941 44.8733L71.9629 37.363C71.9629 36.802 72.3369 36.1788 72.7731 35.9294Z"
        fill="#CCCCCC"
      />
      <path
        d="M33.9772 10.7507L26.4357 6.38785C26.1864 6.23203 25.9682 6.23203 25.8124 6.32552L24.3789 7.13577C24.5347 7.04228 24.7529 7.04228 25.0022 7.1981L32.5437 11.561C33.0423 11.8414 33.4163 12.527 33.4163 13.088L33.3851 21.7514C33.3851 22.0319 33.2916 22.2189 33.1358 22.3123L34.5693 21.5021C34.7252 21.4086 34.8186 21.2216 34.8186 20.9412L34.8498 12.2777C34.8498 11.7168 34.4758 11.0312 33.9772 10.7507Z"
        fill="#EAA49D"
      />
      <path
        d="M32.5741 11.5943C33.0727 11.8748 33.4467 12.5604 33.4467 13.1213L33.4155 21.7847C33.4155 22.3457 33.0104 22.5638 32.5118 22.2833L24.9702 17.9205C24.4716 17.64 24.0977 16.9544 24.0977 16.3935L24.1288 7.73003C24.1288 7.16909 24.5339 6.95095 25.0014 7.23142L32.5741 11.5943Z"
        fill="#E5796E"
      />
      <path
        d="M28.2437 15.2083L27.5269 17.1093L24.5664 15.3953L26.405 12.8087C26.6544 12.4348 27.3088 12.8087 27.5581 13.4631L28.2437 15.2083Z"
        fill="white"
      />
      <path d="M29.3952 18.1987L27.5254 17.108L28.2421 15.207L29.3952 18.1987Z" fill="#E6E6E6" />
      <path
        d="M32.3577 19.9138L29.3972 18.1998L28.2441 15.2081L29.1479 12.8085C29.4284 12.0918 30.4879 12.715 30.7684 13.7434L32.3577 19.9138Z"
        fill="white"
      />
      <path
        d="M28.2444 11.8116C28.2444 12.2167 27.9639 12.4037 27.59 12.1855C27.216 11.9674 26.9355 11.4688 26.9355 11.0325C26.9355 10.6274 27.216 10.4404 27.59 10.6585C27.9639 10.9078 28.2444 11.4065 28.2444 11.8116Z"
        fill="#E6E6E6"
      />
      <path
        d="M61.7746 43.7838L43.2012 33.0636C42.9519 32.9078 42.7026 32.9078 42.5468 33.0013L41.1133 33.8115C41.2691 33.7181 41.5184 33.7181 41.7677 33.8739L60.3411 44.5941C60.8397 44.8746 61.2448 45.5913 61.2448 46.1834V49.3621C61.2448 49.6426 61.1513 49.8607 60.9644 49.9542L62.3979 49.1439C62.5537 49.0505 62.6784 48.8323 62.6784 48.5518V45.3732C62.6784 44.7811 62.2732 44.0643 61.7746 43.7838Z"
        fill="#EAA49D"
      />
      <path
        d="M60.3423 44.5954C60.8409 44.8758 61.246 45.5926 61.246 46.1847V49.3634C61.246 49.9555 60.8409 50.1736 60.3111 49.8931L41.7377 39.1729C41.2391 38.8925 40.834 38.1757 40.834 37.5836V34.4049C40.834 33.8128 41.2391 33.5947 41.7689 33.8752L60.3423 44.5954Z"
        fill="#E5796E"
      />
      <path
        d="M59.7174 46.5248L42.7022 36.646C42.5152 36.5213 42.3594 36.646 42.3594 36.9264C42.3594 37.1758 42.5152 37.4874 42.7022 37.612L59.7174 47.4908C59.9044 47.6155 60.0602 47.4908 60.0602 47.2104C60.0913 46.9611 59.9355 46.6494 59.7174 46.5248Z"
        fill="white"
      />
      <path
        d="M61.7746 54.8385L43.2012 44.1183C42.9519 43.9625 42.7026 43.9625 42.5468 44.056L41.1133 44.8662C41.2691 44.7727 41.5184 44.7728 41.7677 44.9286L60.3411 55.6488C60.8397 55.9292 61.2448 56.646 61.2448 57.2381V60.4168C61.2448 60.6972 61.1513 60.9154 60.9644 61.0089L62.3979 60.1986C62.5537 60.1051 62.6784 59.887 62.6784 59.6065V56.4279C62.6784 55.8358 62.2732 55.1502 61.7746 54.8385Z"
        fill="#EAA49D"
      />
      <path
        d="M60.3423 55.6813C60.8409 55.9618 61.246 56.6785 61.246 57.2706V60.4493C61.246 61.0414 60.8409 61.2596 60.3111 60.9791L41.7377 50.2589C41.2391 49.9784 40.834 49.2616 40.834 48.6695V45.4909C40.834 44.8988 41.2391 44.6806 41.7689 44.9611L60.3423 55.6813Z"
        fill="#E5796E"
      />
      <path
        d="M59.7174 57.4271L42.7022 47.5483C42.5152 47.4237 42.3594 47.5483 42.3594 47.8288C42.3594 48.0781 42.5152 48.3897 42.7022 48.5144L59.7174 58.3932C59.9044 58.5178 60.0602 58.3932 60.0602 58.1127C60.0913 57.8322 59.9355 57.5518 59.7174 57.4271Z"
        fill="white"
      />
      <path
        d="M33.9772 65.7546L26.4357 61.3918C26.1864 61.2359 25.9682 61.2359 25.8124 61.3294L24.3789 62.1397C24.5347 62.0462 24.7529 62.0462 25.0022 62.202L32.5437 66.5649C33.0423 66.8453 33.4163 67.5309 33.4163 68.0919L33.3851 76.7553C33.3851 77.0358 33.2916 77.2228 33.1358 77.3162L34.5693 76.506C34.7252 76.4125 34.8186 76.2255 34.8186 75.9451L34.8498 67.2816C34.8498 66.7207 34.4758 66.0351 33.9772 65.7546Z"
        fill="#EAA49D"
      />
      <path
        d="M32.5741 66.5982C33.0727 66.8787 33.4467 67.5643 33.4467 68.1252L33.4155 76.7886C33.4155 77.3496 33.0104 77.5677 32.5118 77.2872L24.9702 72.9244C24.4716 72.6439 24.0977 71.9583 24.0977 71.3974L24.1288 62.7339C24.1288 62.173 24.5339 61.9549 25.0014 62.2353L32.5741 66.5982Z"
        fill="#E5796E"
      />
      <path
        d="M32.3237 69.526L28.7399 69.8376L25.0938 65.3501C25.0938 65.1942 25.1872 65.1631 25.3119 65.2254L32.1055 69.152C32.199 69.2143 32.3237 69.4013 32.3237 69.526Z"
        fill="white"
      />
      <path
        d="M28.7086 70.1164L32.2924 69.8048V74.1988C32.2924 74.3547 32.1989 74.3858 32.0743 74.3235L25.2806 70.3969C25.156 70.3346 25.0625 70.1476 25.0625 70.0229V65.6289L28.6463 70.0541L28.7086 70.1164Z"
        fill="white"
      />
      <path
        d="M61.4627 67.4346L27.8686 48.0198C27.5258 47.8329 27.2142 47.8017 26.996 47.9264L25.5625 48.7366C25.7806 48.612 26.0923 48.612 26.4351 48.8301L60.0292 68.2449C60.7148 68.65 61.2446 69.5849 61.2446 70.364V72.8882C61.2446 73.2622 61.1199 73.5427 60.8706 73.6673L62.3041 72.8571C62.5223 72.7324 62.6469 72.4519 62.6781 72.078V69.5538C62.6781 68.7747 62.1172 67.8398 61.4627 67.4346Z"
        fill="#EAA49D"
      />
      <path
        d="M60.0282 68.2795C60.7138 68.6847 61.2435 69.6196 61.2435 70.3987V72.9229C61.2435 73.702 60.6826 74.0136 59.997 73.6397L26.4029 54.2249C25.7173 53.8197 25.1875 52.8848 25.1875 52.1058V49.5815C25.1875 48.8024 25.7484 48.4908 26.434 48.8648L60.0282 68.2795Z"
        fill="#E5796E"
      />
      <path
        d="M59.1552 70.1758L26.8076 51.3843C26.6207 51.2596 26.4648 51.3843 26.4648 51.6647C26.4648 51.914 26.6207 52.2257 26.8076 52.3503L59.1552 71.1418C59.3422 71.2665 59.498 71.1418 59.498 70.8614C59.498 70.5809 59.3422 70.2693 59.1552 70.1758Z"
        fill="white"
      />
      <path
        d="M47.7819 73.696L40.2404 69.3332C39.9911 69.1773 39.7729 69.1773 39.6171 69.2708L38.1836 70.0811C38.3394 69.9876 38.5576 69.9876 38.8069 70.1434L46.3484 74.5063C46.847 74.7868 47.221 75.4723 47.221 76.0333L47.1898 84.6967C47.1898 84.9772 47.0963 85.1642 46.9405 85.2577L48.374 84.4474C48.5298 84.3539 48.6233 84.1669 48.6233 83.8865L48.6545 75.223C48.6545 74.6621 48.2494 73.9765 47.7819 73.696Z"
        fill="#EAA49D"
      />
      <path
        d="M46.3475 74.5357C46.8461 74.8162 47.2201 75.5018 47.2201 76.0627L47.1889 84.7261C47.1889 85.2871 46.7838 85.5052 46.2852 85.2247L38.7437 80.8619C38.2451 80.5814 37.8711 79.8958 37.8711 79.3349L37.9023 70.6714C37.9023 70.1105 38.3074 69.8924 38.7748 70.1728L46.3475 74.5357Z"
        fill="#E5796E"
      />
      <path
        d="M45.6605 82.324L39.4278 78.709C39.0538 78.4909 38.7422 77.9611 38.7422 77.5248V74.0969C38.7422 73.6606 39.0538 73.5047 39.4278 73.7229L45.6605 77.3378C46.0344 77.556 46.3461 78.0858 46.3461 78.5221V81.95C46.3461 82.3552 46.0344 82.5421 45.6605 82.324ZM39.4278 74.0345C39.2096 73.9099 39.0227 74.0034 39.0227 74.2527V77.6806C39.0227 77.93 39.2096 78.2416 39.4278 78.3662L45.6605 81.9812C45.8786 82.1058 46.0656 82.0124 46.0656 81.763V78.3351C46.0656 78.0858 45.8786 77.7741 45.6605 77.6495L39.4278 74.0345Z"
        fill="white"
      />
      <path
        d="M42.5442 79.9597C41.6405 79.4299 40.8926 78.1522 40.8926 77.0927C40.8926 76.0331 41.6405 75.628 42.5442 76.1578C43.448 76.6875 44.1959 77.9652 44.1959 79.0248C44.1959 80.0532 43.4791 80.4895 42.5442 79.9597ZM42.5442 76.4694C41.7963 76.0331 41.1731 76.3759 41.1731 77.2485C41.1731 78.1211 41.7963 79.1806 42.5442 79.6169C43.2922 80.0532 43.9154 79.7104 43.9154 78.8378C43.9154 77.9964 43.2922 76.9057 42.5442 76.4694Z"
        fill="white"
      />
      <path
        d="M44.5067 76.9336L40.5801 74.6587V73.8484C40.5801 73.568 40.7671 73.4433 41.0164 73.5991L44.0704 75.3443C44.3197 75.5001 44.5067 75.8429 44.5067 76.1234V76.9336ZM40.8606 74.4717L44.2262 76.4038V75.9052C44.2262 75.8117 44.1639 75.6871 44.0704 75.6247L41.0164 73.8796C40.9229 73.8173 40.8606 73.8796 40.8606 73.9731V74.4717Z"
        fill="white"
      />
      <path
        d="M61.8073 81.7663L54.2658 77.4035C54.0165 77.2477 53.7983 77.2477 53.6425 77.3411L52.209 78.1514C52.3648 78.0579 52.5829 78.0579 52.8323 78.2137L60.3738 82.5766C60.8724 82.8571 61.2464 83.5427 61.2464 84.1036L61.2152 92.767C61.2152 93.0475 61.1217 93.2345 60.9659 93.328L62.3994 92.5177C62.5552 92.4242 62.6487 92.2373 62.6487 91.9568L62.6799 83.2934C62.6799 82.7324 62.2748 82.078 61.8073 81.7663Z"
        fill="#EAA49D"
      />
      <path
        d="M60.375 82.6099C60.8736 82.8904 61.2475 83.576 61.2475 84.1369L61.2164 92.8003C61.2164 93.3613 60.8113 93.5794 60.3438 93.299L52.8023 88.9361C52.3036 88.6556 51.9297 87.97 51.9297 87.4091L51.9608 78.7457C51.9608 78.1847 52.366 77.9666 52.8646 78.247L60.375 82.6099Z"
        fill="#E5796E"
      />
      <path
        d="M56.6027 82.4228C54.8887 81.4255 53.4863 81.9553 53.4863 83.5758C53.4863 84.6354 54.0784 85.9131 54.951 86.9415C54.9198 87.2219 54.7329 87.814 53.9538 87.3466C53.9538 87.3466 55.0445 88.562 56.0729 87.9699C56.2599 88.0945 56.4157 88.2192 56.6027 88.3438C58.3167 89.341 59.719 88.8113 59.719 87.1908C59.7502 85.5391 58.3478 83.42 56.6027 82.4228Z"
        fill="white"
      />
      <path
        d="M61.2423 26.4534L39.8018 14.0815C39.3967 13.8634 39.0539 13.8322 38.7734 13.988L37.3711 14.8294C37.6204 14.6736 37.9944 14.7048 38.3995 14.9229L59.8399 27.2948C60.619 27.7622 61.2734 28.8529 61.2734 29.7567L61.2423 37.1736C61.2423 37.6099 61.0864 37.9527 60.8371 38.0773L62.2706 37.2671C62.5199 37.1112 62.6758 36.7996 62.6758 36.3633L62.7069 28.9464C62.6758 28.0115 62.0525 26.9208 61.2423 26.4534Z"
        fill="#EAA49D"
      />
      <path
        d="M59.8387 27.2926C60.6178 27.7601 61.2722 28.8508 61.2722 29.7545L61.241 37.1714C61.241 38.0752 60.5866 38.4491 59.8075 37.9817L38.3671 25.6098C37.588 25.1424 36.9336 24.0516 36.9336 23.1479L36.9648 15.731C36.9648 14.8273 37.6192 14.4533 38.3983 14.9208L59.8387 27.2926Z"
        fill="#E5796E"
      />
      <path
        d="M59.2788 29.0704L39.0537 17.353C38.8668 17.2284 38.7109 17.353 38.7109 17.6335C38.7109 17.8828 38.8668 18.1944 39.0537 18.3191L59.2788 30.0365C59.4658 30.1612 59.6216 30.0365 59.6216 29.756C59.6527 29.4756 59.4969 29.1951 59.2788 29.0704Z"
        fill="white"
      />
      <path
        d="M59.2788 31.8751L39.0537 20.1577C38.8668 20.033 38.7109 20.1577 38.7109 20.4382C38.7109 20.6875 38.8668 20.9991 39.0537 21.1238L59.2788 32.8412C59.4658 32.9658 59.6216 32.8412 59.6216 32.5607C59.6527 32.2803 59.4969 31.9998 59.2788 31.8751Z"
        fill="white"
      />
      <path
        d="M59.2788 34.6798L39.0537 22.9624C38.8668 22.8377 38.7109 22.9624 38.7109 23.2429C38.7109 23.4922 38.8668 23.8038 39.0537 23.9284L59.2788 35.6459C59.4658 35.7705 59.6216 35.6459 59.6216 35.3654C59.6527 35.0849 59.4969 34.8045 59.2788 34.6798Z"
        fill="white"
      />
      <path
        d="M26.5923 29.3512C26.5611 29.2888 26.53 29.2265 26.53 29.1642C26.53 29.1019 26.53 29.0084 26.53 28.946C26.53 28.7902 26.4676 28.6344 26.3118 28.5097C26.2495 28.4786 26.1872 28.4474 26.156 28.3851C26.1248 28.3539 26.0937 28.2916 26.0625 28.2293C25.9067 27.98 25.5639 27.9176 25.2523 27.9488C24.629 28.0111 24.0369 28.2604 23.5071 28.5409C22.7592 28.946 22.1048 29.507 21.6373 30.1926C21.4503 30.473 21.2945 30.7535 21.201 31.0652C21.1075 31.3456 21.014 31.6261 20.9205 31.8754C20.8582 32.0624 20.7959 32.2494 20.7647 32.4363C20.7336 32.5298 20.7336 32.6233 20.7336 32.7168C20.7336 32.8103 20.7336 32.8726 20.7024 32.9661C20.6401 33.0596 14.906 40.2272 14.5009 40.7258C14.1269 41.1621 13.8465 41.723 13.4413 42.097C12.5688 42.9072 11.4469 43.4682 10.3873 43.998C9.51474 44.4031 8.0189 44.8082 8.39286 46.1171C8.51751 46.491 8.82915 46.8027 9.10962 47.0831C9.57707 47.5817 9.95103 48.1738 10.2315 48.7971C10.2627 48.8283 10.2627 48.8906 10.2938 48.8906C10.3562 48.9218 10.4185 48.8906 10.4496 48.8594C11.9455 48.0492 16.0591 45.1822 16.7447 44.7459C16.7447 44.7147 16.7447 44.7147 16.7447 44.6836C16.9628 44.5901 17.1186 44.3719 17.2744 44.1849C17.773 43.4993 18.2405 42.8137 18.7079 42.097C19.1442 41.3802 19.5805 40.6323 19.9856 39.9156C20.3908 39.1676 20.7647 38.4197 21.1387 37.6406C21.5126 36.8927 22.3852 35.0229 22.4476 34.8982C22.5099 34.7736 22.541 34.6178 22.6657 34.5554C22.7592 34.5243 22.8527 34.5243 22.915 34.462C23.0085 34.3996 23.102 34.3373 23.1643 34.275C24.0057 33.4959 24.4732 32.4052 25.1276 31.5014C25.2523 31.3456 25.3769 31.1586 25.5327 31.034C25.8444 30.7847 26.2495 30.66 26.4365 30.3484C26.53 30.2237 26.5611 30.0679 26.5923 29.9121C26.5923 29.7251 26.6546 29.5381 26.5923 29.3512Z"
        fill="#FFE5CC"
      />
      <path
        d="M4.49642 113.212C4.52759 113.742 4.71457 114.209 5.08853 114.49C5.30667 114.645 5.58714 114.708 5.86761 114.708C6.61553 114.739 7.39462 114.458 7.98672 113.991C8.64115 113.43 9.04628 112.62 9.73187 112.121C10.1058 111.841 10.5421 111.685 10.9472 111.498C11.9756 111.03 12.9417 110.407 13.7831 109.659C13.9701 109.503 14.1882 109.285 14.2817 109.067C14.4375 108.693 14.2817 108.257 13.9389 108.07C13.7208 107.945 13.5026 107.883 13.2533 107.852C12.4119 107.696 11.5393 107.758 10.6979 107.821C9.60722 107.914 8.5165 108.07 7.48811 108.444C7.05182 108.6 6.61554 108.818 6.24158 109.098C6.08576 109.192 5.86761 109.472 5.71179 109.503C5.64947 109.503 5.24434 109.379 5.24434 109.348C4.99504 110.095 4.77689 110.875 4.62108 111.654C4.55875 112.028 4.46526 112.651 4.49642 113.212Z"
        fill="#E5796E"
      />
      <path
        d="M12.3829 72.9196C12.3518 72.6391 12.1959 72.3587 12.0401 72.0782C11.1052 70.3019 10.6689 68.3074 9.98334 66.4065C9.64054 65.5027 9.26658 64.599 8.73681 63.7887C8.42517 63.3213 7.55259 62.0124 6.80467 62.2929C6.71118 62.3241 6.61769 62.4175 6.5242 62.4799C5.15301 63.8199 3.99997 65.3781 3.15856 67.0921C2.28598 68.8372 1.88086 70.52 1.88086 72.4833C1.88086 73.948 2.099 75.3815 2.56645 76.7839C2.72227 77.2825 2.90925 77.7499 3.00274 78.2486C3.09623 78.7783 3.1274 79.3393 3.1274 79.8691C3.15856 81.9882 3.15856 84.1073 3.18972 86.1952C3.18972 87.1613 3.18972 88.1273 3.28321 89.0934C3.34554 90.0595 3.3767 91.0879 3.09623 92.0228C2.81576 93.02 2.62878 94.0172 2.50413 95.0456C2.19249 97.7257 2.75343 100.406 3.31437 103.023C3.81299 105.392 4.3116 107.76 4.84138 110.129C4.87255 110.253 4.90371 110.409 4.9972 110.503C5.09069 110.596 5.18418 110.627 5.27767 110.658C5.9321 110.845 6.64886 110.721 7.24096 110.472C7.86423 110.191 8.39401 109.786 8.89262 109.318C9.01728 109.194 9.17309 109.069 9.23542 108.913C9.4224 108.508 9.23542 108.041 9.0796 107.636C7.58376 103.958 7.67725 99.7825 8.61215 95.9182C8.92378 94.6093 9.32891 93.3005 9.51589 91.9293C9.51589 91.8358 9.54705 91.7111 9.54705 91.6176C9.54705 91.6176 9.54705 91.6176 9.54705 91.5865C10.9806 85.6343 11.7285 79.5263 12.3829 73.4182C12.3829 73.2936 12.4141 73.1066 12.3829 72.9196Z"
        fill="#333333"
      />
      <path
        d="M23.3207 107.136C22.4481 107.105 21.5756 107.105 20.7342 107.198C20.0797 107.292 19.363 107.51 18.8644 107.946C18.5216 108.258 18.2722 108.632 18.0229 109.006C17.3685 110.034 16.1843 112.496 17.8983 113.182C18.2099 113.306 18.5216 113.306 18.8332 113.244C19.9239 113.088 20.8588 112.34 21.9495 112.153C22.3546 112.091 22.7598 112.091 23.1649 112.091C24.6296 112.06 26.1254 111.779 27.372 110.969C27.9641 110.595 28.3692 110.034 28.525 109.348C28.7431 108.414 28.1822 107.821 27.3096 107.634C26.0319 107.354 24.6919 107.198 23.3207 107.136Z"
        fill="#D86767"
      />
      <path
        d="M21.9171 106.48C21.0446 103.582 20.6083 100.528 20.6083 97.5047C20.6083 95.074 20.2967 92.5186 20.6083 90.119C20.9199 87.6882 20.7329 85.0394 20.2967 82.6398C19.6111 78.7444 17.8971 75.0671 17.5543 71.1405C17.4296 69.6446 17.4919 68.1488 17.2426 66.6841C15.4975 67.5879 13.4095 67.8372 11.4774 67.4009C10.4802 67.1516 9.51412 66.7464 8.57922 66.4037C8.1741 66.2478 7.73781 66.1855 7.33268 66.0609C6.95872 65.9362 6.74058 65.7181 6.42894 65.4688C4.90194 67.3074 3.9982 69.6446 3.90471 72.0131C3.81122 73.8205 4.18518 75.6592 4.96427 77.2797C5.77451 78.9937 7.08338 80.209 8.48573 81.4244C9.85692 82.6086 11.2593 83.7617 12.2565 85.2887C13.0979 86.5975 13.5654 88.2804 13.9705 89.745C14.4379 91.4279 14.8119 93.1418 14.8742 94.9182C14.9366 96.5387 14.7184 98.0345 14.9989 99.655C15.1235 100.403 15.3105 101.12 15.5287 101.836C16.3701 104.423 17.8347 106.916 17.7413 109.627C17.7413 109.752 17.7413 109.877 17.8036 109.97C17.8659 110.032 17.9594 110.064 18.0217 110.095C19.1436 110.469 20.359 109.97 21.2939 109.253C21.6055 109.004 21.9171 108.724 22.073 108.35C22.2911 107.757 22.073 107.103 21.9171 106.48Z"
        fill="#424242"
      />
      <path
        d="M1.91039 45.7444C1.56759 47.5208 1.75457 49.4841 2.09737 51.1669C2.59599 53.5976 3.28158 55.9972 4.18532 58.3345C4.55928 59.2694 4.93324 60.2354 5.02673 61.2638C5.18255 63.0401 4.30997 64.723 3.46856 66.3123C3.37507 66.4681 3.31274 66.6239 3.31274 66.7797C3.31274 66.9044 3.40623 67.029 3.46856 67.1537C4.49695 68.6184 6.05512 69.6468 7.70678 70.2389C9.38961 70.831 11.1971 71.018 12.9734 70.9868C14.5004 70.9556 16.0274 70.7998 17.3986 70.1142C17.4609 67.9016 17.5544 65.689 17.5233 63.4452C17.4921 61.3573 16.9935 59.1759 17.4921 57.0879C17.6791 56.34 17.9907 55.6232 18.1777 54.9065C18.3958 54.1274 18.4582 53.286 18.3958 52.4757C18.3335 51.8213 18.1465 51.0734 17.5856 50.7306C17.3986 50.6371 17.2116 50.5748 17.0246 50.4813C16.1521 50.0138 15.9339 48.7985 15.0925 48.2687C14.7809 48.0817 14.4381 48.0194 14.0953 47.9259C10.418 46.8975 7.64445 42.3788 3.53089 43.0332C3.31274 43.0644 3.0946 43.0956 2.90762 43.2202C2.72064 43.3449 2.59599 43.563 2.47133 43.7811C2.22202 44.4044 2.03504 45.0588 1.91039 45.7444Z"
        fill="#D9D9D9"
      />
      <path
        d="M3.71843 44.31C3.65611 44.4034 3.62494 44.5281 3.59378 44.6216C3.34447 45.4007 4.06123 45.9304 4.6845 46.2732C5.65056 46.803 6.80361 47.0523 7.92549 47.0523C8.61109 47.0523 9.26552 46.9588 9.95111 46.8342C10.2004 46.7719 10.3874 46.7719 10.5121 46.5537C10.6679 46.2732 10.6679 45.9616 10.699 45.65C10.7302 44.9644 10.7614 44.31 10.7614 43.6244C10.7925 42.5648 10.8237 41.5364 10.8548 40.4769C10.8548 40.0406 10.2627 40.1652 9.91995 40.1029C9.4525 40.0406 8.95388 39.9782 8.48643 39.8847C7.55153 39.6978 6.61663 39.4485 5.77522 39.0122C5.55707 38.8875 5.18311 38.6071 4.9338 38.6071C4.62217 38.5759 4.62217 39.0122 4.59101 39.2615C4.49752 39.9782 4.49752 40.695 4.49752 41.4118C4.46635 42.2532 4.37286 43.1257 3.9989 43.8737C3.87425 44.0295 3.78076 44.1853 3.71843 44.31Z"
        fill="#FFE5CC"
      />
      <path
        d="M12.599 33.8693C12.6924 34.0251 12.7859 34.1809 12.8171 34.3679C12.8483 34.5237 12.7859 34.7107 12.7859 34.8665C12.7859 35.4586 13.378 35.8637 13.8455 36.2377C14.0325 36.3935 14.2195 36.5805 14.1883 36.7987C14.1883 36.8921 14.126 36.9856 14.0948 37.048C13.752 37.7024 13.752 38.4503 13.7832 39.1982C13.8143 39.6657 14.1571 40.8811 13.6897 41.1927C13.1287 41.5355 12.5366 41.816 11.9134 42.0029C11.5394 42.1276 11.1031 42.1899 10.7603 42.0029C10.4487 41.816 10.2617 41.4732 9.98124 41.255C9.6696 40.9745 9.20215 41.3797 8.85935 41.5043C8.3919 41.6601 7.92445 41.7536 7.42583 41.7848C6.49093 41.8471 5.52487 41.7225 4.65229 41.3797C4.09135 41.1615 3.56158 40.8811 3.12529 40.4759C2.62667 40.0085 2.28388 39.4475 1.94108 38.8554C1.50479 38.0764 1.0685 37.2349 0.975012 36.3624C0.881522 35.4275 1.16199 34.4614 1.59828 33.6511C2.03457 32.8409 2.59551 32.2176 3.34343 31.6567C4.68346 30.6283 6.49093 30.4102 8.20492 30.4102C8.82819 30.4102 9.3268 30.4725 9.91891 30.6906C10.511 30.9399 11.0096 31.3451 11.2589 31.9372C11.5706 32.6228 12.1938 33.2149 12.599 33.8693Z"
        fill="#FFE5CC"
      />
      <path
        d="M8.89111 38.2973C9.10926 37.9545 9.42089 37.7052 9.85718 37.4559C10.7609 36.9572 10.9167 35.8665 11.0102 34.9628C11.166 33.8097 11.1349 32.5009 10.7298 31.4102C10.3558 30.4129 9.10925 30.0078 8.14319 29.8831C6.95898 29.7273 5.74361 29.8831 4.62172 30.3194C2.97006 30.9739 1.59888 32.532 0.850957 34.1214C-0.0527814 36.0535 -0.0839448 38.0168 0.0718721 40.1047C0.134199 40.7903 0.1342 41.2889 0.632814 41.8499C0.944448 42.1927 1.31841 42.442 1.69237 42.6601C3.81148 43.8755 6.49153 44.0937 8.79762 43.2522C9.14042 43.1276 9.48321 42.9718 9.76368 42.7536C10.4493 42.1615 10.3558 41.3513 9.79485 40.6968C9.51438 40.354 9.01577 40.3229 8.79762 39.9178C8.70413 39.7308 8.67297 39.4815 8.67297 39.2633C8.67297 38.8582 8.7353 38.5466 8.89111 38.2973Z"
        fill="#733118"
      />
      <path
        d="M36.9381 30.1002L26.1244 23.8675C25.7817 23.6805 25.47 23.6494 25.2519 23.774L23.8184 24.5843C24.0365 24.4596 24.3481 24.4596 24.6909 24.6778L35.5358 30.9416C36.2214 31.3467 36.7512 32.2816 36.7512 33.0607L36.72 44.747C36.72 45.1209 36.5953 45.4014 36.346 45.5261L37.7796 44.7158C37.9977 44.5912 38.1224 44.3107 38.1535 43.9367L38.1847 32.2505C38.1847 31.4714 37.6237 30.5053 36.9381 30.1002Z"
        fill="#D86767"
      />
      <path
        d="M35.5366 30.9411C36.2222 31.3462 36.752 32.2811 36.752 33.0602L36.7208 44.7465C36.7208 45.5256 36.1599 45.8372 35.4743 45.4632L24.6294 39.1994C23.9438 38.7943 23.4141 37.8594 23.4141 37.0803L23.4452 25.394C23.4452 24.6149 24.0062 24.3033 24.6918 24.6773L35.5366 30.9411Z"
        fill="#E5796E"
      />
      <path
        d="M29.7091 31.161C27.8082 30.0703 26.25 30.9428 26.25 33.1243C26.25 35.3057 27.8082 37.9858 29.7091 39.0765C31.6101 40.1672 33.1683 39.2946 33.1683 37.1132C33.1683 34.9006 31.6413 32.2517 29.7091 31.161ZM28.8677 36.4588V32.9061L31.5478 36.2406L28.8677 36.4588Z"
        fill="white"
      />
      <path
        d="M18.7707 55.9324C22.1986 56.2129 25.6266 55.9635 29.0546 55.6831C29.0858 55.6831 29.0858 55.6831 29.1169 55.6831C29.9895 55.5896 30.1765 54.8728 30.5193 54.1872C30.8309 53.5951 31.0802 52.9719 31.3607 52.3486C32.7942 48.9829 34.0407 45.5861 35.1315 42.0958C35.3184 41.7842 35.6612 41.5661 35.9417 41.3479C37.1259 40.413 38.092 39.1665 38.7464 37.7953C38.9646 37.359 39.1204 36.8292 38.9022 36.3929C38.8399 36.2683 38.7153 36.1436 38.6841 36.019C38.6218 35.8632 38.6529 35.6762 38.5906 35.4892C38.4659 35.1152 38.0297 34.8971 37.8427 34.5543C37.7803 34.3985 37.718 34.2427 37.5622 34.1803C37.4375 34.118 37.2817 34.2115 37.2194 34.305C37.1259 34.3985 37.0947 34.5231 37.0324 34.6478C36.752 35.3645 36.191 35.9566 35.5054 36.2994C35.3808 36.3618 35.1938 36.4241 35.1003 36.3306C35.3184 35.9878 35.4743 35.6139 35.5677 35.2399C35.6301 35.0217 35.6612 34.7724 35.5677 34.5543C35.4743 34.3362 35.2249 34.1803 35.0068 34.305C34.7887 34.3985 34.7575 34.6789 34.6952 34.8971C34.6017 35.2711 34.4459 35.6139 34.2589 35.9255C34.0407 36.2371 33.7603 36.5176 33.5421 36.8604C33.3551 37.1409 33.2305 37.4525 33.137 37.7641C32.9812 38.3251 32.9189 38.9483 32.8877 39.5404C32.8877 39.8521 32.8254 40.1949 32.763 40.5065C32.763 40.5377 32.7319 40.5688 32.7319 40.6C32.3891 41.5972 27.5899 50.6346 26.8732 51.6942C26.655 51.6942 26.468 51.6942 26.281 51.663C24.0061 51.4137 21.7312 51.0397 19.6432 50.1048C18.5837 49.6374 17.5865 49.0141 16.4957 48.7025C15.3739 48.3597 14.0962 48.3597 13.1613 49.0453C12.4133 49.6062 12.0394 50.4788 11.8524 51.3514C11.1668 54.7482 15.4674 55.5896 17.8981 55.9012C18.1786 55.8701 18.459 55.9012 18.7707 55.9324Z"
        fill="#FFE5CC"
      />
      <path
        d="M22.415 53.1905C22.415 54.3747 22.1346 55.4966 21.5736 56.525C21.3867 56.899 21.1685 57.0236 20.7322 57.0236C20.4206 57.0236 20.109 56.9925 19.7973 56.9301C18.0522 56.6808 16.307 56.3069 14.6242 55.7771C13.814 55.5278 13.1284 55.3408 12.4116 54.8734C12.0065 54.624 11.6637 54.2812 11.4455 53.8761C11.0404 53.2217 10.9158 52.4426 11.0404 51.6635C11.2897 50.2923 12.2246 49.0458 13.5335 48.516C13.6893 48.4537 13.8763 48.3914 14.0633 48.3602C14.4372 48.2979 14.8112 48.329 15.2163 48.3602C16.2759 48.516 17.3354 48.89 18.3326 49.2639C19.4234 49.6379 20.5141 50.043 21.5736 50.4481C22.0411 50.6351 22.0723 51.0403 22.1969 51.4765C22.3527 52.0375 22.415 52.5984 22.415 53.1905Z"
        fill="#F2F2F2"
      />
      <path
        d="M22.5083 29.4454C22.1967 29.6324 21.8851 29.7882 21.6669 30.0687C21.3241 30.4738 21.2618 31.0348 21.3241 31.5334C21.3553 31.7515 21.4176 31.9697 21.5423 32.1566C21.6669 32.3436 21.8851 32.4683 22.1032 32.4683C22.3213 32.4371 22.4772 32.3125 22.633 32.1255C22.7576 31.9385 22.8823 31.7515 22.9446 31.5334C23.0693 31.0971 23.0381 30.6296 23.2874 30.2557C23.4121 30.0999 23.5679 29.9752 23.7237 29.8194C23.9107 29.6636 24.0977 29.4766 24.2535 29.2584C24.347 29.1026 24.4405 28.9468 24.4716 28.7598C24.5028 28.5728 24.4716 28.3859 24.3781 28.23C24.0977 27.7938 23.7549 28.417 23.5679 28.604C23.2251 28.9156 22.8823 29.1961 22.5083 29.4454Z"
        fill="#FFE5CC"
      />
    </svg>
  );
};

export default MakeYourOwn;
