import { createContext, useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import PricesConfigService from '@services/api/pricing';
import ProductService from '@services/api/product';
import CalculationsService from '@services/pricing/calculations';
import { useUser } from '@contexts/User';

export const PricingContext = createContext({
  config: null,
  deliveryOptions: {
    isDevIncluded: true,
    isCustomDesign: true,
    isWithoutDesign: false,
    hasOwnDesign: false,
    designMultipliers: [{ value: 1, isMobile: true }],
    devMultipliers: [{ value: 1, isMobile: true }],
  },
  totalPrice: 0,
  totalTimelineWeeks: 1,
  selectedFeatures: [],
  sendProductRequest: async () => {},
  collectPriceDependantValues: () => {},
  setSelectedFeatures: () => {},
  setTotalPrice: () => {},
  setDeliveryOptions: () => {},
  calculateFeatureFixedCost: () => {},
  calculateFeatureCustomizationCost: () => {},
  calculateFeaturePrice: () => {},
  calculateFeaturesPrice: () => {},
  calculateTemplatePrice: () => {},
  calculateTimelineWeeks: () => {},
  calculateTotalPrice: () => {},
});

const PricingProvider = ({ children }) => {
  const { user } = useUser();
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState({
    isCustomDesign: true,
    isDevIncluded: true,
    isWithoutDesign: false,
    hasOwnDesign: false,
    designMultipliers: [1],
    devMultipliers: [1],
  });

  const { data: { data: config } = {} } = useQuery(
    ['priceConfig'],
    PricesConfigService.getActiveConfig,
    {
      enabled: !!user,
    }
  );

  const sendProductRequest = useCallback(async (product) => {
    if (!product) {
      console.log('Product data not found...');
      return;
    }

    await ProductService.create(product);
  }, []);

  const collectPriceDependantValues = useCallback(
    (feature) => {
      if (!feature || !config || !deliveryOptions) {
        return {};
      }

      if (feature.featureCategoryId === 'custom') {
        return {
          devCustomizationHours: CalculationsService.getCustomFeatureDevHours(
            config,
            deliveryOptions,
            feature
          ),
          designUICustomizationHours: CalculationsService.getCustomFeatureDesignHours(
            deliveryOptions,
            config,
            feature
          ),
          designUXCustomizationHours: 0,
          designFixedCost: 0,
          devFixedCost: 0,
        };
      }

      return {
        designFixedCost: feature.designFixedCost,
        devFixedCost: feature.devFixedCost,
        devCustomizationHours: CalculationsService.getDevCustomizationHours(
          feature,
          deliveryOptions
        ),
        designUICustomizationHours: CalculationsService.getDesignUICustomizationHours(
          feature,
          deliveryOptions
        ),
        designUXCustomizationHours: CalculationsService.getDesignUXCustomizationHours(
          feature,
          deliveryOptions
        ),
      };
    },
    [config, deliveryOptions]
  );

  const calculateFeatureFixedCost = useCallback(
    (feature) => {
      if (!feature || !config || !deliveryOptions) {
        return 0;
      }

      return feature.featureCategoryId !== 'custom'
        ? CalculationsService.getTemplateFeatureFixedCost(feature, deliveryOptions)
        : CalculationsService.getCustomFeatureFixedCost();
    },
    [config, deliveryOptions]
  );

  const calculateFeatureCustomizationCost = useCallback(
    (feature) => {
      if (!feature || !config || !deliveryOptions) {
        return 0;
      }

      return feature.featureCategoryId !== 'custom'
        ? CalculationsService.getTemplateFeatureCustomizationCost(feature, config, deliveryOptions)
        : CalculationsService.getCustomFeatureCustomizationCost(feature, config, deliveryOptions);
    },
    [config, deliveryOptions]
  );

  const calculateFeaturePrice = useCallback(
    (feature) => {
      if (!feature) {
        return 0;
      }

      const fixedCost = calculateFeatureFixedCost(feature);
      const customizationCost = calculateFeatureCustomizationCost(feature);

      return fixedCost + customizationCost;
    },
    [calculateFeatureCustomizationCost, calculateFeatureFixedCost]
  );

  const calculateFeaturesPrice = useCallback(
    (features) => {
      if (!features || features.length === 0 || !config) {
        return 0;
      }

      const featuresCosts = features.map((feature) => {
        return calculateFeaturePrice(feature);
      });

      return featuresCosts.reduce((prev, curr) => prev + curr, 0);
    },
    [calculateFeaturePrice, config]
  );

  const calculateTotalPrice = useCallback(() => {
    if (!selectedFeatures || selectedFeatures.length === 0 || !config) {
      return 0;
    }

    const hostingPrice = deliveryOptions.hostingIncluded ? config?.nozomiCloudPrice ?? 0 : 0;
    const supportPrice = deliveryOptions.supportIncluded ? config?.nozomiCarePrice ?? 0 : 0;
    const roadmapIncluded = deliveryOptions.roadmapIncluded ? config?.roadmapPrice ?? 0 : 0;
    const prototypePrice = CalculationsService.getPrototypePrice(deliveryOptions, config);
    const featuresPrice = CalculationsService.getFeaturesTotalPrice(
      selectedFeatures,
      config,
      deliveryOptions
    );

    return hostingPrice + supportPrice + featuresPrice + roadmapIncluded + prototypePrice;
  }, [config, deliveryOptions, selectedFeatures]);

  const calculateTemplatePrice = useCallback(
    (template) => {
      if (!template || !template.features || template.features.length === 0) {
        return 0;
      }

      return CalculationsService.getTemplateCost(template.features, config, deliveryOptions);
    },
    [config, deliveryOptions]
  );

  const calculateTimelineWeeks = useCallback(() => {
    if (!selectedFeatures || selectedFeatures.length === 0 || !config) {
      return 0;
    }

    return CalculationsService.getTimelineWeeks(selectedFeatures, config, deliveryOptions);
  }, [config, deliveryOptions, selectedFeatures]);

  const totalPrice = useMemo(() => {
    return calculateTotalPrice();
  }, [calculateTotalPrice]);

  const totalTimelineWeeks = useMemo(() => {
    return calculateTimelineWeeks();
  }, [calculateTimelineWeeks]);

  const value = useMemo(
    () => ({
      config,
      totalPrice,
      totalTimelineWeeks,
      deliveryOptions,
      selectedFeatures,
      sendProductRequest,
      collectPriceDependantValues,
      setSelectedFeatures,
      setDeliveryOptions,
      calculateFeatureFixedCost,
      calculateFeatureCustomizationCost,
      calculateFeaturePrice,
      calculateFeaturesPrice,
      calculateTemplatePrice,
      calculateTimelineWeeks,
      calculateTotalPrice,
    }),
    [
      config,
      totalPrice,
      totalTimelineWeeks,
      deliveryOptions,
      selectedFeatures,
      sendProductRequest,
      collectPriceDependantValues,
      setSelectedFeatures,
      setDeliveryOptions,
      calculateFeatureCustomizationCost,
      calculateFeaturePrice,
      calculateFeaturesPrice,
      calculateFeatureFixedCost,
      calculateTemplatePrice,
      calculateTimelineWeeks,
      calculateTotalPrice,
    ]
  );

  return <PricingContext.Provider value={value}>{children}</PricingContext.Provider>;
};

PricingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePricing = () => {
  const value = useContext(PricingContext);
  return value;
};

export default PricingProvider;
