import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';

const DropdownControl = ({ name, control, defaultValue, onChange, ...props }) => {
  const { field } = useController({
    control,
    defaultValue,
    name,
  });

  const handleChange = ({ value }) => {
    field.onChange(value);
    onChange(value);
  };

  return <Dropdown id={name} value={field.value} onChange={handleChange} {...props} />;
};

DropdownControl.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

DropdownControl.defaultProps = {
  defaultValue: '',
  onChange: () => {},
};

export default DropdownControl;
