import { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Cross } from '@assets/icons';
import './SearchBar.module.scss';

// eslint-disable-next-line react/prop-types
const SearchBar = ({ className, placeholder, onChange }) => {
  const inputRef = useRef();

  const handleClick = () => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(inputRef.current, '');

    const inputEvent = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(inputEvent);
  };

  return (
    <form className={className}>
      <div className="relative">
        <div className="flex flex-row items-center bg-gray-6 py-2.5 pr-2">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 ml-4 text-gray-500 dark:text-grady-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            ref={inputRef}
            className="block bg-gray-6 w-full text-sm text-black-classic pl-12 outline-0 rounded-md bg-red"
            placeholder={placeholder}
            onChange={onChange}
            required
          />
          <Cross
            className={clsx(
              'cursor-pointer duration-500 scale-75 ease-in-out',
              !inputRef?.current || inputRef?.current?.value === '' ? 'opacity-0' : 'opacity-100'
            )}
            onClick={handleClick}
          />
        </div>
      </div>
    </form>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
};

SearchBar.defaultProps = {
  className: '',
};

export default SearchBar;
