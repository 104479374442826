import PropTypes from 'prop-types';

const Note = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 13"
    width={width}
    height={height}
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M3.702 1.917a.697.697 0 00-.7.695v7.777c0 .384.314.695.7.695h5.591c.386 0 .699-.31.699-.694V4.952a.692.692 0 00-.198-.484l-2.29-2.34a.701.701 0 00-.5-.21H3.701zm-1.538.695c0-.844.688-1.528 1.538-1.528h3.301c.416 0 .813.167 1.103.463l2.29 2.34c.279.285.435.667.435 1.065v5.438c0 .843-.689 1.527-1.538 1.527H3.702c-.85 0-1.538-.684-1.538-1.527V2.612z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.4 8.167c0 .23.188.417.42.417h3.355c.231 0 .42-.187.42-.417a.418.418 0 00-.42-.416H4.82a.418.418 0 00-.42.416zM4.4 5.945c0 .23.188.417.42.417h1.677c.232 0 .42-.187.42-.417a.418.418 0 00-.42-.417H4.82a.418.418 0 00-.42.417z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M3.702 1.917a.697.697 0 00-.7.695v7.777c0 .384.314.695.7.695h5.591c.386 0 .699-.31.699-.694V4.952a.692.692 0 00-.198-.484l-2.29-2.34a.701.701 0 00-.5-.21H3.701zm-1.538.695c0-.844.688-1.528 1.538-1.528h3.301c.416 0 .813.167 1.103.463l2.29 2.34c.279.285.435.667.435 1.065v5.438c0 .843-.689 1.527-1.538 1.527H3.702c-.85 0-1.538-.684-1.538-1.527V2.612z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M4.4 8.167c0 .23.188.417.42.417h3.355c.231 0 .42-.187.42-.417a.418.418 0 00-.42-.416H4.82a.418.418 0 00-.42.416zM4.4 5.945c0 .23.188.417.42.417h1.677c.232 0 .42-.187.42-.417a.418.418 0 00-.42-.417H4.82a.418.418 0 00-.42.417z"
      clipRule="evenodd"
    />
  </svg>
);

Note.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Note.defaultProps = {
  color: '#FFF',
  width: '0.813rem',
  height: '0.813rem',
};

export default Note;
