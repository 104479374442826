const PlanDelivery = () => {
  return (
    <svg
      width={140}
      height={84}
      viewBox="0 0 140 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.4032 0.000288613L96.4906 0.539711C99.2074 0.572602 101.332 2.9079 101.332 5.85498L101.286 78.6242C101.286 80.6569 99.635 82.3476 97.6484 82.3541L45.0219 82.5844C43.1668 82.591 41.6406 81.0385 41.6406 79.1308V6.21021C41.6406 2.75002 44.2193 -0.0326029 47.4032 0.000288613Z"
        fill="#BDBDBD"
      />
      <path
        d="M92.3975 6.97266H51.1318C49.4017 6.97266 48.0137 8.40015 48.0137 10.0842V70.1376C48.0137 71.8216 49.4017 73.1307 51.1318 73.1307H92.3975C94.1276 73.1307 95.542 71.8216 95.542 70.1376V10.0842C95.542 8.40015 94.1276 6.97266 92.3975 6.97266Z"
        fill="#E0E0E0"
      />
      <path
        d="M69.2446 58.1516V55.5071C66.4883 55.3953 63.7649 54.744 61.8309 53.902C61.1402 53.5994 60.7849 52.8232 60.9889 52.093L61.6598 49.6327C61.9032 48.7315 62.89 48.2644 63.7517 48.6262C65.6726 49.4288 68.0408 50.0669 70.5998 50.0669C73.2508 50.0669 75.2309 49.1656 75.2309 47.3237C75.2309 45.6594 73.7047 44.6726 70.0143 43.6793C64.7516 42.2387 60.9757 39.9428 60.9757 35.3117C60.9757 31.1279 64.0346 27.9374 69.3433 27.0822V24.4378C69.3433 23.5892 70.034 22.8984 70.8826 22.8984H72.4351C73.2837 22.8984 73.9744 23.5892 73.9744 24.4378V26.8125C76.5268 26.9178 78.3819 27.352 79.8554 27.8651C80.612 28.1282 81.0198 28.9439 80.8159 29.7136L80.1975 31.9897C79.9607 32.8646 79.02 33.3514 78.1714 33.029C76.8163 32.5159 74.8954 31.9831 72.4088 31.9831C69.2578 31.9831 68.3631 33.2856 68.3631 34.4565C68.3631 35.8972 70.2511 36.8839 74.3428 38.147C80.1909 39.9034 82.6183 42.3308 82.6183 46.6922C82.6183 50.6984 79.6515 54.2967 73.8889 55.1914V58.1516C73.8889 59.0002 73.1982 59.6909 72.3496 59.6909H70.7971C69.9354 59.6909 69.2446 59.0002 69.2446 58.1516Z"
        fill="#BDBDBD"
      />
      <path
        d="M68.1902 59.1777C68.1902 59.1777 67.2758 56.77 66.2036 56.9871C65.1379 57.2108 63.9669 58.0265 63.9669 58.0265L62.25 60.3486C62.25 60.3486 67.0719 62.2498 68.7625 61.1578C68.7625 61.1578 73.6831 62.0064 79.8996 60.9867C78.9984 59.0856 78.4655 57.1845 78.1498 55.7109C75.0053 57.5002 71.6175 58.8291 68.1902 59.1777Z"
        fill="url(#paint0_linear_661_6770)"
      />
      <path
        d="M94.2665 41.707C94.2665 41.707 87.2212 50.5878 78.1562 55.7123C78.472 57.1858 79.0049 59.0935 79.9061 60.9881C84.6096 60.2184 90.0498 58.3765 94.6612 54.2848L94.2665 41.707Z"
        fill="url(#paint1_linear_661_6770)"
      />
      <path
        d="M0.133066 45.8033L14.1251 80.9907C14.8356 82.78 16.6841 83.8523 18.5918 83.576L55.2856 80.8657L42.0632 43.4483C41.708 42.5208 40.8133 41.9221 39.8266 41.9419C33.393 42.0932 9.96106 42.6655 1.58029 43.4154C0.415934 43.5141 -0.314259 44.7179 0.133066 45.8033Z"
        fill="#BDBDBD"
      />
      <path
        d="M17.3539 81.979L3.37499 46.4759C3.09212 45.7588 3.61181 44.9826 4.38147 44.9629L38.9307 43.6538C39.8188 43.6209 40.6345 44.1537 40.9634 44.9826L53.6201 79.5911L17.3539 81.979Z"
        fill="#E0E0E0"
      />
      <path
        d="M139.869 45.8033L125.877 80.9907C125.167 82.78 123.318 83.8523 121.411 83.576L84.7168 81.0105L97.9326 43.4483C98.2879 42.5208 99.1825 41.9221 100.169 41.9419C106.603 42.0932 130.035 42.6655 138.416 43.4154C139.586 43.5141 140.31 44.7179 139.869 45.8033Z"
        fill="#BDBDBD"
      />
      <path
        d="M122.647 81.979L136.626 46.4759C136.909 45.7588 136.389 44.9826 135.619 44.9629L101.07 43.6538C100.182 43.6209 99.3664 44.1537 99.0375 44.9826L86.3809 79.5911L122.647 81.979Z"
        fill="#E0E0E0"
      />
      <path
        d="M41.4434 42.8867L41.8841 54.6422C43.1274 55.0697 44.3115 55.4052 45.4364 55.6486C46.1403 53.3791 47.6928 50.5965 48.9624 48.5243C44.2918 46.8732 41.4434 42.8867 41.4434 42.8867Z"
        fill="url(#paint2_linear_661_6770)"
      />
      <path
        d="M64.7521 42.6797C58.3449 49.5935 53.1612 50.0013 48.9576 48.5146C47.688 50.5868 46.1355 53.3694 45.4316 55.639C62.5352 59.4083 66.5612 43.6862 66.5612 43.6862L64.7521 42.6797Z"
        fill="url(#paint3_linear_661_6770)"
      />
      <path
        d="M66.0527 41.9502C66.0527 41.9502 66.7895 41.1608 68.1578 41.4042C69.5261 41.6542 70.7365 42.1279 71.5917 41.4042C72.4403 40.6806 72.7429 41.6542 72.7429 41.6542L70.1971 43.5882L66.0527 41.9502Z"
        fill="url(#paint4_linear_661_6770)"
      />
      <path
        d="M64.5293 43.3026C64.5293 43.3026 65.0358 41.1909 66.5554 41.4804C68.0816 41.7698 71.5352 43.6512 72.4299 43.3026C73.3179 42.9539 74.4625 42.1251 75.048 42.4211L74.0152 43.7038C74.0152 43.7038 76.6663 42.1777 77.1202 43.1118L72.7127 45.5918C72.7193 45.5852 66.2725 44.9406 64.5293 43.3026Z"
        fill="url(#paint5_linear_661_6770)"
      />
      <path
        d="M19.2691 81.6158C19.2691 81.6158 17.0851 50.4148 33.0177 40.7118C33.0177 40.7118 37.4713 37.7713 43.0234 43.4681C48.5754 49.165 46.7861 55.0789 42.1287 60.6244C37.4713 66.1699 32.7283 76.0702 33.8532 81.6158H19.2691Z"
        fill="url(#paint6_linear_661_6770)"
      />
      <path
        d="M41.045 32.2109C41.0384 32.5859 35.7363 40.1181 35.7363 40.1181C35.7363 40.1181 36.6902 41.9929 41.2358 43.5191L45.163 35.454L41.045 32.2109Z"
        fill="url(#paint7_linear_661_6770)"
      />
      <path
        d="M38.957 58.0903C38.957 58.0903 49.0218 66.5171 59.8892 62.8398C60.5602 62.6096 61.3035 62.6622 61.9285 62.9911L65.0532 64.6423C66.3623 65.333 67.9871 64.8791 68.7502 63.6095C69.1844 62.8858 69.5528 62.057 69.6317 61.261L63.5204 58.7876L63.9743 58.0376C63.9743 58.0376 60.9878 57.7877 60.205 59.9256C60.205 59.9256 54.5213 62.2938 44.9301 51.7422C44.9301 51.7422 40.5753 55.4392 38.957 58.0903Z"
        fill="url(#paint8_linear_661_6770)"
      />
      <path
        d="M38.4186 58.0459C38.3725 58.0459 38.3331 58.0327 38.2936 58.0064C34.044 55.1317 32.0442 50.5006 32.0245 50.4545C31.9784 50.3427 32.0311 50.2111 32.1429 50.1651C32.2547 50.119 32.3863 50.1717 32.4323 50.2835C32.4521 50.3296 34.4058 54.8488 38.5436 57.6446C38.6423 57.7104 38.6751 57.8551 38.6028 57.9538C38.5567 58.013 38.4909 58.0459 38.4186 58.0459Z"
        fill="url(#paint9_linear_661_6770)"
      />
      <path
        d="M44.7269 51.6433C44.6546 51.6433 44.5822 51.6104 44.5427 51.538C44.5296 51.5183 43.2337 49.4988 40.7668 45.0847C40.0169 43.7427 39.0301 42.8876 37.8329 42.5521C35.8528 41.9995 33.9451 43.0586 33.9253 43.0718C33.8201 43.131 33.682 43.0981 33.6227 42.9862C33.5635 42.881 33.5964 42.7428 33.7083 42.6836C33.7938 42.6376 35.7936 41.5193 37.9513 42.1245C39.2669 42.4929 40.3458 43.4138 41.1549 44.8676C43.6152 49.2685 44.9045 51.2749 44.9177 51.2946C44.9835 51.3999 44.9572 51.538 44.8519 51.6038C44.8124 51.6301 44.773 51.6433 44.7269 51.6433Z"
        fill="url(#paint10_linear_661_6770)"
      />
      <path
        d="M45.2223 27.8984C45.2223 27.8984 47.1103 32.2335 47.2352 34.5096C47.3602 36.7857 47.8536 38.2593 45.2223 38.7198C42.591 39.1802 40.3741 37.8646 39.1702 35.6016C39.1702 35.6016 37.2888 32.1086 37.7099 30.6745C38.1374 29.247 45.2223 27.8984 45.2223 27.8984Z"
        fill="url(#paint11_linear_661_6770)"
      />
      <path
        d="M40.071 33.9703L40.2223 33.9045C40.4131 33.819 40.492 33.5953 40.3999 33.4111C40.0316 32.6941 39.3079 31.0495 40.2421 30.668C41.4459 30.1746 46.2152 29.201 47.248 27.5104C48.2347 25.8856 47.3598 23.7805 46.0441 23.1884C45.9586 23.149 45.8534 23.1819 45.8073 23.2674L45.6955 23.4779C45.6231 23.6226 45.4126 23.5963 45.3666 23.445C45.3205 23.2937 45.1232 23.2674 45.0442 23.3989C44.7285 23.9055 43.8733 24.7278 41.5906 25.1488C38.3409 25.7474 36.1964 27.0631 36.1438 29.3063C36.1372 29.451 35.9859 29.5299 35.8675 29.451C35.6307 29.3063 35.2425 29.2339 34.7294 29.6681C33.8348 30.418 34.2032 32.3652 36.1569 33.9637C36.249 34.0427 36.249 34.1874 36.1503 34.2532C35.7951 34.4834 35.2689 35.036 36.6371 35.7662C38.0515 36.5227 39.1106 36.4635 39.5184 36.3911C39.6171 36.3714 39.6829 36.2793 39.6632 36.174L39.229 34.2005C39.2027 34.0887 39.2882 33.9835 39.4066 33.9835H40.071V33.9703Z"
        fill="#242424"
      />
      <path
        d="M40.1554 34.3844C40.1554 34.3844 39.4384 31.6149 38.1951 32.3912C36.9518 33.1674 38.8398 35.4961 39.8397 35.6014L40.1554 34.3844Z"
        fill="url(#paint12_linear_661_6770)"
      />
      <path
        d="M61.9355 62.9913C61.9355 62.9913 64.4945 65.4911 64.9879 65.1293C65.1655 64.9977 65.6062 64.5307 66.0338 64.0044C66.3364 63.636 66.3693 63.1098 66.1062 62.7085L65.501 61.7744C64.9287 60.8863 63.6985 60.7021 62.896 61.3928C62.5013 61.748 62.1329 62.2546 61.9355 62.9913Z"
        fill="url(#paint13_linear_661_6770)"
      />
      <path
        d="M102.242 81.1573C102.242 81.1573 102.222 69.915 95.6901 58.3108C93.6443 54.6796 92.9207 50.4563 93.5588 46.3383L94.2758 41.7138C94.2758 41.7138 103.249 38.6812 113.188 41.5888C113.188 41.5888 126.977 52.9692 117.76 82.0717L102.242 81.1573Z"
        fill="url(#paint14_linear_661_6770)"
      />
      <path
        d="M95.7949 35.8031L98.1236 41.3881C98.2684 41.7433 98.5907 41.9933 98.9657 42.0591C100.308 42.2893 103.551 42.6511 105.682 41.2499C105.939 41.0855 105.998 40.7368 105.827 40.4869L101.531 34.3164L95.7949 35.8031Z"
        fill="url(#paint15_linear_661_6770)"
      />
      <path
        d="M101.531 62.0607C101.209 62.1462 101.051 62.508 101.202 62.7975L107.932 75.6975C108.044 75.9146 108.294 76.0199 108.531 75.9541L130.542 69.5994C130.818 69.5205 130.976 69.2311 130.897 68.9613L127.108 55.8376C127.029 55.5679 126.753 55.41 126.483 55.4824L101.531 62.0607Z"
        fill="#F2F0ED"
      />
      <path
        d="M102.952 60.5444C102.636 60.6168 102.459 60.9523 102.571 61.2549L107.958 75.885C108.057 76.1482 108.346 76.2995 108.616 76.2205L130.561 69.8856C130.838 69.8067 131.002 69.5304 130.943 69.2541L127.904 55.1699C127.838 54.8739 127.542 54.6897 127.253 54.7555L102.952 60.5444Z"
        fill="#E0E0E0"
      />
      <path
        d="M104.868 59.0723C104.486 59.1315 104.23 59.5065 104.315 59.8814L107.973 75.676C108.058 76.0509 108.44 76.2746 108.808 76.1693L130.418 69.9265C130.767 69.8278 130.971 69.466 130.879 69.1174L127.556 56.2239C127.471 55.895 127.149 55.6779 126.807 55.7305L104.868 59.0723Z"
        fill="#828282"
      />
      <path
        d="M122.356 65.092C124.034 52.4222 114.272 42.6797 114.272 42.6797L108.463 49.4553C112.87 54.9548 114.969 59.9938 115.929 63.9474C117.192 63.579 120.251 62.9935 122.356 65.092Z"
        fill="url(#paint16_linear_661_6770)"
      />
      <path
        d="M115.924 64.0928C115.858 64.0928 115.799 64.0468 115.779 63.981C114.588 59.0736 112.089 54.2188 108.345 49.5482C108.293 49.4824 108.306 49.3903 108.365 49.3377C108.431 49.285 108.523 49.2982 108.576 49.3574C112.352 54.0609 114.871 58.9551 116.068 63.9086C116.088 63.9876 116.042 64.0665 115.957 64.0862C115.95 64.0928 115.937 64.0928 115.924 64.0928Z"
        fill="url(#paint17_linear_661_6770)"
      />
      <path
        d="M115.924 63.9506C116.812 67.6279 116.72 70.371 116.589 71.6801C116.536 72.1735 116.141 72.5616 115.648 72.5945C115.082 72.634 114.319 72.6866 113.635 72.7261C112.885 72.7721 112.05 72.2919 111.491 71.8972C111.208 71.6933 110.839 72.009 110.997 72.3248C111.412 73.1405 112.471 73.8641 113.201 74.2917C113.464 74.4496 113.418 74.8443 113.122 74.9298L110.004 75.8113C115.128 80.6332 118.51 75.239 118.51 75.239C120.773 71.7459 121.937 68.3318 122.365 65.1018C120.253 62.9968 117.194 63.5822 115.924 63.9506Z"
        fill="url(#paint18_linear_661_6770)"
      />
      <path
        d="M98.1988 27.2567C98.1001 27.3488 94.0742 35.2494 94.0742 35.2494L98.633 37.4531L103.56 30.5261C103.567 30.5327 99.8368 25.7174 98.1988 27.2567Z"
        fill="url(#paint19_linear_661_6770)"
      />
      <path
        d="M101.496 31.3616L99.2195 35.1573C99.1866 35.2165 99.1472 35.2691 99.0945 35.3152C98.8248 35.5651 97.8973 36.3019 97.1802 35.302C96.5685 34.4534 95.9961 33.6772 95.3844 33.3614C95.0291 33.1772 94.5818 33.3219 94.4042 33.6837C94.0292 34.4271 93.3056 35.9401 92.8451 37.3742C92.7333 37.736 92.9175 38.1175 93.2661 38.2622C94.1871 38.6372 96.0159 39.3148 97.6341 39.5384C97.8973 39.5779 98.167 39.4661 98.3249 39.249C99.0222 38.2951 100.989 35.6243 101.653 34.9599C102.278 34.3416 103.423 34.5718 103.943 34.7231C104.14 34.7823 104.351 34.7494 104.522 34.6442C105.048 34.3284 105.962 33.539 105.127 32.2036C104.916 31.8747 105.022 31.4274 105.351 31.2169C105.936 30.8288 106.574 30.1051 105.969 28.9342C105.357 27.7435 104.259 27.6383 103.509 27.7304C103.18 27.7698 102.844 27.5988 102.713 27.2896C102.653 27.1515 102.542 27.0331 102.338 26.9936C101.943 26.9147 101.686 26.5594 101.765 26.1647C101.897 25.4937 101.798 24.6188 100.726 24.0268C98.759 22.9414 97.0355 24.7109 96.5948 26.1976C96.154 27.6843 98.3578 28.7368 99.7392 28.9145C100.759 29.046 101.311 30.1512 101.535 30.7301C101.627 30.934 101.607 31.1708 101.496 31.3616Z"
        fill="#242424"
      />
      <path
        d="M100.799 36.3404C100.799 36.3404 101.674 33.7814 102.792 33.8735C103.91 33.9656 104.792 35.0839 103.404 36.3404C101.68 37.8994 100.799 36.3404 100.799 36.3404Z"
        fill="url(#paint20_linear_661_6770)"
      />
      <path
        d="M67.7574 82.4326C67.7574 83.0904 67.2246 83.6233 66.5667 83.6233H18.3148C17.3149 83.6233 16.5124 82.8141 16.5124 81.8208L15.7559 80.9393L65.8628 80.5117C66.9088 80.5183 67.7574 81.3801 67.7574 82.4326Z"
        fill="#828282"
      />
      <path
        d="M72.2441 82.4326C72.2441 83.0904 72.777 83.6233 73.4348 83.6233H121.693C122.693 83.6233 123.496 82.8141 123.496 81.8208L124.252 80.9393L74.1453 80.5117C73.0862 80.5183 72.2441 81.3801 72.2441 82.4326Z"
        fill="#828282"
      />
      <defs>
        <linearGradient
          id="paint0_linear_661_6770"
          x1="79.1851"
          y1="59.5132"
          x2="57.6716"
          y2="56.6125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_661_6770"
          x1="86.0562"
          y1="44.7473"
          x2="87.0943"
          y2="71.2644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7062" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_661_6770"
          x1="49.7671"
          y1="42.8057"
          x2="40.5191"
          y2="52.2424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7062" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_661_6770"
          x1="55.9997"
          y1="13.6235"
          x2="55.9997"
          y2="39.8507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_661_6770"
          x1="70.4144"
          y1="33.7978"
          x2="68.118"
          y2="51.8064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_661_6770"
          x1="70.8265"
          y1="13.6214"
          x2="70.8265"
          y2="39.8486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_661_6770"
          x1="51.5998"
          y1="36.0537"
          x2="2.34319"
          y2="101.022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7062" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_661_6770"
          x1="43.3074"
          y1="34.0018"
          x2="34.3636"
          y2="48.5052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="0.531" stopColor="#FEA6A3" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_661_6770"
          x1="69.3584"
          y1="26.4048"
          x2="57.6348"
          y2="52.5111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_661_6770"
          x1="32.0057"
          y1="54.0979"
          x2="38.6406"
          y2="54.0979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1473D" />
          <stop offset="1" stopColor="#E9605A" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_661_6770"
          x1="33.5964"
          y1="46.7933"
          x2="44.9527"
          y2="46.7933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1473D" />
          <stop offset="1" stopColor="#E9605A" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_661_6770"
          x1="51.5288"
          y1="24.3727"
          x2="38.4757"
          y2="38.3927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_661_6770"
          x1="49.6117"
          y1="22.589"
          x2="36.5585"
          y2="36.609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_661_6770"
          x1="61.0953"
          y1="55.6926"
          x2="66.8967"
          y2="69.8335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_661_6770"
          x1="94.2901"
          y1="54.2236"
          x2="133.641"
          y2="75.1729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7062" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_661_6770"
          x1="100.859"
          y1="24.1363"
          x2="100.859"
          y2="49.7592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_661_6770"
          x1="99.2599"
          y1="44.8828"
          x2="138.611"
          y2="65.8322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7062" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_661_6770"
          x1="106.148"
          y1="53.4829"
          x2="118.238"
          y2="59.9194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1473D" />
          <stop offset="1" stopColor="#E9605A" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_661_6770"
          x1="118.944"
          y1="65.6921"
          x2="109.743"
          y2="83.3858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_661_6770"
          x1="107.79"
          y1="32.8153"
          x2="95.3407"
          y2="31.9692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_661_6770"
          x1="101.979"
          y1="46.9106"
          x2="102.481"
          y2="35.2287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FEB3B1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlanDelivery;
