const ValidateIdea = () => {
  return (
    <svg
      width={101}
      height={101}
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_352_6482)">
        <path
          d="M91.3609 23.9875C91.0256 23.4299 90.6782 22.8825 90.3125 22.3472C85.4645 15.2247 77.3946 10.3484 69.553 8.14055C66.117 7.17095 62.5901 6.66393 59.0692 6.53465C35.9725 5.69635 27.4865 24.7389 20.0448 29.6495C12.6031 34.5601 1.18206 42.9472 2.61626 60.5656C3.70302 73.9623 11.3245 86.7711 25.2423 92.5988C26.7755 93.2432 28.3854 93.8027 30.0721 94.2653C33.2092 95.1278 36.6088 95.6712 40.2731 95.8328C50.7953 96.3014 59.3399 93.7643 67.6966 87.3771C69.6399 85.8924 71.571 84.2178 74.0919 84.034C75.9241 83.9007 77.6613 84.5592 75.7463 86.3307C73.496 88.4113 76.0917 92.5059 81.8083 89.6617C91.3528 84.9107 95.2797 74.6612 97.13 64.24C99.4005 51.4494 98.2996 35.5398 91.3609 23.9875Z"
          fill="#585550"
          fillOpacity="0.4"
        />
        <path
          opacity="0.7"
          d="M91.3609 23.9875C91.0256 23.4299 90.6782 22.8825 90.3125 22.3472C85.4645 15.2247 77.3947 10.3484 69.553 8.14055C66.117 7.17095 62.5901 6.66393 59.0692 6.53465C35.9725 5.69635 27.4865 24.7389 20.0448 29.6495C12.6031 34.5601 1.18206 42.9472 2.61626 60.5656C3.70302 73.9623 11.3245 86.7711 25.2423 92.5988C26.7755 93.2432 28.3854 93.8027 30.0721 94.2653C33.2092 95.1278 36.6088 95.6712 40.2731 95.8328C50.7953 96.3014 59.3399 93.7643 67.6966 87.3771C69.6399 85.8924 71.571 84.2178 74.0919 84.034C75.9241 83.9007 77.6613 84.5592 75.7463 86.3307C73.4961 88.4113 76.0918 92.5059 81.8083 89.6617C91.3529 84.9107 95.2797 74.6612 97.1301 64.24C99.4005 51.4494 98.2996 35.5398 91.3609 23.9875Z"
          fill="white"
        />
        <path
          d="M87.6472 33.6412C87.5442 33.516 87.4533 33.3826 87.3644 33.2453C87.0634 32.7827 85.2353 30.4536 82.2538 30.2395C77.6058 29.9082 76.3433 33.6392 75.5231 34.2937C74.703 34.9481 72.7376 33.8028 71.4266 34.2937C70.1156 34.7845 70.1156 36.2591 69.1319 36.5884C68.1481 36.9156 67.5098 36.0369 65.8554 36.2611C64.7262 36.4126 64.3808 37.0813 64.0536 37.5721C63.7264 38.063 62.7426 37.8994 61.7589 37.8994C60.7751 37.8994 59.957 39.0467 59.957 39.0467H95.3515C95.3515 39.0467 94.3677 35.6046 92.8931 34.6229C91.4185 33.6392 90.2712 35.2774 89.2874 34.9502C88.6006 34.7219 87.9663 34.0331 87.6472 33.6412Z"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.2566 9.16464C37.1535 9.0394 37.0626 8.90608 36.9738 8.76872C36.6728 8.30614 34.8447 5.97708 31.8632 5.76296C27.2151 5.43168 25.9526 9.16262 25.1325 9.8171C24.3124 10.4716 22.3469 9.32624 21.036 9.8171C19.725 10.308 19.725 11.7826 18.7412 12.1118C17.7575 12.4391 17.1192 11.5604 15.4648 11.7846C14.3356 11.9361 13.9902 12.6047 13.663 13.0956C13.3357 13.5864 12.352 13.4228 11.3682 13.4228C10.3845 13.4228 9.56641 14.5702 9.56641 14.5702H44.9608C44.9608 14.5702 43.9771 11.1281 42.5025 10.1464C41.0279 9.16262 39.8805 10.8008 38.8968 10.4736C38.21 10.2453 37.5737 9.55652 37.2566 9.16464Z"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.117 39.038C92.117 39.038 90.4848 36.3413 88.4971 35.9151C86.5094 35.4889 85.8711 36.909 85.2328 37.1918C84.5945 37.4766 82.7502 36.2686 81.613 36.4827C80.4777 36.6948 80.1929 37.5473 79.2011 37.8301C78.2072 38.1149 77.8537 37.618 76.8599 37.7594C75.8661 37.9008 72.3896 39.0441 72.3896 39.0441L92.117 39.038Z"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.969 32.0367H60.0875V19.4844H32.969L27.5635 25.7141L32.969 32.0367Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.6675 100.993H60.7646L61.6656 1.5L62.6675 2.5201V100.993Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.6646 1.5H59.7617V100.991H61.6646V1.5Z"
          fill="white"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.3148 18.4781H62.1963V5.92578H89.3148L94.7203 12.1555L89.3148 18.4781Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.0264 15.5009V9.61058H67.9639V8.45312H71.6463V9.61058H70.5939V15.5029H69.0264V15.5009Z"
          fill="#242424"
        />
        <path
          d="M72.2646 15.5029V8.45312H74.2059C74.6806 8.45312 75.0927 8.50565 75.4421 8.61069C75.7896 8.71573 76.0623 8.90157 76.2562 9.17225C76.4501 9.44293 76.5471 9.82269 76.5471 10.3176C76.5471 10.6085 76.5228 10.865 76.4784 11.0912C76.4319 11.3175 76.3471 11.5134 76.2218 11.6791C76.0966 11.8447 75.9188 11.9821 75.6865 12.0932L76.6521 15.5049H75.0421L74.2685 12.3457H73.8241V15.5049H72.2646V15.5029ZM73.8221 11.4488H74.2564C74.4766 11.4488 74.6483 11.4104 74.7695 11.3316C74.8907 11.2528 74.9775 11.1397 75.026 10.9923C75.0745 10.8448 75.1007 10.665 75.1007 10.457C75.1007 10.156 75.0462 9.91964 74.9351 9.75198C74.824 9.58433 74.622 9.49949 74.3251 9.49949H73.8201V11.4488H73.8221Z"
          fill="#242424"
        />
        <path
          d="M79.4774 15.608C78.9381 15.608 78.5159 15.5191 78.2109 15.3433C77.9059 15.1656 77.6938 14.909 77.5705 14.5697C77.4493 14.2303 77.3867 13.8142 77.3867 13.3213V8.45312H78.928V13.5536C78.928 13.6991 78.9401 13.8445 78.9623 13.9879C78.9845 14.1334 79.0371 14.2525 79.1138 14.3455C79.1926 14.4384 79.3118 14.4848 79.4754 14.4848C79.6431 14.4848 79.7663 14.4384 79.841 14.3455C79.9158 14.2525 79.9642 14.1334 79.9844 13.9879C80.0046 13.8425 80.0147 13.697 80.0147 13.5536V8.45312H81.5641V13.3173C81.5641 13.8102 81.5015 14.2263 81.3762 14.5656C81.251 14.905 81.0389 15.1636 80.7359 15.3393C80.4389 15.5191 80.0168 15.608 79.4774 15.608Z"
          fill="#242424"
        />
        <path
          d="M82.5586 15.5029V8.45312H85.726V9.51565H84.116V11.265H85.3442V12.3436H84.116V14.4505H85.7441V15.5029H82.5586Z"
          fill="#242424"
        />
        <path
          d="M36.8955 29.0303V21.9805H39.9417V23.0329H38.4529V24.8085H39.7498V25.8972H38.4529V29.0303H36.8955Z"
          fill="#242424"
        />
        <path
          d="M40.0791 29.0303L41.4103 21.9805H43.1859L44.5009 29.0303H43.0647L42.8303 27.5415H41.7941L41.5497 29.0303H40.0791ZM41.9335 26.6103H42.6829L42.3092 23.8611L41.9335 26.6103Z"
          fill="#242424"
        />
        <path d="M45.1445 29.0303V21.9805H46.7019V27.9778H48.32V29.0303H45.1445Z" fill="#242424" />
        <path
          d="M50.8035 29.1326C50.3631 29.1326 49.9934 29.0538 49.6985 28.8983C49.4036 28.7407 49.1794 28.5003 49.0279 28.1711C48.8764 27.8438 48.7936 27.4176 48.7754 26.8965L50.1066 26.6965C50.1126 26.9975 50.1409 27.2419 50.1894 27.4277C50.2379 27.6136 50.3086 27.7469 50.3975 27.8277C50.4884 27.9085 50.5954 27.9489 50.7247 27.9489C50.8863 27.9489 50.9933 27.8943 51.0418 27.7832C51.0903 27.6721 51.1166 27.557 51.1166 27.4358C51.1166 27.1449 51.0479 26.9005 50.9085 26.7005C50.7691 26.5005 50.5813 26.2985 50.3429 26.0965L49.7329 25.5653C49.4662 25.339 49.242 25.0825 49.0582 24.7957C48.8744 24.5088 48.7835 24.1533 48.7835 23.7291C48.7835 23.1312 48.9612 22.6747 49.3147 22.3575C49.6682 22.0404 50.151 21.8828 50.759 21.8828C51.1368 21.8828 51.4398 21.9475 51.668 22.0747C51.8963 22.202 52.072 22.3696 52.1892 22.5757C52.3084 22.7817 52.3892 22.9999 52.4336 23.2281C52.4781 23.4584 52.5023 23.6766 52.5083 23.8846L51.1691 24.0503C51.163 23.8422 51.1509 23.6604 51.1307 23.5069C51.1105 23.3534 51.0681 23.2342 51.0055 23.1494C50.9408 23.0645 50.8439 23.0241 50.7105 23.0241C50.5651 23.0241 50.4601 23.0847 50.3934 23.2059C50.3267 23.3271 50.2924 23.4503 50.2924 23.5715C50.2924 23.8321 50.355 24.0462 50.4803 24.2119C50.6055 24.3775 50.7691 24.5492 50.9711 24.729L51.5549 25.2421C51.8619 25.5027 52.1225 25.7996 52.3346 26.1309C52.5467 26.4622 52.6518 26.8702 52.6518 27.357C52.6518 27.6883 52.577 27.9873 52.4255 28.2579C52.274 28.5286 52.0619 28.7407 51.7852 28.8983C51.5064 29.0538 51.1792 29.1326 50.8035 29.1326Z"
          fill="#242424"
        />
        <path
          d="M53.2656 29.0303V21.9805H56.433V23.043H54.823V24.7923H56.0512V25.871H54.823V27.9778H56.4512V29.0303H53.2656Z"
          fill="#242424"
        />
        <path
          d="M28.9373 62.2422C28.9373 62.2422 24.9639 62.8664 24.7862 63.0906C24.6084 63.3148 23.6711 64.0279 23.6711 64.0279C23.6711 64.0279 17.6455 66.2155 17.5546 66.5286C17.4657 66.8417 19.518 84.743 19.518 84.743L18.4918 100.992H48.0444L47.5091 93.8491L51.6158 66.5731L41.8834 62.422L28.9373 62.2422Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.3203 58.3595C29.3203 58.3595 29.8718 61.3672 29.8718 62.7206C29.8718 64.074 33.231 67.0313 33.6815 66.9828C34.132 66.9344 39.9475 61.3167 39.9475 61.3167C39.9475 61.3167 39.1961 58.2585 39.1961 55.7011C39.1961 53.1438 39.1961 50.6875 39.1961 50.6875C39.1961 50.6875 37.6912 51.4389 37.5417 52.1924C37.3922 52.9438 37.3922 53.4953 36.9903 54.0468C36.5903 54.6002 29.3203 58.3595 29.3203 58.3595Z"
          fill="white"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.3555 54.6953L31.4268 60.1816L30.0693 57.3233L37.3555 54.6953Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2052 46.5156C23.2052 46.5156 22.4376 46.8227 22.5528 47.0913C22.6679 47.36 24.5869 48.5114 24.5869 48.5114L23.282 47.2832L23.2052 46.5156Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3753 43.3139C24.3753 43.3139 23.7268 43.833 23.1208 41.9726C22.5148 40.1122 22.5593 39.0739 22.8178 39.4193C23.0784 39.7647 23.2077 40.1122 23.2077 40.1122C23.2077 40.1122 22.9916 39.9385 23.8137 38.2093C24.6358 36.4782 25.7186 35.5268 25.5448 36.1752C25.3711 36.8236 25.1994 37.3428 25.1994 37.3428C25.1994 37.3428 25.46 36.0015 26.8861 35.3955C28.3143 34.7895 29.1364 34.7026 28.9627 35.0056C28.789 35.3086 28.3143 35.654 28.3143 35.654C28.3143 35.654 31.9927 35.048 33.6794 35.3935C35.3661 35.7389 33.7218 36.3025 33.7218 36.3025C33.7218 36.3025 36.0589 36.6479 37.8749 37.773C39.6929 38.8982 38.4809 38.985 38.4809 38.985L38.6546 39.9364C38.6546 39.9364 39.5192 41.6676 39.8222 43.4411C40.1252 45.2147 39.6929 46.4691 39.6929 46.6004C39.6929 46.7297 38.6546 47.5518 37.7901 47.5963C36.9255 47.6387 36.9679 46.861 36.8387 46.5136C36.7094 46.1681 36.4064 43.831 35.9741 43.3543C35.5418 42.8776 34.1561 42.316 34.1561 41.9706C34.1561 41.6252 34.243 40.8879 33.8531 40.7162C33.4632 40.5424 28.7465 39.2456 27.4921 40.3263C26.2377 41.409 25.8478 42.0999 25.8478 42.0999L24.3753 43.3139Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2394 40.6314C25.2394 40.6314 24.1142 42.3625 24.2011 43.7462C24.2879 45.1299 24.6334 50.7132 25.2818 53.0483C25.9302 55.3855 28.1805 58.6296 29.5218 58.674C30.863 58.7164 36.7049 55.4723 37.3533 54.6926C38.0017 53.9129 38.0017 52.6585 38.0017 52.6585L38.0886 52.0525C38.0886 52.0525 39.2137 51.5333 39.9066 50.8849C40.5994 50.2365 41.1186 48.5478 40.9024 47.4671C40.6863 46.3864 40.0379 46.1258 40.0379 46.1258C40.0379 46.1258 39.7773 45.9096 39.2582 46.0834C38.739 46.2571 38.2199 46.6449 38.2199 46.6449C38.2199 46.6449 38.3492 46.7742 38.133 46.9479C37.9169 47.1216 37.527 47.1641 37.4846 47.0348C37.4422 46.9055 37.4422 46.0834 37.2685 45.6935C37.0947 45.3036 36.8362 45.0451 36.923 44.8714C37.0099 44.6976 37.2685 44.2654 37.1392 43.8331C37.0099 43.4008 35.0182 42.2757 35.1919 41.7121C35.3656 41.1505 36.62 41.0192 35.9716 40.3708C35.3232 39.7224 28.8329 38.7689 27.7502 38.9427C26.6675 39.1164 25.4555 40.1567 25.2394 40.6314Z"
          fill="white"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.7514 45.5625C27.7514 45.5625 26.7556 49.1965 27.0161 49.4126C27.2767 49.6288 28.2706 50.1055 28.2706 50.1055"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.3966 44.7319C32.3966 45.0511 32.1966 45.3076 31.9522 45.3076C31.7058 45.3076 31.5078 45.0491 31.5078 44.7319C31.5078 44.4128 31.7078 44.1562 31.9522 44.1562C32.1966 44.1562 32.3966 44.4148 32.3966 44.7319Z"
          fill="#242424"
        />
        <path
          d="M25.3828 46.8861C25.3828 46.8861 25.9424 46.064 26.7322 46.1286"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.259 44.5803C27.259 44.5803 26.4025 44.3177 25.7117 44.6127C25.0209 44.9096 24.6573 45.2712 24.8229 45.4692C24.9865 45.6671 25.8773 45.0086 26.2712 45.0086C26.6672 45.0086 27.7196 45.0086 27.5883 44.8106C27.457 44.6127 27.259 44.5803 27.259 44.5803Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.7276 43.3668C29.7276 43.3668 30.9779 42.28 31.7698 42.0821C32.5616 41.8841 33.6787 42.575 33.6464 42.8073C33.614 43.0375 32.198 42.6093 31.9334 42.7406C31.6708 42.8719 30.4527 43.7284 29.8912 43.7607C29.3337 43.795 29.6306 43.4981 29.7276 43.3668Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.4893 53.1074C27.4893 53.1074 28.774 52.2509 29.3335 52.1519C29.8931 52.053 31.1111 52.1196 31.1111 52.1196"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.9062 49.3579C28.9062 49.3579 29.795 49.6872 29.993 49.6205C30.191 49.5539 30.191 49.16 29.9607 48.9297"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.3504 46.6656C27.1202 48.1624 26.3505 49.1603 25.2597 49.1603C24.1689 49.1603 23.2842 48.1544 23.2842 46.9161C23.2842 45.6758 24.1689 44.6719 25.2597 44.6719C25.9425 44.6719 26.9565 44.688 27.3121 45.286C27.5242 45.6415 27.4211 46.2051 27.3504 46.6656Z"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8447 46.7819C30.9295 47.8384 32.0607 47.6162 32.9313 46.9597C33.8039 46.3032 34.2342 44.5498 33.4888 43.558C32.7434 42.5682 31.1032 42.7157 30.2305 43.3722C29.6851 43.7822 28.8852 44.4064 28.96 45.0973C29.0064 45.5134 29.5114 46.4567 29.8447 46.7819Z"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.9628 44.6699C27.466 44.2861 27.3125 45.284 27.3125 45.284"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.335 43.2505C33.335 43.2505 34.2177 43.0586 34.3712 43.1353C34.5247 43.2121 39.2576 46.0825 39.2576 46.0825L38.7061 46.2057L34.448 43.5959L33.7188 43.8262L33.335 43.2505Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2031 62.7898L29.1746 67.6439L30.827 63.4463L28.9686 60.7617L27.2031 62.7898Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.8936 94.4607C46.536 94.3416 46.4189 93.6426 46.4189 93.6426L22.6758 96.9756L25.6533 87.514C25.6533 87.514 29.2347 87.5907 32.5738 88.0573C35.9108 88.524 46.6633 87.1645 47.5561 87.009C48.449 86.8534 50.5053 87.0857 50.778 87.2029C51.0507 87.32 51.5537 87.514 51.8648 88.328C51.9617 88.5846 52.7576 90.6955 52.9899 91.1217C53.2222 91.5479 53.2606 92.4024 53.0283 92.7518C52.796 93.1013 52.2526 93.6063 52.2526 93.6063C52.2526 93.6063 50.8164 95.4687 50.5457 95.703C50.273 95.9353 47.8288 96.2848 47.8288 96.2848C47.8288 96.2848 46.5481 95.5859 46.3926 95.4707C46.2371 95.3536 46.7037 94.6163 46.7037 94.6163L46.8936 94.4607Z"
          fill="white"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.213 91.043L25.1687 96.6161L23.4961 96.8242L26.213 91.043Z"
          fill="#242424"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.8838 92.832C49.8838 92.832 50.7766 94.9288 50.7766 95.2379"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.4268 90.1914C50.4268 90.1914 52.4064 93.4133 52.2508 93.6072"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.1631 88.2891L52.6377 90.4626"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.6267 67.5887C49.6267 67.5887 47.8875 68.5886 46.932 70.544C45.9765 72.4993 41.9769 81.1954 41.9769 81.1954C41.9769 81.1954 38.6722 91.9762 39.4115 93.2811C40.1509 94.586 44.0192 97.107 44.8453 97.107C45.6715 97.107 46.7583 95.4546 47.6269 94.1517C48.4955 92.8468 52.3214 82.8054 53.7131 78.5452C55.1049 74.285 54.7575 69.938 54.0182 68.5462C53.2788 67.1544 52.1497 66.3282 51.0629 66.6777"
          fill="#E5796E"
        />
        <path
          d="M49.6267 67.5887C49.6267 67.5887 47.8875 68.5886 46.932 70.544C45.9765 72.4993 41.9769 81.1954 41.9769 81.1954C41.9769 81.1954 38.6722 91.9762 39.4115 93.2811C40.1509 94.586 44.0192 97.107 44.8453 97.107C45.6715 97.107 46.7583 95.4546 47.6269 94.1517C48.4955 92.8468 52.3214 82.8054 53.7131 78.5452C55.1049 74.285 54.7575 69.938 54.0182 68.5462C53.2788 67.1544 52.1497 66.3282 51.0629 66.6777"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.9327 90.238C35.9327 90.238 34.6278 92.3691 35.1065 93.2822C35.5853 94.1952 37.7144 96.4132 38.8456 97.2818C39.9768 98.1504 42.2371 97.8029 43.5845 97.0212C44.9318 96.2395 45.8449 94.1528 46.7155 92.0217C47.5841 89.8906 48.6284 86.6303 47.976 85.283C47.3235 83.9356 45.0631 82.152 44.4551 82.1096C43.843 82.0651 35.9327 90.238 35.9327 90.238Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.533 65.3118C35.533 65.3118 35.1128 58.6619 35.0421 58.2418C34.9714 57.8216 32.4525 57.1227 31.8929 57.0863C31.3334 57.052 27.3782 59.2558 27.0631 59.3972C26.748 59.5366 26.4672 59.7467 26.5722 60.3062C26.6773 60.8658 27.1318 62.4757 27.2025 62.7908C27.2732 63.1059 28.7074 63.6998 28.7074 63.6998C28.7074 63.6998 29.477 66.1501 29.9315 66.7803C30.386 67.4106 31.6808 68.3559 31.6808 68.3559C31.6808 68.3559 33.3635 79.5649 33.7271 83.6049C34.0907 87.6449 34.6159 88.8973 35.3027 90.2305C35.9895 91.5637 38.3731 94.8361 39.2215 95.0785C40.0699 95.3209 41.3223 93.3009 43.3423 90.1901C45.3623 87.0793 45.8875 84.2917 45.1603 83.1201C44.4331 81.9485 41.3223 77.9489 41.2415 77.8277C41.1607 77.7065 36.5955 68.0509 36.1511 67.1621C35.7087 66.2753 35.533 65.3118 35.533 65.3118Z"
          fill="white"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.7065 63.702L28.0723 61.0234"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.2807 61.9414C28.2807 61.9414 28.0323 62.2767 27.5879 62.3171L28.2807 61.9414Z"
          fill="white"
        />
        <path
          d="M28.2807 61.9414C28.2807 61.9414 28.0323 62.2767 27.5879 62.3171"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.1395 61.3562C28.1395 61.3562 27.788 61.4269 27.4365 60.9805L28.1395 61.3562Z"
          fill="white"
        />
        <path
          d="M28.1395 61.3562C28.1395 61.3562 27.788 61.4269 27.4365 60.9805"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.8115 63.9857L37.1508 67.2884L42.5523 62.4242L40.8555 59.3438L35.8115 63.9857Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5551 66.5312C17.5551 66.5312 16.2623 67.7594 15.9492 69.133C15.6361 70.5086 12.5132 87.5049 12.4506 90.943C12.388 94.379 14.513 95.3789 15.4503 96.0051C16.3876 96.6293 18.6378 96.4434 18.6378 96.4434L21.8254 86.5696C21.8254 86.5696 22.2011 82.0711 21.8254 78.3826C21.4497 74.6961 19.5751 69.2583 19.5751 69.2583"
          fill="#E5796E"
        />
        <path
          d="M17.5551 66.5312C17.5551 66.5312 16.2623 67.7594 15.9492 69.133C15.6361 70.5086 12.5132 87.5049 12.4506 90.943C12.388 94.379 14.513 95.3789 15.4503 96.0051C16.3876 96.6293 18.6378 96.4434 18.6378 96.4434L21.8254 86.5696C21.8254 86.5696 22.2011 82.0711 21.8254 78.3826C21.4497 74.6961 19.5751 69.2583 19.5751 69.2583"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.072 86.07C21.072 86.07 17.7592 90.2574 17.4481 92.944C17.135 95.6306 17.6986 96.8184 18.2601 97.0689C18.8217 97.3194 22.5728 98.1314 23.0092 97.9435C23.4475 97.7557 27.134 89.3828 27.3845 88.5707C27.635 87.7587 27.6976 87.0093 27.0714 86.8214C26.4472 86.6315 21.3225 85.3185 21.072 86.07Z"
          fill="#E5796E"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3779 86.3218C16.3779 86.3218 17.2162 85.2148 19.1231 85.2148C21.03 85.2148 21.6784 85.635 21.6784 85.635"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9502 84.2979C16.9502 84.2979 19.354 83.8777 20.9559 85.0231"
          stroke="#242424"
          strokeWidth="0.652"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_352_6482">
          <rect width="101" height="101" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ValidateIdea;
