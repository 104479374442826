import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@components/Form';

const Base = ({ item, price, onClick }) => {
  const featuresLabel = item.features?.map((feature) => feature.name).join(', ');

  const handleClick = () => onClick(item.id);
  const defaultImage = 'https://i.postimg.cc/x1sBzBh7/image-9.png';

  return (
    <article className="px-5 py-6 flex flex-col bg-white rounded-xl w-96">
      <div className="flex flex-row items-center mb-5">
        <img
          src={item.pictureUrl ?? defaultImage}
          alt={item.name}
          className="object-cover w-8 h-8 rounded-lg mr-2.5"
        />
        <p className="font-bold text-black-brand-1 text-[1.5633rem] leading-[1.875rem] truncate">
          {item.name}
        </p>
      </div>
      <div className="mb-5 h-[13.5rem] overflow-hidden rounded-xl">
        <img
          src={item.previewUrl ?? defaultImage}
          alt={item.name}
          className="object-cover object-top h-full w-full"
        />
      </div>
      <p className="font-bold text-black-brand-1 text-base leading-[1.188rem] mb-2">Features</p>
      <p className="font-normal text-gray-2 text-sm leading-[1.063rem] line-clamp-2">
        {featuresLabel}
      </p>
      <div className="bg-gray-6 my-6 max-h-px" />
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center opacity-0">
          <p className="text-sm leading-[1.063rem] font-medium mr-[0.625rem]">Price</p>
          <span className="font-bold">
            {price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </span>
        </div>
        <Button
          onClick={handleClick}
          type="button"
          className="bg-red-400 text-white-default justify-center font-bold text-sm leading-4 px-2 py-3 rounded-2lg w-40"
        >
          View Details
        </Button>
      </div>
    </article>
  );
};

Base.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    pictureUrl: PropTypes.string,
    previewUrl: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
};

Base.defaultProps = {
  item: {
    description: '',
    pictureUrl: null,
    previewUrl: null,
    features: [],
  },
};

export default React.memo(Base);
