const BuildProduct = () => {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.9056 9.66982C60.9996 11.2978 70.1976 15.7998 73.9576 23.6058C79.6396 35.3998 89.8616 35.2818 92.4876 57.6158C92.8976 61.0978 92.9236 64.3738 92.6176 67.4338C92.4536 69.0798 92.1916 70.6638 91.8396 72.1838C88.6656 85.9858 78.1936 94.5958 66.1956 97.0938C50.4136 100.382 41.0596 90.4998 35.4676 84.0018C29.8756 77.5018 11.2896 71.5658 8.53357 49.6298C8.11557 46.2858 8.03757 42.8938 8.39557 39.5378C9.20757 31.8778 12.4056 23.7118 18.1356 18.3478C18.5656 17.9458 19.0096 17.5558 19.4656 17.1778C28.3796 9.79782 41.8716 7.44982 52.9056 9.66982Z"
        fill="#585550"
        fillOpacity="0.4"
      />
      <path
        opacity="0.7"
        d="M52.9056 9.66982C60.9996 11.2978 70.1976 15.7998 73.9576 23.6058C79.6396 35.3998 89.8616 35.2818 92.4876 57.6158C92.8976 61.0978 92.9236 64.3738 92.6176 67.4338C92.4536 69.0798 92.1916 70.6638 91.8396 72.1838C88.6656 85.9858 78.1936 94.5958 66.1956 97.0938C50.4136 100.382 41.0596 90.4998 35.4676 84.0018C29.8756 77.5018 11.2896 71.5658 8.53357 49.6298C8.11557 46.2858 8.03757 42.8938 8.39557 39.5378C9.20757 31.8778 12.4056 23.7118 18.1356 18.3478C18.5656 17.9458 19.0096 17.5558 19.4656 17.1778C28.3796 9.79782 41.8716 7.44982 52.9056 9.66982Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M87.8004 43.0586L80.1084 43.0626L82.4004 45.3986L67.7344 58.8126L70.4544 61.7126L85.1864 48.2366L87.6524 50.7506L87.8004 43.0586Z"
        fill="black"
      />
      <path
        d="M74.6885 86.5256L79.9705 81.6936L82.4365 84.2076L82.5865 76.5156L74.8925 76.5196L77.1865 78.8556L62.5205 92.2696L65.2405 95.1696L73.9365 87.2136"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M24 32.9023L17.994 32.9043L19.784 34.7303L8.33203 45.2043L10.456 47.4683L21.96 36.9463L23.886 38.9083L24 32.9023Z"
        fill="black"
      />
      <path
        d="M21.4323 64.8514L25.1223 61.3834L23.0643 69.4634C22.9703 69.8334 23.1943 70.2114 23.5643 70.3054C23.8023 70.3654 24.0443 70.2954 24.2103 70.1374C24.3023 70.0494 24.3723 69.9353 24.4063 69.8033L27.2903 58.4774C27.3483 58.2474 27.2863 58.0034 27.1243 57.8314C26.9623 57.6594 26.7223 57.5814 26.4903 57.6254L15.0063 59.8014C14.6343 59.8734 14.3843 60.2354 14.4543 60.6114C14.5243 60.9874 14.8883 61.2334 15.2643 61.1634L23.4563 59.6114L9.91627 72.3394C9.42627 72.7994 9.40427 73.5674 9.86227 74.0574C10.1023 74.3114 10.4243 74.4413 10.7483 74.4413C11.0463 74.4413 11.3463 74.3314 11.5803 74.1114L20.5003 65.7274"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M72.2394 35.1601C72.1694 35.0861 72.0674 35.0521 71.9674 35.0721L67.0494 36.0041C66.8894 36.0341 66.7834 36.1901 66.8134 36.3501C66.8434 36.5101 66.9994 36.6161 67.1594 36.5861L70.6674 35.9221L64.8674 41.3721C64.6574 41.5681 64.6474 41.8981 64.8454 42.1081C64.9474 42.2181 65.0854 42.2721 65.2254 42.2721C65.3534 42.2721 65.4814 42.2261 65.5814 42.1301L71.3814 36.6801L70.4994 40.1401C70.4594 40.2981 70.5554 40.4601 70.7134 40.5001C70.8154 40.5261 70.9194 40.4961 70.9894 40.4281C71.0294 40.3901 71.0594 40.3421 71.0734 40.2861L72.3094 35.4361C72.3354 35.3381 72.3094 35.2341 72.2394 35.1601Z"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M53.9239 82.6054C53.8539 82.5314 53.7519 82.4974 53.6519 82.5174L48.7339 83.4494C48.5739 83.4794 48.4679 83.6354 48.4979 83.7954C48.5279 83.9554 48.6839 84.0614 48.8439 84.0314L52.3519 83.3674L46.5519 88.8174C46.3419 89.0134 46.3319 89.3434 46.5299 89.5534C46.6319 89.6634 46.7699 89.7174 46.9099 89.7174C47.0379 89.7174 47.1659 89.6714 47.2659 89.5754L53.0659 84.1254L52.1839 87.5854C52.1439 87.7434 52.2399 87.9054 52.3979 87.9454C52.4999 87.9714 52.6039 87.9414 52.6739 87.8734C52.7139 87.8354 52.7439 87.7874 52.7579 87.7314L53.9939 82.8814C54.0199 82.7834 53.9919 82.6794 53.9239 82.6054Z"
        fill="black"
      />
      <path
        d="M63.7377 50.9844L31.5077 50.9964L41.1157 60.7884L18.6357 81.8764L30.0317 94.0224L52.7837 72.6784L63.1177 83.2084L63.7377 50.9844Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M88.8414 27.2232C88.8414 27.2232 87.9014 23.7012 85.3194 23.1132C82.7354 22.5252 83.2054 24.7572 81.5614 25.4612C79.9174 26.1652 79.0954 24.7572 77.6854 24.9912C76.2754 25.2252 76.3934 26.1652 75.8074 26.7532C75.2194 27.3412 74.3974 26.5192 73.2234 26.6352C72.0494 26.7532 71.1094 28.2792 71.1094 28.2792H93.4214C93.4214 28.2792 93.3034 27.3392 91.7774 26.6352C90.2494 25.9312 89.6634 27.2232 88.8414 27.2232Z"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M24.9418 46.2421C24.9418 46.2421 24.5338 44.7161 23.4158 44.4601C22.2958 44.2061 22.4998 45.1721 21.7878 45.4781C21.0758 45.7841 20.7198 45.1721 20.1078 45.2741C19.4978 45.3761 19.5478 45.7821 19.2938 46.0381C19.0398 46.2941 18.6838 45.9361 18.1738 45.9881C17.6658 46.0381 17.2578 46.7001 17.2578 46.7001H26.9258C26.9258 46.7001 26.8758 46.2921 26.2138 45.9881C25.5518 45.6821 25.2978 46.2421 24.9418 46.2421Z"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M73.8305 66.121C73.8305 66.121 73.4225 64.595 72.3045 64.339C71.1845 64.085 71.3885 65.051 70.6765 65.357C69.9645 65.663 69.6085 65.051 68.9965 65.153C68.3865 65.255 68.4365 65.661 68.1825 65.917C67.9285 66.171 67.5725 65.815 67.0625 65.867C66.5545 65.917 66.1465 66.579 66.1465 66.579H75.8145C75.8145 66.579 75.7645 66.171 75.1025 65.867C74.4425 65.561 74.1865 66.121 73.8305 66.121Z"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M30.2516 16.1103C30.2516 16.1103 29.4336 13.0423 27.1836 12.5303C24.9336 12.0183 25.3436 13.9623 23.9116 14.5763C22.4796 15.1903 21.7636 13.9623 20.5356 14.1663C19.3076 14.3703 19.4116 15.1883 18.8996 15.7003C18.3876 16.2123 17.6716 15.4963 16.6496 15.5983C15.6276 15.7003 14.8096 17.0303 14.8096 17.0303H34.2416C34.2416 17.0303 34.1396 16.2123 32.8096 15.5983C31.4796 14.9843 30.9676 16.1103 30.2516 16.1103Z"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M38.8195 26.7878C38.8195 26.7878 38.4115 27.8198 38.3895 28.0138C38.3675 28.2078 38.4315 28.9378 38.6255 29.3038C38.8195 29.6698 39.0335 29.9698 38.8195 29.9698C38.6035 29.9698 38.4975 29.9698 38.3455 29.6678C38.1955 29.3658 38.1515 29.1078 38.1515 29.1078C38.1515 29.1078 36.7975 30.7418 36.7115 30.8278C36.6255 30.9138 36.4315 30.9358 36.4535 30.7418C36.4755 30.5478 37.0995 29.4078 37.0995 29.4078L35.8955 30.8478C35.8955 30.8478 35.5735 31.0638 35.6595 30.7398C35.7455 30.4178 36.5415 29.1278 36.5415 29.1278C36.5415 29.1278 35.7035 29.8598 35.4875 30.0098C35.2715 30.1598 34.9275 30.3978 34.9075 30.2258C34.8855 30.0538 36.0035 28.7198 36.0255 28.6138C36.0475 28.5058 35.2295 29.1298 35.1435 29.2378C35.0575 29.3458 34.6275 29.7758 34.5195 29.7758C34.4115 29.7758 34.3475 29.6458 34.5635 29.2598C34.7795 28.8718 35.5535 28.1838 35.7255 27.9698C35.8975 27.7538 38.1335 25.9258 38.1335 25.9258L38.8195 26.7878Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M39.8685 48.3438C39.8685 48.3438 38.6185 49.6357 38.3265 50.5117C38.0345 51.3877 38.2005 52.7638 38.7025 52.8458C39.2025 52.9298 40.2445 51.5957 40.5365 50.5117C40.8285 49.4277 40.9945 48.8857 40.9945 48.8857L39.8685 48.3438Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M40.5355 50.5117L39.8675 51.3457L38.6175 50.9297L38.5335 50.2217L38.4695 50.1797C38.4095 50.2957 38.3595 50.4077 38.3235 50.5137C38.0315 51.3897 38.1975 52.7657 38.6995 52.8477C39.2015 52.9297 40.2435 51.5957 40.5355 50.5117Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M55.4734 48.8506C55.4734 48.8506 55.8894 49.5586 56.3074 49.8506C56.7234 50.1426 58.3094 50.8086 58.2674 51.0606C58.2254 51.3106 56.5574 51.3946 55.9334 51.3946C55.3094 51.3946 54.4734 51.0186 53.8894 50.6866C53.3054 50.3526 52.8894 50.3526 53.0554 49.7686C53.2214 49.1846 53.8474 48.4766 53.8474 48.4766L55.4734 48.8506Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M58.2654 51.0607C58.2994 50.8567 57.2474 50.3727 56.6354 50.0447L56.2234 50.5607C56.2234 50.5607 54.9314 49.9767 54.3894 49.6027C54.0734 49.3827 53.7134 49.1368 53.4694 48.9688C53.3054 49.2088 53.1314 49.5027 53.0554 49.7707C52.8894 50.3547 53.3054 50.3547 53.8894 50.6887C54.4734 51.0227 55.3074 51.3967 55.9334 51.3967C56.5594 51.3967 58.2254 51.3127 58.2654 51.0607Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M61.3155 41.086C60.2775 35.99 56.5976 30.334 56.4736 30.042C56.3476 29.75 55.4736 27.416 55.4736 27.416C55.4736 27.416 52.0136 27.792 50.9716 27.666C49.9296 27.54 47.2196 27.082 47.2196 27.082V28.25C47.2196 28.25 45.9536 31.172 45.4956 32.39C45.0396 33.608 44.8856 37.67 44.8356 37.924C44.7856 38.178 44.2776 41.072 44.2776 41.072L39.2336 48.814C39.2336 48.814 39.2016 49.702 40.0636 49.752C40.9276 49.802 40.9936 49.738 40.9936 49.738L47.9576 42.112C47.9576 42.112 48.3136 41.148 48.4136 40.944C48.5116 40.746 51.3376 34.094 51.4796 33.678L52.5016 34.37L57.7676 41.508C57.7676 41.508 57.1416 42.176 56.7676 43.092C56.3916 44.01 53.8496 48.47 53.8496 48.47C53.8496 48.47 54.5576 49.096 54.9756 49.178C55.3916 49.262 56.4276 49.308 56.4276 49.308C56.4276 49.308 60.7396 42.874 60.8636 42.456C60.9876 42.038 61.4375 41.682 61.3155 41.086Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M50.4373 15.9637L48.7373 16.6877C48.7373 16.6877 49.9313 21.2837 49.8593 21.6817C49.7873 22.0797 48.1533 28.0417 48.1533 28.0417C48.1533 28.0417 50.1793 28.9817 52.1333 28.6937C54.0873 28.4037 54.7033 27.8977 54.7033 27.8977C54.7033 27.8977 53.7673 20.9597 53.7673 20.6697C53.7673 20.3797 53.8753 16.9057 53.8753 16.9057C53.8753 16.9057 52.1733 15.6377 50.4373 15.9637Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M55.3138 21.1328L56.1638 23.5308C56.1638 23.5308 57.8618 23.6808 58.8358 23.9048C59.8098 24.1288 61.9078 24.5048 61.9078 24.5048L61.7838 25.8028C61.7838 25.8028 59.3118 26.0028 58.0618 26.0768C56.8138 26.1508 55.3898 26.1508 55.3898 26.1508L54.2158 23.3528L55.3138 21.1328Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M53.0415 17.9629C53.0415 17.9629 53.0655 21.6589 53.2155 22.2829C53.3655 22.9069 56.4675 32.6529 56.4675 32.7769C56.4675 32.9009 56.8175 32.8269 57.3175 32.6029C57.8175 32.3789 58.8155 31.2289 58.8155 31.2289C58.8155 31.2289 56.7635 28.4469 56.0655 27.0989C55.3655 25.7509 55.1135 23.9069 55.0635 23.6329C55.0135 23.3589 55.5635 22.0589 55.5875 21.1609C55.6115 20.2609 55.6375 20.1129 55.6375 19.9629C55.6375 19.8129 55.9615 18.1149 56.0115 17.6409C56.0615 17.1669 55.5115 17.0409 54.8375 16.7169C54.1635 16.3929 53.0195 16.1289 53.0195 16.1289L53.0415 17.9629Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M50.2949 17.9924C50.2949 17.9924 51.1489 20.5564 51.1489 21.3004C51.1489 22.0444 49.8869 25.2284 49.3669 25.9724C48.8469 26.7164 45.8249 32.3604 45.8249 32.3604C45.8249 32.3604 44.1529 31.6164 43.5569 30.9844C42.9629 30.3524 42.7769 29.4604 42.7769 29.4604L47.8309 24.1444L46.9489 21.0024L43.1949 22.9724L38.6229 27.5444L37.5449 26.2424L41.3289 21.0604C41.3289 21.0604 45.2009 17.5824 45.3509 17.4704C45.4989 17.3584 49.4389 15.9844 49.4389 15.9844L50.2949 17.9924Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M46.5027 19.9609L46.9487 21.0009L45.3867 19.9609"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M49.3666 25.9727C49.3666 25.9727 44.2266 30.2787 44.3006 30.6127C44.3746 30.9467 45.0446 31.8027 45.8246 32.3607L49.3666 25.9727Z"
        fill="#E5796E"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M50.2172 15.2751C50.2172 15.2751 49.6672 15.0991 49.4912 15.2251C49.3152 15.3511 49.1152 15.8511 49.1152 15.8511C49.1152 15.8511 48.8892 18.9311 48.9152 19.0311C48.9412 19.1311 51.5432 17.8791 51.5432 17.8791C51.5432 17.8791 53.9212 19.0051 53.9472 18.8051C53.9732 18.6051 53.0212 16.1271 53.0212 16.1271L50.2172 15.2751Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M53.7185 9.94904C53.7185 9.94904 52.6485 9.02304 51.2085 9.12504C49.9985 9.21104 48.5345 9.59904 48.0805 11.389C47.6285 13.179 49.0685 14.579 49.6845 14.825C50.3025 15.071 50.4045 15.155 50.4045 15.155C50.4045 15.155 50.1165 15.361 49.9105 15.485C49.7045 15.609 49.7045 15.609 49.7045 15.609C49.7045 15.609 49.9505 17.213 50.7545 18.263C51.5565 19.313 51.7005 19.579 51.7005 19.579C51.7005 19.579 52.4625 18.715 52.5845 17.419L53.2845 15.855C53.2845 15.855 54.2725 15.897 54.3965 15.691C54.5205 15.485 54.9525 13.675 54.9525 13.675C54.9525 13.675 55.5285 13.201 55.4665 12.913C55.4045 12.625 54.9725 12.439 54.8485 12.295C54.7245 12.151 54.5605 11.987 54.4585 11.555C54.3565 11.121 54.1085 10.421 53.7185 9.94904Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M52.6685 10.153C53.1405 9.88704 53.4905 9.88504 53.7205 9.95304C53.7185 9.95104 53.7185 9.94904 53.7165 9.94904C53.7165 9.94904 52.6465 9.02304 51.2065 9.12504C49.9965 9.21104 48.5325 9.59904 48.0785 11.389C47.6265 13.179 49.0665 14.579 49.6825 14.825C50.2985 15.071 50.4025 15.155 50.4025 15.155C50.8545 15.053 51.3485 14.331 51.3485 14.331C51.3485 14.331 50.8965 14.393 50.5865 14.311C50.2785 14.229 49.9485 13.467 50.2985 12.953C50.6485 12.439 51.1425 12.871 51.2245 12.933C51.3065 12.995 51.5745 13.015 51.8005 12.809C52.0265 12.603 52.0885 11.965 52.0265 11.575C51.9685 11.183 51.7225 10.689 52.6685 10.153Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M54.1888 12.105C54.2208 12.273 54.1928 12.419 54.1268 12.431C54.0608 12.445 53.9788 12.319 53.9468 12.151C53.9148 11.983 53.9428 11.837 54.0088 11.825C54.0768 11.813 54.1568 11.939 54.1888 12.105Z"
        fill="#242424"
      />
      <path
        d="M53.6367 11.5731C53.6367 11.5731 53.8627 11.1831 54.1927 11.4291"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M54.5013 14.0619C54.5013 14.0619 54.0273 14.1239 53.8213 13.6719"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M53.2864 15.852L52.4424 15.668L52.9164 16.51L53.2864 15.852Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M48.3669 10.5668C48.3669 10.5668 47.1729 9.64079 46.1029 9.59879C45.0329 9.55679 44.2709 10.6888 43.9429 11.5948C43.6129 12.5008 42.0289 12.5208 40.7749 11.9848C39.5189 11.4508 38.4909 12.9308 38.5929 14.2688C38.6949 15.6068 41.4729 16.6148 42.9349 16.1008C44.3949 15.5868 45.2189 12.7888 45.7529 11.6768C46.2869 10.5648 48.2229 10.9368 48.2229 10.9368L48.3669 10.5668Z"
        fill="#242424"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M61.9072 24.5081C61.9072 24.5081 63.2812 24.0341 63.7812 23.7841C64.2812 23.5341 65.3052 23.8081 65.3792 23.8341C65.4532 23.8581 66.1532 23.6341 66.1532 23.8341C66.1532 24.0341 66.0792 24.0581 65.7292 24.2081C65.3792 24.3581 64.6812 24.7821 64.5552 24.9321C64.4312 25.0821 64.3552 25.1561 64.5792 25.1561C64.8032 25.1561 65.9772 24.7561 65.9532 25.0821C65.9292 25.4061 65.4792 25.5061 65.1292 25.6061C64.7792 25.7061 64.9792 25.8301 63.8812 25.9061C62.7832 25.9801 61.7832 25.4821 61.7832 25.4821L61.9072 24.5081Z"
        fill="white"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M65.3792 23.832C65.3792 23.832 64.5792 23.956 64.3052 24.106C64.0312 24.256 64.0312 24.256 64.0312 24.256"
        stroke="#242424"
        strokeMiterlimit="10"
      />
      <path
        d="M63.7061 23.9805C63.7061 23.9805 64.0061 23.8805 64.4301 23.7305L63.7061 23.9805Z"
        fill="white"
      />
      <path
        d="M63.7061 23.9805C63.7061 23.9805 64.0061 23.8805 64.4301 23.7305"
        stroke="#242424"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default BuildProduct;
