import Auth from '@pages/Auth';
import { AUTH } from '@router/consts';

export const AUTH_ROUTES = [
  {
    element: <Auth.SignIn />,
    path: AUTH.SIGN_IN,
  },
  {
    element: <Auth.SignUp />,
    path: AUTH.SIGN_UP,
  },
  {
    element: <Auth.CheckEmail />,
    path: AUTH.CHECK_EMAIL,
  },
  {
    element: <Auth.EmailVerification />,
    path: AUTH.EMAIL_VERIFICATION,
  },
  {
    element: <Auth.RestoreRequest />,
    path: AUTH.RESTORE_REQUEST,
  },
  {
    element: <Auth.RestoreNotification />,
    path: AUTH.RESTORE_NOTIFICATION,
  },
  {
    element: <Auth.ResetPassword />,
    path: AUTH.RESET_PASSWORD,
  },
];
