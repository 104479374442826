import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import { INPUT } from '@utils/const';

const Input = ({ onChange, name, value, className, error, label, placeholder, type }) => {
  return (
    <div className={clsx('w-full', className)}>
      <label
        className={clsx('block text-sm leading-[1.063rem] text-black-brand-1 font-bold mb-2')}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        id={name}
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholder}
        className={clsx(
          'py-4 px-5 w-full h-12 rounded bg-gray-6 outline-none text-sm leading-[1.063rem] text-black-brand-1 font-normal',
          error && 'border border-red-400'
        )}
      />
      <AnimatePresence initial={false} mode="wait">
        <motion.p
          key={error || 'empty'}
          animate={{ opacity: 1, y: 2 }}
          initial={{ opacity: 0, y: -4 }}
          exit={{ opacity: 0, y: -4 }}
          transition={{ duration: 0.2 }}
          className="absolute text-red-400 text-xs font-light"
        >
          {error}
        </motion.p>
      </AnimatePresence>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT.TYPES)),
};

Input.defaultProps = {
  className: '',
  error: null,
  label: '',
  placeholder: '',
  type: INPUT.TYPES.TEXT,
  name: 'input',
};

Input.displayName = 'Input';

export default Input;
