import { useEffect, useRef } from 'react';

export default function useDeferredRenderEffect(fn, inputs) {
  const didMountRef = useRef(null);

  useEffect(() => {
    if (didMountRef.current && didMountRef.current !== JSON.stringify(inputs)) {
      didMountRef.current = JSON.stringify(inputs);

      return fn();
    }
    didMountRef.current = JSON.stringify(inputs);
    return () => {};
  }, [fn, inputs]);
}
