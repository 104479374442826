import clsx from 'clsx';
import PropTypes from 'prop-types';

const ArrowDown = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 8"
    className={clsx('w-3 h-2', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.898 1.102c.22.22.22.576 0 .796l-4.5 4.5a.563.563 0 0 1-.796 0l-4.5-4.5a.563.563 0 0 1 .796-.796L6 5.205l4.102-4.103c.22-.22.576-.22.796 0Z"
      fill={color}
    />
  </svg>
);

ArrowDown.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ArrowDown.defaultProps = {
  color: '#242424',
  className: '',
};

export default ArrowDown;
