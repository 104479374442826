import config from '@config/config';

import ApiService from './api';
import LocalStorageService from '../localStorage';

class AuthApi extends ApiService {
  constructor() {
    if (AuthApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return AuthApi.instance;
    }

    super(config.authApiUrl);
    AuthApi.instance = this;
  }

  async googleLogin(token) {
    const response = await this.http.post('/socialLogin', { token, type: 'google' });
    return response.data;
  }

  async emailLogin(email, password) {
    const response = await this.http.post('/login', { email, password });
    return response.data;
  }

  async emailRegistration(email, password) {
    await this.http.post('/register', { email, password });
  }

  async requestPasswordReset(email) {
    await this.http.post('/passwordReset/request', { email });
  }

  async resetPassword(password, resetPasswordCode, email) {
    const response = await this.http.post('/passwordReset', { password, resetPasswordCode, email });
    return response.data;
  }

  async requestActivation(email) {
    await this.http.post('/userActivation/request', { email });
  }

  async activateUser(email, activationCode) {
    const response = await this.http.post('/userActivation', { email, activationCode });
    return response.data;
  }

  async logout() {
    const response = await this.http.post('/session/logout');

    return response.data;
  }

  async getCurrentUser() {
    const response = await this.http.get('/session/current');
    return response.data;
  }

  async refreshToken() {
    const refreshToken = LocalStorageService.getRefreshToken();
    if (!refreshToken) {
      throw new Error('Refresh token does not exist');
    }

    const response = await this.http.post('/session/refresh', { refreshToken });
    return response.data;
  }
}

const authApiInstance = new AuthApi();
export default authApiInstance;
