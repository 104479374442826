import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROOT } from '@router/consts';

export const RedirectRoot = ({ path }) => <Navigate to={path} replace />;

RedirectRoot.propTypes = {
  path: PropTypes.string,
};

RedirectRoot.defaultProps = {
  path: ROOT,
};
