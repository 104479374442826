import clsx from 'clsx';
import PropTypes from 'prop-types';

const ArrowRight = ({ className, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 6"
    className={clsx('w-7 h-1.5', className)}
    {...props}
  >
    <path fill={color} d="M28 3L23 .113v5.774L28 3zM0 3.5h23.5v-1H0v1z" />
  </svg>
);

ArrowRight.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ArrowRight.defaultProps = {
  color: '#000',
  className: '',
};

export default ArrowRight;
